<template>
    <main class="background imageless_bg messages">
        <div class="pcon">
            <ProfileMenu />
            <NotificationsMenu />
        </div>


    </main>
</template>

<script>
import ProfileMenu from '@/components/profile/ProfileMenu.vue'
import NotificationsMenu from '@/components/NotificationsMenu.vue';
export default {
    name: 'TicketsVue',
    components: { ProfileMenu, NotificationsMenu },
    data() {
        return {
            openTicket: false
        }
    },
    mounted() {
        document.title = 'Notifications / Rossoneries';

    }
}
</script>

<style></style>