<template>
    <main class="news all_posts">

        <article class="news news2">
            <ArticleLogo :article="article" />
        </article>
        <LeagueLogo logo="uel" />
        <div :class="$route.path.includes('/uel') && 'sub_container_small'" class="sub_container_b">
            <NewsSubMenu name="Info" route="uelinfo" />
            <NewsSubMenu name="tables" route="uelstages" />
            <NewsSubMenu name="Matchday" route="uelmatchday" />
            <NewsSubMenu name="Goal-Scorers" route="uelgs" />
        </div>
        <ul class="league_info fixtures ">

            <div class="league_goalscorers">
                <UelGoalscorers :limit="25" :currentYear="currentYear" key="currentYear" :fullScreen="fullScreen" />
            </div>
        </ul>

    </main>
</template>

<script>
import UelGoalscorers from '@/components/uel/UelGoalscorers.vue'
import NewsSubMenu from '@/components/team/NewsSubMenu.vue'
import ArticleLogo from '../components/ArticleLogo.vue'
import LeagueLogo from '@/components/LeagueLogo.vue'

export default {
    name: 'SerieAgs',
    components: { ArticleLogo, NewsSubMenu, UelGoalscorers, LeagueLogo },
    emits: ['news_direction'],
    data() {
        return {
            title: '2024 Top Goalscorers',
            fullScreen: true,
            article: 'News',
            limit: undefined,
            activeMenu: 'serie A',
            activeSubMenu: 'table',
            currentYear: 'default',
            customStyle: {
                width: '75px',
                height: '75px'
            }
        }
    },
    mounted() {
        document.title = 'Uel top Goal Scorers / Rossoneries';

    }

}
</script>

<style ></style>