<template>
    <div id="profile_request" class="pno">
        <div class="nhead">All Notifications</div>

        <div v-if="messages" class="profile messages messages_container notif-all">


            <div v-for="message in messages" :key="message.id" :id="message.id" class="nblock">
                <div class="prpi2">
                    <PoppedProfile v-for="uniquePicId in Array.from(new Set(message.profile_pics.map(pic => pic.id)))"
                        :key="uniquePicId" :pic="message.profile_pics.find(pic => pic.id === uniquePicId)" />
                </div>


                <li>
                    <router-link v-if="!message.cm" @click="!message.seen && noteSeen(message.id)" ref="seenNote"
                        :id="message.id" :to="{ name: 'singlePost', params: { id: message.ref_id } }"
                        :key="$route.fullPath">
                        {{ message.message }}
                    </router-link>
                    <router-link v-else @click="!message.seen && noteSeen(message.id)" ref="seenNote" :id="message.id"
                        :to="{ name: 'PostToComment', params: { id: message.ref_id, cm: message.cm } }"
                        :key="'PostToComment_' + message.ref_id">
                        {{ message.message }}
                    </router-link>
                    <svg v-if="!message.seen && available" width="10px" height="10px" viewBox="0 0 72 72" id="emoji"
                        xmlns="http://www.w3.org/2000/svg" fill="#000000" stroke="#000000">
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                            <g id="color">
                                <circle cx="36" cy="36.0001" r="28" fill="#d22f27"></circle>
                            </g>
                            <g id="line">
                                <circle cx="36" cy="36.0001" r="28" fill="none" stroke="#000000" stroke-linejoin="round"
                                    stroke-width="2"></circle>
                            </g>
                        </g>
                    </svg>

                </li>




            </div>
            <div v-if="pages.length > 1" style="text-align: center;">
                <PaginationVue name="notificationsPaginate" :current_page="currentPage" :pages="pages"
                    @changePage="pageChanged" />

            </div>
            <div v-if="available" class="canotif">
                <span @click="noteSeen(0)"> Mark All as Seen </span>
            </div>
        </div>
    </div>
</template>

<script>
import PaginationVue from "../components/PaginationVue.vue"
import PoppedProfile from './profile/PoppedProfile.vue'
export default {
    name: "TicketsMenu",
    inject: ['backendServer', 'loading', 'doUpdateUserInfo'],
    components: { PaginationVue, PoppedProfile },
    data() {
        return {
            messages: null,
            page: null,
            pages: null,
            available: null,
            popUser: null
        }
    },
    computed: {
        currentHost() {
            let hostFragments = location.host.split(':');
            return hostFragments[0];
        },
        currentPage() {

            if (this.page)
                return this.page;

            let endOfURL = window.location.href.split('/').pop();
            let number = +endOfURL;
            number = number > 0 ? number : 1
            // console.log('num:', endOfURL, endOfURL)

            return number
        }
    },
    methods: {
        noteSeen(id) {
            this.loading()
            fetch(this.backendServer() + '/user-notification-seen/' + id, {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {
                    this.notifs = data.notifs;
                    this.available = false
                    this.doUpdateUserInfo(data.base)
                    this.loading()

                });


        },
        pageChanged(pageNumber) {
            this.loading()
            this.page = pageNumber
            fetch(this.backendServer() + '/userprofile/notifications', {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: "follow",
                referrerPolicy: "no-referrer",
                body: JSON.stringify({
                    'page': pageNumber,

                }),
            })

                .then((response) => response.json())
                .then((data) => {

                    this.messages = data['output']
                    this.pages = data['pages']
                    this.available = data['available']
                    this.loading()

                });
        },



    },
    mounted() {
        this.loading()
        fetch(this.backendServer() + '/userprofile/notifications', {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "include",
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify({
                'page': this.currentPage
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                this.messages = data['output']
                this.available = data['available']
                this.pages = data['pages']


                this.loading()
            });

    }
}
</script>

<style>
.pnots {
    transition: .2s linear all;
}

/* .prpi:hover li img.pnots {
    margin: 0 3px !important;
} */

div.prpi2 {
    padding: 3px 25px;
    display: flex;
    flex-wrap: wrap
}

.prpi li {
    padding: 0 !important;
}



.prpi li img.pnots {
    margin: 0 -10px;
}

.prpi li:first-child img {
    margin-left: 0;
}

.nblock {
    border-bottom: 1px rgb(213 188 188) solid;
    transition: .3s all linear;
    position: relative;
}

div.nblock:last-child {
    border-bottom: none !important;
}

/* 
.nblock:hover {
    background-color: white;
} */

.pno {
    text-align: left !important;
    flex-direction: column;
}

.notif-all {
    padding-top: 32px;
    overflow: auto;
    max-height: 500px;
}

.nhead {
    margin-top: 10px;
    font-weight: bold;
    font-size: 1.2em;
}

.pno li {
    list-style: none;
    padding: 3px 25px;
}

li a {
    text-decoration: none;
    color: black;
    transition: .3s linear all;
}

li a:hover {
    color: chocolate;
}

span.unreads {
    color: red;
    font-size: .85em;
}

@media screen and (max-width:400px) {
    span.hidedate {
        display: none;
    }

}

.date {
    font-size: .7em;
}

.new_ticket {
    text-align: center;
    transform: translate(-50%, -50%);
    position: absolute;
    bottom: -10px;
    color: rgb(70 27 27);
    cursor: pointer;
    width: fit-content;
    margin: 15px auto;
    left: 50%;
}

.canotif {
    text-align: center;
    transform: translate(-50%, -50%);
    position: absolute;
    /* bottom: -10px; */
    color: rgb(70 27 27);
    cursor: pointer;
    width: -moz-fit-content;
    font-size: .89em;
    width: fit-content;
    top: 20px;
    color: white;
    background-color: crimson;
    border-radius: 8px;
    padding: 5px;
    /* margin: 15px auto; */
    border: 1px solid red;
    /* left: 50%; */
    right: -50px;
    transition: .5s linear all;
}

.canotif:hover {
    background-color: black;
}

.new_ticket :hover {
    color: rgb(255, 66, 66)
}



div.messages {
    color: rgb(2, 2, 2)
}

tbody {
    color: rgb(22, 22, 22) !important;

}

table.ucl tr {
    border-bottom: 1px solid #ff000045;
}

#profile_request {
    text-align: center;
    display: flex;
    width: 100%;
    align-items: center;
    background-color: rgb(255, 255, 255, 0.79)
}

/*tbody tr {
    cursor: pointer;
}*/
</style>