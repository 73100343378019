<template>
    <div id="profile_request">

        <div class="profile messages messages_container">
            <table class="ucl messages">
                <thead>
                    <tr>
                        <th>Title</th>

                        <th>Last reply by</th>


                    </tr>
                </thead>

                <tbody>




                    <tr v-for="message in messages" :key="message.id" :id="message.id" @click="singleTicket">
                        <td>
                            {{ message.title }} <span class="unreads">{{ message.unreads > 0 ? '(+' + message.unreads + ')'
                                : '' }}</span>
                        </td>


                        <td class="ti">
                            {{ message.reply_username
                                ? message.reply_username
                                : (message.username ? message.username : message.email) }}
                            <span class="date hidedate"> ({{ message.reply_date_posted ?
                                message.reply_date_posted : message.date }})</span>

                        </td>


                    </tr>


                </tbody>
            </table>
            <PaginationVue v-if="this.pages && this.pages.length > 1" name="ticketsPaginate" :current_page="currentPage"
                :pages="pages" @changePage="pageChanged" />
            <div class="new_ticket">
                <span @click="openNewTicket"> new ticket <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                        fill="currentColor" class="bi bi-plus-square-fill" viewBox="0 0 16 16">
                        <path
                            d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                    </svg></span>
            </div>
        </div>
    </div>
    <NewTicket v-if="openTicket" @close="openNewTicket" />
</template>

<script>
import NewTicket from '@/components/NewTicket.vue'
import PaginationVue from "../components/PaginationVue.vue"

export default {
    name: "TicketsMenu",
    inject: ['backendServer', 'loading'],
    components: { NewTicket, PaginationVue },
    data() {
        return {
            messages: null,
            openTicket: false,
            page: null,
            pages: null
        }
    },
    computed: {
        currentHost() {
            let hostFragments = location.host.split(':');

            return hostFragments[0];

        },
        currentPage() {

            if (this.page)
                return this.page;

            let endOfURL = window.location.href.split('/').pop();
            let number = +endOfURL;
            number = number > 0 ? number : 1

            return number
        }
    },
    methods: {
        openNewTicket() {
            this.openTicket = !this.openTicket
        },
        pageChanged(pageNumber) {
            this.loading()
            this.page = pageNumber
            fetch(this.backendServer() + '/userprofile/tickets', {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: "follow",
                referrerPolicy: "no-referrer",
                body: JSON.stringify({
                    'page': pageNumber,

                }),
            })

                .then((response) => response.json())
                .then((data) => {

                    this.messages = data['output']
                    this.pages = data['pages']
                    this.loading()

                });
        },
        singleTicket(event) {
            let id = event.target.closest('tr').id
            this.$router.push({ name: 'ticket', params: { id: id } })
        }
    },
    mounted() {
        this.loading()
        fetch(this.backendServer() + '/userprofile/tickets', {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "include",
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify({
                'page': this.currentPage
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                this.messages = data['output']
                this.pages = data['pages']
                this.loading()
            });

    }
}
</script>

<style scoped>
span.unreads {
    color: red;
    font-size: .85em;
}

@media screen and (max-width:400px) {
    span.hidedate {
        display: none;
    }

}

.date {
    font-size: .7em;
}

.new_ticket {
    text-align: center;
    transform: translate(-50%, -50%);
    position: absolute;
    bottom: -25px;
    color: rgb(70 27 27);
    cursor: pointer;
    width: fit-content;
    margin: 15px auto;
    left: 50%;
}

.new_ticket :hover {
    color: rgb(255, 66, 66)
}



div.messages {
    color: rgb(2, 2, 2)
}

tbody {
    color: rgb(22, 22, 22) !important;

}

table.ucl tr {
    border-bottom: 1px solid #ff000045;
}

#profile_request {
    text-align: center;
    display: flex;
    width: 100%;
    align-items: center;
    background-color: rgb(255, 255, 255, 0.79)
}

tbody tr {
    cursor: pointer;
}
</style>