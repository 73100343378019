<template>
    <div class="news">
        <span v-for="news in allNews" :key="news.id" ref="singleNews">
            {{ news.content }} -- {{ news.info }}
            <br>
            <textarea :ref="'editNews-' + news.id" placeholder="edit news" name="edit-news" cols="10"
                rows="10"></textarea>
            <br>
            <button @click="processNews(news.id, 'keep')">keep</button> -
            <button @click="processNews(news.id, 'dissmiss')">dissmiss</button>-
            <br>
            <ImageFinder @addPicture="changePic" />

        </span>
    </div>
    <input @click="refreshNews" type="submit" value="refresh" class="button_submit">
    <input @click="dissmissAll" type="submit" value="dismiss all" class="button_submit">
</template>

<script>
import ImageFinder from './ImageFinder.vue';
export default {
    name: 'AdminNews',
    inject: ['backendServer', 'currentHost', 'loading'],
    components: { ImageFinder },
    data() {
        return {
            allNews: null,
            editNews: null,
            images: null,
            chosen_pic: null
        }
    },

    methods: {
        changePic(target) {
            this.chosen_pic = target

        },
        dissmissAll() {
            fetch(this.backendServer() + '/dissmiss-news', {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {

                    this.allNews = data;
                });
        },
        refreshNews() {
            this.loading()

            fetch(this.backendServer() + '/google-news', {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
            this.loadNews()
            this.loading()

        },
        processNews(id, string) {
            const textareaValue = this.$refs['editNews-' + id][0].value;

            this.loading()
            fetch(this.backendServer() + '/process-news', {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: "follow",
                referrerPolicy: "no-referrer",
                body: JSON.stringify({
                    'id': id,
                    'type': string,
                    'edit': textareaValue,
                    'img': this.chosen_pic
                }),
            }).then((response) => {
                return response.json();
            }).then((data) => {
                this.allNews = data;
                this.loading()
                this.chosen_pic = null
            })
        },
        loadNews() {
            fetch(this.backendServer() + '/all-news', {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {

                    this.allNews = data;
                });
        }
    },

    mounted() {
        this.loadNews()
    },
}
</script>

<style scoped>
textarea {
    width: 100%;
    height: 23px;
    margin-top: 5px;
}

span {
    margin: 10px;
    border: 1px solid rosybrown;
    padding: 10px;
    border-radius: 10px;
    width: 100%;
    overflow: auto;
    background-color: #0b3537;
}

img {
    width: 125px
}

div.news {
    font-size: .6em;
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
}
</style>