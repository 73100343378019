<template>
    <div>
        <div v-if="user_login_info" class="opt">
            <div class="opt-b">
                <router-link class="nd" :to="{ name: 'home' }" target="_blank"><span>Home</span></router-link>
            </div>
            <div class="opt-b">
                <router-link class="nd" :to="{ name: 'leagueinfo' }" target="_blank"><span>Serie A</span></router-link>
            </div>
            <div class="opt-b">
                <router-link class="nd" :to="{ name: 'uclinfo' }" target="_blank"><span>Champions
                        League</span></router-link>
            </div>
            <div class="opt-b">
                <router-link class="nd" :to="{ name: 'uelinfo' }" target="_blank"><span>Europa League</span></router-link>
            </div>
            <div class="opt-b">
                <router-link class="nd" :to="{ name: 'news' }" target="_blank"><span>News</span></router-link>
            </div>
            <div class="opt-b">
                <router-link class="nd" :to="{ name: 'allposts' }" target="_blank"><span>Threads</span></router-link>
            </div>
            <div class="opt-b">
                <router-link class="nd" :to="{ name: 'prediction' }" target="_blank"><span>Predict
                        Milan</span></router-link>
            </div>
            <div class="opt-b">
                <router-link class="nd" :to="{ name: 'profile' }" target="_blank"><span>Profile</span></router-link>
            </div>
        </div>
        <div v-else class="opt">
            <div class="opt-b">
                <router-link class="nd" :to="{ name: 'home' }" target="_blank"><span>Home</span></router-link>
            </div>
            <div class="opt-b">
                <router-link class="nd" :to="{ name: 'leagueinfo' }" target="_blank"><span>Serie A</span></router-link>
            </div>
            <div class="opt-b">
                <router-link class="nd" :to="{ name: 'uclinfo' }" target="_blank"><span>Champions
                        League</span></router-link>
            </div>
            <div class="opt-b">
                <router-link class="nd" :to="{ name: 'uelinfo' }" target="_blank"><span>Europa League</span></router-link>
            </div>
            <div class="opt-b">
                <router-link class="nd" :to="{ name: 'news' }" target="_blank"><span>News</span></router-link>
            </div>
            <div class="opt-b">
                <router-link class="nd" :to="{ name: 'allposts' }" target="_blank"><span>Threads</span></router-link>
            </div>
            <div class="opt-b">
                <router-link class="nd" :to="{ name: 'login' }" target="_blank"><span>Login</span></router-link>
            </div>
            <div class="opt-b">
                <router-link class="nd" :to="{ name: 'register' }" target="_blank"><span>Register</span></router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: ' ExtraNav',
    inject: ['user_login_info']
}
</script>

<style>
.nd {
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}
</style>