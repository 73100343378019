<template>
    <main class="news all_posts create_post">
        <article class="news news2">
            <ArticleLogo :article="article" />
            <div class="article_container">
                <SortingPosts v-if="posts && posts.length > 1" @sort="sortingPosts" :parent="parent" />
            </div>
        </article>
        <div v-if="posts" class="comp_title comp_title_matchday">
            <h5 class="league-info-header">{{ posts[0].author }}'s Threads</h5>
        </div>
        <div id="post_body">

            <ul class="news post_complete" id="all_posts">
                <li v-for="post in posts" :key="post.id" class="new_posted all_posts">
                    <PostBlock :post="post" @reloadPost="fetchData" />
                </li>
            </ul>
            <PaginationVue v-if="this.pages && this.pages.length > 1" name="userpostsPaginate" :id="id"
                :current_page="currentPage" :pages="pages" @changePage="pageChanged" />

            <div v-if="posts" class="btat">
                <span @click="Threads">
                    Back to Threads
                </span>
            </div>
        </div>
    </main>
</template>

<script>
import ArticleLogo from "../components/ArticleLogo.vue"

import PostBlock from "../components/PostBlock.vue"
import PaginationVue from "../components/PaginationVue.vue"
import SortingPosts from "../components/allPosts/SortingPosts.vue"


export default {
    name: "UserPosts",
    components: { ArticleLogo, PostBlock, PaginationVue, SortingPosts },
    props: ['user_login_info'],
    inject: ['backendServer', 'current_user', 'currentHost', 'loading'],
    data() {
        return {
            article: 'Threads',
            posts: null,
            pages: null,
            add_new_post: false,
            overlay: false,
            sort: 'latest',
            page: null,
            id: window.location.href.split('/').pop(),
            parent: 'users'

        }
    },
    computed: {
        currentPage() {

            if (this.page)
                return this.page;

            //let endOfURL = window.location.href.split('/').pop();

            // http://localhost:8080/posts/user/2
            // http://localhost:8080/posts/user/2/5
            let urlSegments = window.location.href.split('/');
            let lastIndex = urlSegments.length - 1;
            if (urlSegments[lastIndex - 1] == 'user')
                return 1;

            return urlSegments[lastIndex];

        }

    },
    methods: {
        Threads() {
            this.$router.push({ name: 'allposts' })
        },
        sortingPosts(sortName) {
            this.sort = sortName;

            this.pageChanged(1)

        },
        pageChanged(pageNumber) {
            this.loading()

            this.page = pageNumber
            fetch(this.backendServer() + '/posts/user/' + this.id, {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: "follow",
                referrerPolicy: "no-referrer",
                body: JSON.stringify({
                    'page': pageNumber,
                    'sort': this.sort
                }),
            })

                .then((response) => response.json())
                .then((data) => {
                    this.posts = data['object'];
                    this.pages = data['pages']
                    this.loading()
                });

        },


        fetchData() {
            this.loading()

            fetch(this.backendServer() + '/posts/user/' + this.id, {

                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: "follow",
                referrerPolicy: "no-referrer",
                body: JSON.stringify({
                    'sort': this.sort,
                    'page': this.currentPage
                }),
            })

                .then((response) => response.json())
                .then((data) => {
                    this.posts = data['object'];
                    if (!this.posts.length) {
                        this.$router.push({ name: 'allposts' })
                        this.loading()

                        return
                    }
                    this.pages = data['pages']
                    document.title = this.posts[0].author + ' / Threads';
                    this.loading()

                });
        },
    },

    mounted() {
        this.fetchData()
    }

}
</script>

<style>
.btat {
    margin-top: 10px;
}

.btat span {
    cursor: pointer;
    background: rgb(255, 255, 255);
    padding: 7px;
    border-radius: 10px;
    transition: .3s all linear;
}

.btat span:hover {
    color: red;
}

.fade-enter-from {
    opacity: 0;
}

.fade-enter-to {
    opacity: 1;
}

.fade-enter-active {
    transition: all 1s ease-out;
}

.fade-leave-from {
    opacity: 1;
}

.fade-leave-to {
    opacity: 0;
}

.fade-leave-active {
    transition: opacity .3s ease-out;
}

main.news {
    position: relative;
}
</style>