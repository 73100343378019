<template>
    <h5 class="league-info-header predict-info-header">Share With</h5>

    <div class="shareon">
        <a class="facebook"></a>
        <a class="linkedin"></a>
        <a class="mastodon"></a>
        <!-- FB App ID is required for the Messenger button to function -->
        <a class="messenger" data-fb-app-id="0123456789012345"></a>
        <a class="odnoklassniki"></a>
        <a class="pinterest"></a>
        <a class="pocket"></a>
        <a class="reddit"></a>
        <a class="teams"></a>
        <a class="telegram" :data-text="text"></a>
        <a class="tumblr"></a>
        <a class="twitter"></a>
        <a class="viber"></a>
        <a class="vkontakte"></a>
        <a class="whatsapp"></a>
        <a class="copy-url"></a>
    </div>
</template>

<script>
import { init } from "shareon";
import "shareon/css";
init();
export default {
    name: 'ShareWith',
    props: ['text'],
    mounted() {
        this.$nextTick(() => {
            // Use import instead of require for ES6 module syntax
            import("shareon").then((Shareon) => {

                if (Shareon && Shareon.init) {
                    Shareon.init();
                }
            }).catch((error) => {
                console.error('Error loading Shareon:', error);
            });
        });
    },
    metaInfo() {
        return {
            title: 'Your Website Title',
            meta: [
                {
                    property: 'og:title',
                    content: 'http://localhost:8080/comp/ucl/match-day'
                },
                {
                    property: 'og:description',
                    content: 'everything is black and red for a rossonero'
                },
                {
                    property: 'og:url',
                    content: 'http://localhost:8080' // Update with your actual localhost URL and port
                },
                {
                    name: 'twitter:card',
                    content: 'everything is black and red for a milanista' // Use 'summary' for a basic preview
                }
            ]
        };
    }
}
</script>

<style></style>