<template>
    <main class="news all_posts">

        <article class="news news2">
            <ArticleLogo />
        </article>
        <!-- <div v-if="$route.path.includes('/schedule')" class="sub_container_b">
            <NewsSubMenu name="fixtures" route="fixtures" />
            <NewsSubMenu name="results" route="results" />
            <NewsSubMenu name="Squad" route="squad" />

        </div> -->
        <ul class=" fixtures">
            <TeamResult v-for="(game, index) in games" :key="index" :game="game" />
        </ul>

    </main>
</template>

<script>
import ArticleLogo from '@/components/ArticleLogo.vue'
import TeamResult from '@/components/team/TeamResult.vue'
// import NewsSubMenu from '@/components/team/NewsSubMenu.vue'


export default {
    name: 'LeagueInfo',
    inject: ['backendServer', 'currentHost'],

    components: { ArticleLogo, TeamResult },
    emits: ['news_direction'],
    data() {
        return {
            games: null,

        }
    },

    mounted() {
        document.title = 'Results / Rossoneries';

        fetch(this.backendServer() + '/all-results', {
            method: "GET",
            credentials: "include"
        })
            .then((response) => response.json())
            .then((data) => {
                this.games = data;
            });

    }

}
</script>
<style>
.mres {
    position: absolute;
    /* transform: translate(-50%, -50%); */
    bottom: 4px;
    right: 4px;
    transition: .2s rotate linear;
    cursor: pointer
}

.mres-clicked {
    rotate: 180deg;
}
</style>