<template>
    <main v-if="posts && posts.length" class="news all_posts create_post">
        <article class="news news2">
            <ArticleLogo :article="article" />
            <div class="article_container">
                <SortingPosts @sort="sortingPosts" :parent="parent">
                    <div v-if="user_login_info" class="svg_post">
                        <AddPost @click="openNewPost" />
                    </div>
                </SortingPosts>
            </div>
        </article>
        <div class="comp_title comp_title_matchday">
            <h5 class="league-info-header">{{ current_user }}'s Threads</h5>
        </div>
        <div id="post_body">

            <ul class="news post_complete" id="all_posts">
                <li v-for="post in posts" :key="post.id" class="new_posted all_posts">
                    <PostBlock :post="post" @reloadPost="fetchData" />
                </li>
            </ul>
            <PaginationVue v-if="this.pages && this.pages.length > 1" name="yourpostPaginate"
                :current_page="currentPage" :pages="pages" @changePage="pageChanged" />
            <div v-if="user_login_info" class="svg_post">
                <AddPost @click="openNewPost" />
            </div>
            <transition name="fade">

                <AddPostActive v-if="add_new_post" @close="openNewPost" @NewPostSubmitted="PostReciever" />

            </transition>

        </div>
    </main>
    <main v-else class="news all_posts create_post">
        <article class="news news2">
            <ArticleLogo :article="article" />

        </article>

        <div class="comp_title comp_title_matchday">
            <h5 class="league-info-header">You currently have no Threads,Add a new one?</h5>
        </div><br><br>
        <div v-if="user_login_info">
            <AddPost @click="openNewPost" />
        </div>
        <transition name="fade">

            <AddPostActive v-if="add_new_post" @close="openNewPost" @NewPostSubmitted="PostReciever" />

        </transition>
    </main>
</template>

<script>
import ArticleLogo from "../components/ArticleLogo.vue"

import PostBlock from "../components/PostBlock.vue"
import PaginationVue from "../components/PaginationVue.vue"
import AddPost from "../components/allPosts/AddPost.vue"
import AddPostActive from "../components/allPosts/AddPostActive.vue"
import SortingPosts from "../components/allPosts/SortingPosts.vue"


export default {
    name: "YourPosts",
    components: { ArticleLogo, PostBlock, PaginationVue, AddPost, AddPostActive, SortingPosts },
    props: ['user_login_info'],
    inject: ['backendServer', 'current_user', 'currentHost', 'loading'],
    data() {
        return {
            article: 'Your Posts',
            posts: null,
            pages: null,
            add_new_post: false,
            overlay: false,
            sort: 'latest',
            page: null,
            id: window.location.href.split('/').pop(),
            parent: 'users'

        }
    },
    computed: {
        currentPage() {

            if (this.page)
                return this.page;

            //let endOfURL = window.location.href.split('/').pop();

            // http://localhost:8080/posts/user/2
            // http://localhost:8080/posts/user/2/5
            let urlSegments = window.location.href.split('/');
            let lastIndex = urlSegments.length;
            if (urlSegments[lastIndex - 1] == 'my-threads')
                return 1;
            return urlSegments[lastIndex - 1];

        }

    },
    methods: {
        sortingPosts(sortName) {
            this.sort = sortName;

            this.pageChanged(1)

        },
        pageChanged(pageNumber) {
            this.loading()

            this.page = pageNumber
            fetch(this.backendServer() + '/my-posts', {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: "follow",
                referrerPolicy: "no-referrer",
                body: JSON.stringify({
                    'page': pageNumber,
                    'sort': this.sort
                }),
            })

                .then((response) => response.json())
                .then((data) => {
                    this.posts = data['object'];
                    this.pages = data['pages']
                    this.loading()
                });

        },
        openNewPost() {
            this.add_new_post = !this.add_new_post
            // this.overlay = !this.overlay
        },
        PostReciever(event) {
            this.loading()

            this.add_new_post = !this.add_new_post
            this.overlay = !this.overlay
            const formData = new FormData();
            formData.append('content', event.content);
            formData.append('title', event.title);

            for (let i = 0; i < event.image_file.length; i++) {
                formData.append('image_file[]', event.image_file[i], event.image_file[i].name);
            }
            fetch(this.backendServer() + '/ajaxnewpost', {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "include",
                body: formData,
            }).then((response) => {
                return response.json();
            }).then((data) => {
                // If the user is logged in:
                // Router...
                this.posts = data
                this.loading()
            })


        },
        fetchData() {
            this.loading()
            fetch(this.backendServer() + '/my-posts', {

                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: "follow",
                referrerPolicy: "no-referrer",
                body: JSON.stringify({
                    'sort': this.sort,
                    'page': this.currentPage
                }),
            })

                .then((response) => response.json())
                .then((data) => {
                    this.posts = data['object'];
                    this.pages = data['pages']

                    this.loading()

                });
        },
    },

    mounted() {
        document.title = this.current_user + ' / Threads';
        this.fetchData()
    }

}
</script>

<style>
.fade-enter-from {
    opacity: 0;
}

.fade-enter-to {
    opacity: 1;
}

.fade-enter-active {
    transition: all 1s ease-out;
}

.fade-leave-from {
    opacity: 1;
}

.fade-leave-to {
    opacity: 0;
}

.fade-leave-active {
    transition: opacity .3s ease-out;
}

main.news {
    position: relative;
}
</style>