<template>
    <div class="sub-container">
        <router-link class="news-menu" :to="{ name: route }" :class="activeClass(route)">

            <span :id=name>{{ name }}</span>
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'NewsSubMenu',
    props: ['activeSubMenu', 'name', 'route'],
    methods: {
        activeClass(linkRouteName) {
            if (this.$route.name == linkRouteName)
                return 'active_bar';

            switch (linkRouteName) {
                case 'serie A':
                    if (this.$route.path.includes('/serie-a'))
                        return 'active_bar';
                    break;
                case 'schedule':
                    if (this.$route.path.includes('/schedule'))
                        return 'active_bar';
                    break
                case 'squad':
                    if (this.$route.path.includes('/squad'))
                        return 'active_bar';
                    break
                default:
                    return '';
            }

            return '';
        },
    }
}
</script>

<style scoped>
span {
    padding: 5px;

    border-radius: 10px;

}

span:hover {
    background-color: #2f2842;
}

a {
    text-decoration: none;
    color: inherit;
    font-size: 1.2em;
    margin-left: 0 !important;
}

.active_bar {
    color: #ff4949 !important;
    font-weight: bold;
    pointer-events: none;
    cursor: none;
}

div.sub-container {
    border-radius: 8px;
    /* background-color: rgba(26, 28, 50, 0.8); */
    align-items: center;
    justify-content: space-around;
    transition: .3s right linear;
    /* padding: 0 4px; */
}

/* div.sub-container:hover {
    background-color: #2f2842;

} */
</style>