<template>
    <main class="register background all_posts">
        <div>

            <article class="news news2">
                <ArticleLogo :article="article" />
                <h3>Sign In</h3>
            </article>
            <!-- <div id="g_id_onload" data-client_id="906235521597-39lqp5qu3klf8vceng0t5gvr3hudfu8a" data-context="use"
                data-ux_mode="popup" data-login_uri="localhost:8080/login" data-auto_prompt="false">
            </div>

            <div class="g_id_signin" data-type="standard" data-shape="pill" data-theme="outline" data-text="continue_with"
                data-size="large" data-logo_alignment="left">
            </div> -->
            <div id="buttonDiv"></div>
            <div class="comp_title comp_title_matchday">
                <h5 class="league-info-header">
                    Sign In with Email or Username

                </h5>
            </div>
            <form action="" method="post" class="login_form">
                <div class="change_login_method">
                    <p @click="change_login_method" class="one" :class="email_method && 'active'">by email</p>
                    <p @click="change_login_method" class="two" :class="username_method && 'active'">by username</p>
                </div>

                <div class="choose_login_method">

                    <TextField v-if="email_method" name="email" type="text" v-model="email" />

                    <TextField v-if="username_method" name="username" type="username" v-model="username" />

                    <TextField name="password" type="password" v-model="password" />
                </div>
                <div class="remember">
                    <label for="remember">remember me</label>
                    <input id="remember" name="remember" type="checkbox" value="y" v-model="remember_me">
                </div>

                <input type="submit" name="submit" value="log in" class="button_submit" id="subone"
                    @click="validateLoginUser">


                <span class="reset_request"><router-link :to="{ name: 'resetpassword' }">Forgot
                        Password?</router-link></span>
                <p class="notyet sdfe">haven't signed up yet?join us <router-link
                        :to="{ name: 'register' }">here</router-link></p>
            </form>
        </div>
    </main>
</template>

<script>
import ArticleLogo from '../components/ArticleLogo.vue'
import TextField from "../components/TextField.vue"
/* global google */
export default {
    name: 'LoginVue',
    inject: ['backendServer', 'setFlashedMessage', 'doUpdateUserInfo', 'currentHost', 'loading'],
    components: { ArticleLogo, TextField },
    data() {
        return {
            email: '',
            password: '',
            username: '',
            remember_me: false,
            article: 'Login',
            cookies: '',
            email_method: true,
            username_method: false,
            login_type: 'email',
            emailLabel: false,
        }
    },
    //emits: ["userInfoHasBeenUpdated"],

    methods: {
        validateLoginUser(event) {
            event.preventDefault()
            if (this.login_type == 'email') {
                if (!this.email || !this.password) {
                    this.setFlashedMessage("please fill all the fields");
                    return
                } else if (this.password.length < 8 || !this.email.includes('@')) {
                    this.setFlashedMessage("wrong email or password,try again");
                    return
                } else {
                    this.doLogin(event)
                }

            }
            else if (this.login_type != 'email') {
                if (!this.username || !this.password) {
                    this.setFlashedMessage("please fill all the fields");
                    return
                } else if (this.password.length < 8 || this.username.length < 8) {
                    this.setFlashedMessage("wrong username or password,try again");
                    return
                } else {
                    this.doLogin(event)
                }
            }


        },
        doLogin(event) {

            event.preventDefault();
            this.loading()
            fetch(this.backendServer() + '/loginvue', {
                method: "POST", // *GET, POST, PUT, DELETE, etc.

                mode: "cors", // no-cors, *cors, same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "include", // include, *same-origin, omit
                headers: {
                    //"Content-Type": "application/json",
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: "follow", // manual, *follow, error
                referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify({
                    'email': this.email,
                    'username': this.username,
                    'password': this.password,
                    'remember_me': this.remember_me,
                    'login_type': this.login_type,
                }), // body data type must match "Content-Type" header
            }).then((response) => {
                return response.json();
            }).then((data) => {
                if (data == 'loginErrorEmail') {
                    this.setFlashedMessage("email is not registered on this site");
                    this.loading()
                }
                else if (data == 'notactivated') {
                    this.setFlashedMessage("Please check your Email to activate your account first");
                    this.loading()
                }
                else if (data == 'loginErrorUser') {
                    this.setFlashedMessage("username is not registered on this site");
                    this.loading()
                } else if (data == 'wrongPassword') {
                    this.setFlashedMessage("Wrong password");
                    this.loading()
                } else {
                    this.doUpdateUserInfo(data);

                    this.$router.push({ name: 'profile' })
                    this.loading()
                }


            })

        },


        change_login_method(event) {
            if (!event.target.classList.contains('active')) {
                this.email = ''
                this.username = ''
                this.username_method = !this.username_method
                this.email_method = !this.email_method
                this.login_type = this.login_type == 'email' ? 'username' : 'email'


            }

        },
        initGoogleSignIn() {
            google.accounts.id.initialize({
                client_id: "906235521597-39lqp5qu3klf8vceng0t5gvr3hudfu8a",
                callback: this.handleCredentialResponse,
            });
            google.accounts.id.renderButton(
                document.getElementById("buttonDiv"),
                { theme: "outline", text: "continue_with" } // customization attributes
            );
            // google.accounts.id.prompt(); // also display the One Tap dialog
        },
        handleCredentialResponse(response) {

            fetch(this.backendServer() + '/google-register', {
                method: "POST",
                mode: "cors", // no-cors, *cors, same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "include", // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: "follow", // manual, *follow, error
                referrerPolicy: "no-referrer",
                body: JSON.stringify({ 'token': response.credential }),
            })
                .then(response => response.json())
                .then(data => {
                    // Step 3: Handle the response from the backend


                    // Step 4: Check if registration was successful
                    if (data) {
                        // Registration successful, you can proceed with any additional actions

                        this.doUpdateUserInfo(data);

                        this.$router.push({ name: 'profile' })
                    } else {
                        // Registration failed, handle the error
                        console.error("User registration failed:", data.error);
                    }
                })
                .catch(error => {
                    // Step 5: Handle errors
                    console.error("Error during registration:", error);
                });
        }


    },
    mounted() {
        document.title = 'Login / Rossoneries';
        this.initGoogleSignIn();
    }
}
</script>

<style>
#buttonDiv {
    margin: 10px auto;
    max-width: 200px;
    min-height: 44px;
}

#remember {
    margin-left: 5px;
}
</style>