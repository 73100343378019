<template>
    <main class="background imageless_bg messages">
        <div class="pcon">
            <ProfileMenu />
            <div id="profile">
                <div v-if="ticket" class="ticket profile messages">
                    <ul class="posted_data single_ticket">
                        <li class="new_posted  tickets st">
                            <div class="post_title ticket_title">subject : {{ ticket.title }}</div>
                            <div class="ticket_form">
                                <textarea v-model="new_reply" ref="textAreaReply" placeholder="add a reply" name=""
                                    id="" cols="30" rows="10" class="ticket_textArea"></textarea>
                                <div v-if="new_reply && new_reply.length" class="comment_buttons">
                                    <button @click="newReply" class="button_submit bt">reply</button>
                                </div>
                            </div>
                            <div class="ticket_info">
                                <div v-for="reply in replies" :key="reply.id" class="each_ticket">
                                    <span class="ticket_info">by {{ reply.username }} on {{
                    reply.date }}:</span>
                                    <span class="ticket_content">{{ reply.content }}</span>
                                </div>
                                <div class="each_ticket">
                                    <span class="ticket_info">by {{ ticket.username }} on {{
                    ticket.date }}:</span>
                                    <span class="ticket_content">{{ ticket.content }}</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

        </div>

    </main>
</template>

<script>
import ProfileMenu from '@/components/profile/ProfileMenu.vue'

export default {
    name: 'TicketsVue',
    inject: ['backendServer', 'currentHost', 'setFlashedMessage'],
    components: { ProfileMenu },
    data() {
        return {
            'ticket': null,
            'replies': null,
            'id': window.location.href.split('/').pop(),
            'new_reply': null
        }
    },
    methods: {

        newReply() {
            if (this.new_reply == null)
                return
            fetch(this.backendServer() + '/ticket/' + this.id + '/reply', {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: "follow",
                referrerPolicy: "no-referrer",

                body: JSON.stringify({
                    'content': this.new_reply,
                    'title': this.ticket.title
                }),
            }).then((response) => {
                return response.json();
            }).then((data) => {
                this.new_reply = null
                if (data['error'])
                    this.setFlashedMessage(data['error'])

                else {
                    this.ticket = data['ticket']
                    this.replies = data['replies']
                }
            })
        }
    },
    mounted() {
        fetch(this.backendServer() + '/ticket/' + this.id, {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            credentials: "include",
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            // body: JSON.stringify({
            //     'page': this.currentPage
            // }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['error'])
                    this.setFlashedMessage(data['error'])

                else {
                    this.ticket = data['ticket']
                    this.replies = data['replies']
                    document.title = 'Ticket / ' + this.ticket.title


                }

            });

    }
}
</script>

<style scoped>
.bt {
    margin: 0 2px;
}

.bi-send {
    position: absolute;
    transform: rotate(45deg);
    right: 14px;
    bottom: 8px;
    cursor: pointer;
    opacity: 0;
    transition: opacity .3s linear;
}

.bi-send:hover {
    fill: red
}

ul.posted_data {
    margin-top: 0 !important;
}

/* ul.single_ticket {
    width: 90% !important;
} */

div.ticket_form textarea {
    font-size: .85em;
    height: 80px;
    width: 95% !important;
    vertical-align: middle;
    margin: 5px auto;
    border: none;
    transition: height .3s linear;
}

div.ticket_form {
    display: flex;
    flex-direction: column;
}

div.ticket_form textarea:focus~.bi-send {
    opacity: 1;

}

.comment_buttons {
    display: flex;
    margin-left: 20px;
    align-items: baseline;
    margin-bottom: 4px;
}

ul.single_ticket {
    width: 100%
}
</style>