<template>
    <div :class="logoDecider()">
        <div></div>
    </div>
</template>

<script>
export default {
    name: 'LeagueLogo',
    props: ['logo'],
    methods: {
        logoDecider() {
            switch (this.logo) {
                case 'logo-serie-a':
                    return 'logo-serie-a'
                case 'ucl':
                    return 'logo-ucl'
                case 'uel':
                    return 'logo-uel'
                default:
                    return
            }
        }
    }
}
</script>

<style></style>