<template>
    <main class="news all_posts">

        <article class="news news2">
            <ArticleLogo />
        </article>
        <PredictionMenu />

        <PredictionGame />
        <PredictionTable />
    </main>
</template>

<script>
import PredictionMenu from '@/components/predictions/PredictionMenu.vue'
import ArticleLogo from '../components/ArticleLogo.vue'
import PredictionGame from '@/components/predictions/PredictionGame.vue'
import PredictionTable from '@/components/predictions/PredictionTable.vue'

export default {
    name: 'LeagueInfo',
    components: { ArticleLogo, PredictionMenu, PredictionGame, PredictionTable },
    data() {
        return {

        }
    },
    mounted() {
        document.title = 'Prediction / Rossoneries';

    }

}
</script>

<style ></style>