<template>
    <div class="avatars">

        <img :src="profile_image" alt="" class="avatar" ref="avatar">
        <form action="" name="picture" method="post" enctype="multipart/form-data">
            <label>
                <div v-if="!profile_image_button" class="edit_picture">
                    <input type="file" class="buttonhide" ref="profile_picture" @change="newPicture">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                        id="profile_picture" class="bi bi-images" viewBox="0 0 16 16">
                        <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                        <path
                            d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2zM14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1zM2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1h-10z" />
                    </svg>
                    {{ error }}
                </div>
            </label>
            <input @click="sendPicture" v-if="profile_image_button" type="submit" class="update button_submit"
                value="update" @touchend="console.log('clicked')">
            <input @click="cancelPicture" v-if="profile_image_button" type="button" class="update button_submit"
                value="cancel">
        </form>

    </div>
</template>

<script>
export default {
    name: 'AvatarVue',
    inject: ['backendServer', 'profile_image', 'profile_image_change', 'doUpdateUserInfo', 'currentHost', 'loading'],
    data() {
        return {
            profile_image_button: false,
            image: null,
            error: ''
        }
    },
    methods: {

        newPicture(event) {
            event.preventDefault()
            this.error = ''
            const theFile = this.$refs.profile_picture.files[0]

            const fileExtension = theFile.name.split('.').pop().toLowerCase();

            const acceptedFormats = ['.gif', '.jpg', '.jpeg', '.png', '.webp', '.mp4', '.webm', '.mov', '.qt'];
            if (!acceptedFormats.includes('.' + fileExtension)) {
                this.error = 'Unsupported file format.supported formats are:.gif, .jpg,.jpeg,.png,.webp,.mp4,.webm,.mov,.qt';
                return;
            }
            // Check if the file size exceeds 20MB
            if (theFile.size > 2 * 1024 * 1024) {
                this.error = 'File size exceeds 2MB limit. Please select a smaller file.'
                return;
            }
            const reader = new FileReader()
            reader.addEventListener('load', event => {
                this.$refs.avatar.src = event.target.result;
            })
            reader.readAsDataURL(this.$refs.profile_picture.files[0])
            this.profile_image_button = !this.profile_image_button

            this.image = this.$refs.profile_picture.files[0]

        },
        cancelPicture(event) {
            event.preventDefault()

            this.$refs.avatar.src = this.profile_image;
            this.profile_image_button = !this.profile_image_button
            this.image = null
        },
        sendPicture(event) {
            event.preventDefault()
            this.loading()
            const formData = new FormData();

            formData.append('avatar', this.image, this.image.name);

            fetch(this.backendServer() + '/profilepic', {
                method: "POST",
                // mode: "cors",
                cache: "no-cache",
                credentials: "include",
                // headers: {
                //     'Content-Type': 'multipart/form-data'
                // },
                body: formData,

            }).then((response) => {



                return response.json()
            }).then((data) => {

                this.doUpdateUserInfo(data)
                this.$refs.avatar.src = this.profile_image;
                this.profile_image_button = !this.profile_image_button
                this.image = null
                this.loading()
            })
        }
    }
}
</script>

<style scoped>
.update {
    font-size: .7em;
    /* width: 60px; */
}

.bi-images {
    cursor: pointer;
}

div.edit_picture {
    max-width: 175px;
    font-size: 12px;
}
</style>