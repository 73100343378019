<template>
    <main class="news all_posts">

        <article class="news news2">
            <ArticleLogo :article="article" />
        </article>
        <LeagueLogo :logo="logo" />
        <div v-if="$route.path.includes('/serie-a')" :class="$route.path.includes('/serie-a') && 'sub_container_small'"
            class="sub_container_b">
            <NewsSubMenu name="Info" route="leagueinfo" />
            <NewsSubMenu name="Table" route="leaguetable" />
            <NewsSubMenu name="Matchday" route="matchday" />
            <NewsSubMenu name="Goal-Scorers" route="serieags" />
        </div>
        <ul class="league_info fixtures ">

            <div class="league_goalscorers">
                <GoalScorers v-if="activeSubMenu == 'table'" :limit="25" :currentYear="currentYear" key="currentYear"
                    :fullScreen="fullScreen" />
            </div>
        </ul>

    </main>
</template>

<script>
import ArticleLogo from '../components/ArticleLogo.vue'
import GoalScorers from '@/components/team/GoalScorers.vue'
import LeagueLogo from '@/components/LeagueLogo.vue'
import NewsSubMenu from '@/components/team/NewsSubMenu.vue'
export default {
    name: 'SerieAgs',
    components: { ArticleLogo, NewsSubMenu, GoalScorers, LeagueLogo },
    emits: ['news_direction'],
    data() {
        return {
            fullScreen: true,
            article: 'News',
            limit: undefined,
            activeMenu: 'serie A',
            activeSubMenu: 'table',
            currentYear: 'default',
            logo: 'logo-serie-a',
            title: '2023-24 Top Goalscorers'
        }
    },
    mounted() {
        document.title = 'Serie A top Goal Scorers / Rossoneries';

    }

}
</script>

<style >
.league_goalscorers {
    width: 100%;
}
</style>