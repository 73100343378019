<template>
    <div class="sub_container_small sub_container_b">
        <NewsMenu name="Info" route="prediction" />
        <NewsMenu v-if="available" name="My History" route="predictionhistory" />
        <NewsMenu name="Table" route="predictiontable" />
        <NewsMenu name="Rules" route="squad" />
    </div>
</template>

<script>
import NewsMenu from '../team/NewsMenu.vue';
export default {
    name: 'PredictionMenu',
    components: { NewsMenu },
    inject: ['backendServer', 'user_login_info', 'currentHost'],
    data() {
        return {
            available: false
        }
    },
    methods: {
        fetchData() {
            if (!this.user_login_info) {
                return
            }
            fetch(this.backendServer() + '/prediction-history-menu', {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {

                    this.available = data

                });
        },
    },
    mounted() {
        this.fetchData()
    }
}
</script>
<style></style>