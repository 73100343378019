<template>
    <div class="page_nums">
        <label v-for="(page, index) in pages" :key="index">
            <span v-if="page && page == current_page" class="post_clickable_pages active_span in_active">

                {{ page }}

            </span>
            <span v-else-if="page && page != current_page" class="post_clickable_pages in_active">
                <router-link v-if="id" :to="{ name: name, params: { page: page, id: id } }" @click="changePage">
                    {{ page }}
                </router-link>
                <router-link v-else :to="{ name: name, params: { page: page } }" @click="changePage">
                    {{ page }}
                </router-link>
            </span>
            <span v-if="!page && index">...</span>
        </label>
    </div>
</template>

<script>
export default {
    props: ["pages", "current_page", "name", "id"],
    methods: {
        changePage(event) {
            this.$emit('changePage', event.target.innerText)
            console.info('FSDAFSDAFSDA', this.name)
        }
    }

}

</script>

<style></style>