<template>
    <div :class="show && 'home_login_show'" class="home_login">
        <div class="home_login_body">
            <span class="home-titles">log in</span>
            <div class="home_login_form">
                <form action="/login" method="post">
                    <div class="change_login_method home_login_method">
                        <p @click="change_login_method" class="one" :class="email_method && 'active'">by email</p>
                        <p @click="change_login_method" class="two" :class="username_method && 'active'">by username</p>
                    </div>
                    <div class="choose_login_method">

                        <TextField v-if="email_method" name="email" type="text" v-model="email" />

                        <TextField v-if="username_method" name="username" type="username" v-model="username" />

                        <TextField name="password" type="password" v-model="password" />
                    </div>
                    <div class="remember">
                        <label for="remember">remember me</label>
                        <input id="remember" name="remember" type="checkbox" value="y" v-model="remember_me">
                    </div>

                    <input type="submit" name="submit" value="log in" class="button_submit" id="subone"
                        @click="validateLoginUser">


                    <span class="reset_request"><router-link :to="{ name: 'resetpassword' }">Forgot
                            Password?</router-link></span>

                </form>
            </div>
        </div>
    </div>
</template>

<script>

import TextField from '../TextField.vue';
export default {
    components: { TextField },
    data() {
        return {
            email: '',
            password: '',
            username: '',
            remember_me: false,
            article: 'Login',
            cookies: '',
            email_method: true,
            username_method: false,
            login_type: 'email',
            emailLabel: false,
        }
    },
    inject: ['backendServer', 'loading', 'setFlashedMessage', 'doUpdateUserInfo', 'currentHost',],
    props: ['show'],
    methods: {
        validateLoginUser(event) {
            event.preventDefault()
            if (this.login_type == 'email') {
                if (!this.email || !this.password) {
                    this.setFlashedMessage("please fill all the fields");
                    return
                } else if (this.password.length < 8 || !this.email.includes('@')) {
                    this.setFlashedMessage("wrong email or password,try again");
                    return
                } else {
                    this.doLogin(event)
                }

            }
            else if (this.login_type != 'email') {
                if (!this.username || !this.password) {
                    this.setFlashedMessage("please fill all the fields");
                    return
                } else if (this.password.length < 8 || this.username.length < 8) {
                    this.setFlashedMessage("wrong username or password,try again");
                    return
                } else {
                    this.doLogin(event)
                }
            }


        },
        doLogin(event) {

            event.preventDefault();
            this.loading()
            fetch(this.backendServer() + '/loginvue', {
                method: "POST", // *GET, POST, PUT, DELETE, etc.

                mode: "cors", // no-cors, *cors, same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "include", // include, *same-origin, omit
                headers: {
                    //"Content-Type": "application/json",
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: "follow", // manual, *follow, error
                referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify({
                    'email': this.email,
                    'username': this.username,
                    'password': this.password,
                    'remember_me': this.remember_me,
                    'login_type': this.login_type,
                }), // body data type must match "Content-Type" header
            }).then((response) => {
                return response.json();
            }).then((data) => {
                if (data == 'loginErrorEmail') {
                    this.setFlashedMessage("email is not registered on this site");
                    this.loading()
                }
                else if (data == 'notactivated') {
                    this.setFlashedMessage("Please check your Email to activate your account first");
                    this.loading()
                }
                else if (data == 'loginErrorUser') {
                    this.setFlashedMessage("username is not registered on this site");
                    this.loading()
                } else if (data == 'wrongPassword') {
                    this.setFlashedMessage("Wrong password");
                    this.loading()
                } else {
                    this.doUpdateUserInfo(data);

                    this.$router.push({ name: 'profile' })
                    this.loading()
                }


            })

        },


        change_login_method(event) {
            if (!event.target.classList.contains('active')) {
                this.email = ''
                this.username = ''
                this.username_method = !this.username_method
                this.email_method = !this.email_method
                this.login_type = this.login_type == 'email' ? 'username' : 'email'


            }

        },


    }
}
</script>

<style scoped>
::v-deep(.login_labels input) {
    height: 23px !important;
    transition: all .2s linear !important;
}

::v-deep(input:valid:not(:focus)~label) {
    top: -20% !important
}

::v-deep(div.login_labels) {
    width: fit-content;
    margin: 0 auto;
}



.choose_login_method {
    display: grid;
    font-size: 11px;
}

::v-deep(.login_labels input:focus) {
    width: 175px !important;
}

::v-deep(p) {
    margin-bottom: 4px;
}

::v-deep(div.remember) {
    font-size: 11px;
    height: 18px;
}
</style>