<template>
    <ul class="p-fixtures fixtures league_table" :class="!fullScreen && 'p_league_info'">
        <h5 class="league-info-header predict-info-header">Prediction Table</h5>

        <li class="standings seriaa sr">

            <table class="seriaa standings">
                <thead>
                    <tr>
                        <th style="padding-left: 5px;">username</th>
                        <th>Mp</th>
                        <th>exact</th>
                        <th>marg</th>
                        <th>just</th>
                        <th>points</th>
                    </tr>
                </thead>

                <tbody v-if="players">
                    <tr v-for="player in players" :key="player.id" class="teams" :class="checkUser(player.name)">

                        <td class="team pre-p"> <span>{{ player.position }}</span> -<img :src="player.image"
                                class="pre-pic">
                            <router-link :to="{ name: 'predictionhistoryid', params: { id: player.id } }">
                                {{ player.name }}
                            </router-link>

                        </td>
                        <td>{{ player.mp }}</td>
                        <td>{{ player.exact }}</td>
                        <td>{{ player.margin }}</td>
                        <td>{{ player.just }}</td>
                        <td>{{ player.points }}</td>

                    </tr>
                </tbody>


            </table>
            <div class="disfu">
                <button @click="loadMoreRows()" v-if="button" class="button_submit more-pre">Load more</button>
            </div>
            <table v-if="user && user.length > 0" class="seriaa standings">
                <thead>
                    <tr>
                        <th style="padding-left: 5px;">username</th>
                        <th>Mp</th>
                        <th>exact</th>
                        <th>marg</th>
                        <th>just</th>
                        <th>points</th>
                    </tr>
                </thead>

                <tbody v-if="players">
                    <tr v-for="player in user" :key="player.id" class="teams" :class="checkUser(player.name)">

                        <td class="team pre-p"> <span>{{ player.position }}</span> -<img :src="player.image"
                                class="pre-pic">
                            <router-link :to="{ name: 'predictionhistoryid', params: { id: player.id } }">
                                {{ player.name }}
                            </router-link>

                        </td>
                        <td>{{ player.mp }}</td>
                        <td>{{ player.exact }}</td>
                        <td>{{ player.margin }}</td>
                        <td>{{ player.just }}</td>
                        <td>{{ player.points }}</td>

                    </tr>
                </tbody>
            </table>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'PredictionTable',
    inject: ['backendServer', 'loading', 'currentHost', 'current_user'],
    props: ['fullScreen'],
    data() {
        return {
            players: null,
            user: null,
            default: 10,
            button: true
        }
    },
    methods: {
        checkUser(user) {
            if (!this.current_user) {
                return
            }
            if (this.current_user == user) {
                return 'highlight-team'
            }
        },
        loadMoreRows() {
            this.default += 10
            this.user = null
            this.fetchPredictionTable()
        },
        fetchPredictionTable() {
            this.loading()
            fetch(this.backendServer() + '/prediction-table/' + this.default, {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {

                    this.players = data.top
                    this.user = data.user

                    if (this.players.length < this.default) {
                        this.button = !this.button
                    }
                    // this.years = data.years
                    // this.$emit('years', this.years)

                    this.loading()
                });
        }
    },
    mounted() {
        this.fetchPredictionTable()
    }
}
</script>

<style>
ul.p_league_info {
    width: 90%;
    margin: 0 auto 0 !important;
    display: flex;
}



.disfu {
    margin: 12px 0;
}

.pre-p {
    max-width: 120px;
}

.pre-p a {
    text-decoration: none !important;
    color: initial !important;
}

.pre-pic {
    border-radius: 50%;
}

.more-pre {
    font-size: 13px;
}
</style>