<template>
    <div class="table-container">


        <li v-for="(group, index) in table" :key="index" class="ucl tablenews" :class="fullScreen ? 'standings' : 'seriaa'">

            <table class="seriaa" :class="fullScreen && 'standings'">
                <thead>
                    <tr>

                        <th colspan="2">group {{ group[0].group }}</th>

                        <th>M</th>
                        <th>W</th>
                        <th>D</th>
                        <th>L</th>
                        <th>Gls</th>
                        <th>Gd</th>
                        <th>pts</th>

                    </tr>
                </thead>

                <tbody v-if="table">
                    <tr v-for="(team, index) in group" :key="index" class="teams">
                        <td :class="[index + 1 < 3
                            ? 'cl_qualify'
                            : (index + 1 == 3
                                ? 'ul_qualify'
                                : 'relegate'
                            )]">
                            <div></div>
                        </td>
                        <td class="team"> <span>{{ index + 1 }}</span> - <img :src="team.logo" alt=""> {{ team.name }}</td>
                        <td>{{ team.matches }}</td>
                        <td>{{ team.wins }}</td>
                        <td>{{ team.draws }}</td>
                        <td>{{ team.losses }}</td>
                        <td>{{ team.goals_scored }}:{{ team.goals_con }}</td>
                        <td>{{ team.gd }}</td>
                        <td>{{ team.points }}</td>
                    </tr>
                </tbody>

            </table>
            <div v-if="!fullScreen" class="seriaa_full_table">
                <a href="">More</a>
            </div>


        </li>
        <li class="ucl tablenews">
            <div v-if="fullScreen" class="standings_info">
                <div class="standing_container">
                    <div class="cl_qualify"></div>Europa League Next Stage
                </div>
                <div class="standing_container">
                    <div class="ul_qualify"></div>Conference League qualifying phase
                </div>

                <div class="standing_container">
                    <div class="relegate"></div>Eliminated
                </div>

            </div>
        </li>
    </div>
</template>

<script>
export default {
    name: 'UelTable',
    inject: ['backendServer', 'currentHost', 'loading'],
    props: ['fullScreen'],
    data() {
        return {
            table: null,
            years: null,
            year: null
        }
    },
    methods: {
        // sorting(event) {
        //     this.year = event.target.value
        //     this.$emit('yearChange', this.year)
        //     
        //     this.loading()
        //     fetch(this.backendServer()+'/serie-a/' + this.year, {
        //         method: "GET",
        //         credentials: "include"
        //     })
        //         .then((response) => response.json())
        //         .then((data) => {

        //             this.table = data.teams;
        //             this.years = data.years
        //             this.loading()
        //         });
        // }
    },
    mounted() {
        this.loading()
        fetch(this.backendServer() + '/uel', {
            method: "GET",
            credentials: "include"
        })
            .then((response) => response.json())
            .then((data) => {

                this.table = data;
                // this.years = data.years

                this.loading()
            });

    }
}
</script>
<style scoped>
/* 
td:not(.team) {
    width: 30px;
} */
li div.standings_info {
    margin-top: 0;
}

li.ucl {
    margin-bottom: 5px !important;
}
</style>