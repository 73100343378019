<template>
    <div :class="show && 'home_register_show'" class="home_register">
        <div class="home_register_body">
            <span class="home-titles">sign up</span>
            <div class="home_register_form">
                <form action="/register" method="post">
                    <div class="home_register_form_content choose_login_method">
                        <TextField name="username" type="username" v-model="username" />
                        <TextField name="email" type="text" v-model="email" />
                        <TextField name="password" type="password" v-model="password" />
                        <TextField name="confirm password" type="password" v-model="passwordConfirm" />

                        <input type="submit" name="submit" value="sign up" class="button_submit" id="subone"
                            @click="validateRegisteringUser">

                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import TextField from '../TextField.vue';
export default {
    name: 'SignupHome',
    components: {
        TextField
    },
    props: ['show'],
    inject: ['backendServer', 'setFlashedMessage', 'currentHost'],
    data() {
        return {
            article: 'Register',
            username: '',
            email: '',
            password: '',
            passwordConfirm: '',
        }
    },
    methods: {

        validateRegisteringUser(event) {
            event.preventDefault()

            if (!this.username || !this.email || !this.password || !this.passwordConfirm) {
                this.setFlashedMessage("please fill all the fields");
                return
            }
            else if (this.username.length < 8 || this.password.length < 8) {
                this.setFlashedMessage("username and password must at least be 8 characters long");
                return
            }
            else if (this.password != this.passwordConfirm) {
                this.setFlashedMessage("Passwords does'nt match");
                return
            }
            else {
                this.doRegister(event)
            }

            // this.doLogin()

        },
        doRegister(event) {
            event.preventDefault();
            fetch(this.backendServer() + '/registering', {
                method: "POST", // *GET, POST, PUT, DELETE, etc.

                mode: "cors", // no-cors, *cors, same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "include", // include, *same-origin, omit
                headers: {
                    //"Content-Type": "application/json",
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: "follow", // manual, *follow, error
                referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify({
                    'email': this.email,
                    'username': this.username,
                    'password': this.password,
                    'password_confirm': this.passwordConfirm,
                }), // body data type must match "Content-Type" header
            }).then((response) => {
                return response.json();
            }).then((data) => {
                // If the user is logged in:
                // Router...
                if (data.username && data.email) {
                    this.setFlashedMessage("this user name and email has been registerd already")
                } else if (data.username) {
                    this.setFlashedMessage("username already taken")
                } else if (data.email) {
                    this.setFlashedMessage("email has been registerd already")
                } else if (data.success) {
                    this.setFlashedMessage("thank you dear " + this.username + "Please verify your email address to activate your account")
                }




            })

        },
    }
}
</script>

<style scoped>
::v-deep(.login_labels input) {
    height: 23px !important;
    transition: all .2s linear !important;
}

::v-deep(input:valid:not(:focus)~label) {
    top: -20% !important
}

::v-deep(div.login_labels) {
    width: fit-content;
    margin: 0 auto;
}



.choose_login_method {
    display: grid;
    font-size: 11px;
}

::v-deep(.login_labels input:focus) {
    width: 175px !important;
}

::v-deep(p) {
    margin-bottom: 4px;
}

::v-deep(div.remember) {
    font-size: 11px;
    height: 18px;
}
</style>