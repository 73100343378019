<template>
    <li class="fng">

        <div class="match-title">
            <span class="match-d">{{ game.date }}</span>
            <span class="c-name">{{ game.title }}</span>
            <span class="spacer"></span>
        </div>


        <div class="fixtures p-fixture" :key="state ? 'section6' : 'section5'">
            <div class="ht">
                <!-- Only show input when not in 'state' -->

                <div>
                    {{ game.home }} <img class="fixl" :src="game.homeLogo" alt="">
                </div>

            </div>
            <span v-if="game.h_result != -1" class="match-t">{{ game.h_result }}-{{ game.a_result }}</span>
            <span v-else class="match-t">{{ game.time }}</span>
            <div class="at">

                <div>
                    <img class="fixl" :src="game.awayLogo" alt=""> {{ game.away }}
                </div>
                <!-- Only show input when not in 'state' -->

            </div>
        </div>

        <!-- <div class="p-info">
                your prediction,awaiting result
            </div> -->
        <div class="fixtures p-fixture" :key="state ? 'section6' : 'section5'">

            <div class="ht pre-res">
                <!-- Only show input when not in 'state' -->

                predicted

            </div>
            <span class="match-t user-pre">{{ check.h_result }}-{{ check.a_result }}</span>
            <div v-if="check.points != -1" class="at pre-res">
                earned {{ check.points }} points
            </div>
            <div v-else class="at pre-res">
                awaiting calculation
            </div>
        </div>

    </li>
</template>

<script>
export default {
    name: 'PredictionComplete',
    inject: ['currentHost', 'loading', 'setFlashedMessage', 'user_login_info'],
    props: ['game', 'check'],
    data() {
        return {
            homeTeam: null,
            awayTeam: null,
            state: false,
        }
    },
    methods: {

    },
}
</script>

<style>
.p-fixture {
    font-size: 1.2em;
}

.pre-info {
    height: 30px;
    display: flex;
    font-size: .9em;
    align-items: center;
    justify-content: center;
    color: black;
}

.pre-res {
    font-size: .7em;
    height: 30px;
    font-family: Arial, Helvetica, sans-serif !important;
}

.p-fixture span.user-pre {
    font-size: .8em;
}
</style>