<template>
    <div id="overlay" class="show">
    </div>
</template>

<script>
export default {
    name: 'OverlayVue',
    inject: ['doOverlay'],
}
</script>

<style></style>