<template>
    <div v-if="thread" :class="show && 'home'" class="home_thread">
        <div class="home_post_title">
            <div>
                <img class="home_posted_profile_picture" :src="thread.profile_image">
                {{ thread.title }}
                <br> by {{ thread.author }}

            </div>
        </div>
        <div class="hct">
            {{ thread.content }}
            <PostMedia v-if="thread.media" :media="thread.media" :lheight="true" />
        </div>
        <router-link :to="{ name: 'allposts' }" class="nnd">
            <span class="more_p">Go to Threads</span> </router-link>
    </div>
</template>

<script>
import PostMedia from '../singlePost/PostMedia.vue';
export default {
    data() {
        return {
            thread: null
        }
    },
    inject: ['loading', 'setFlashedMessage', 'doUpdateUserInfo', 'backendServer',],
    props: ['show'],
    components: { PostMedia },
    mounted() {
        fetch(this.backendServer() + '/home-thread', {
            method: "GET",
            credentials: "include",
        })
            .then((response) => response.json())
            .then((data) => {

                this.thread = data;
            });
    }
}
</script>

<style scoped>
.home_thread {
    position: relative;
    overflow: hidden;
    height: 280px;
    width: 280px;
    border-radius: 50px;
    text-align: center;
    transition: background-image 1s ease-in-out;
    background-size: contain;
    margin: 10px;
    background-color: rgb(255, 255, 255, 0.89);
}

@media screen and (min-width:760px) {
    .home_thread {
        width: 350px;
    }

}

@media screen and (max-width:618px) {
    .home_thread {
        width: 350px;
    }

}

.nnd {
    background-color: rgb(251 204 204) !important;
}

img.ict {
    max-width: 35%;
    max-height: 400px;
    margin: 10px auto;
    border-radius: 21px;
}

.hct {
    max-height: 144px;
    padding: 0 5px;
    text-align: justify;
    overflow: auto;
    font-size: .8em;
}

div.home_post_title {
    font-size: 14px;
    text-decoration: none;
    color: #ff0000;
    background-color: rgb(251 204 204 / 100%);
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    padding: 7px;
    padding-left: 23px;
    width: 100%;
    text-align: left;
}

.home_posted_profile_picture {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50px;
}
</style>