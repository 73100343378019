<template>
    <main class="news all_posts">

        <article class="news news2">
            <ArticleLogo />
        </article>
        <LeagueLogo :logo="logo" />
        <div v-if="$route.path.includes('/serie-a')" :class="$route.path.includes('/serie-a') && 'sub_container_small'"
            class="sub_container_b">
            <NewsSubMenu name="Info" route="leagueinfo" />
            <NewsSubMenu name="Table" route="leaguetable" />
            <NewsSubMenu name="Matchday" route="matchday" />
            <NewsSubMenu name="Goal-Scorers" route="serieags" />
        </div>

        <ul class="league_info league_table">
            <TableNews v-if="activeSubMenu == 'table'" :fullScreen="fullScreen" @yearChange="yearChanged"
                :currentYear="currentYear" />
            <SwappedTeams :currentYear="currentYear" />
        </ul>

    </main>
</template>

<script>
import TableNews from '@/components/team/TableNews.vue'
import ArticleLogo from '../components/ArticleLogo.vue'
import LeagueLogo from '@/components/LeagueLogo.vue'

import SwappedTeams from '@/components/team/SwappedTeams.vue'
import NewsSubMenu from '@/components/team/NewsSubMenu.vue'

export default {
    name: 'LeagueTable',
    components: { ArticleLogo, TableNews, SwappedTeams, LeagueLogo, NewsSubMenu },
    data() {
        return {
            logo: 'logo-serie-a',
            title: 'Table',
            fullScreen: true,
            article: 'News',
            limit: undefined,
            activeMenu: 'serie A',
            activeSubMenu: 'table',
            currentYear: 'default'
        }
    },
    methods: {
        yearChanged(event) {
            this.currentYear = event
        },

    },
    mounted() {
        document.title = 'Serie A Table / Rossoneries';

    }

}
</script>

<style >
main ul.league_table {
    display: block;

}

ul.league_table table {
    font-size: 18px;
}
</style>