<template>
    <div :class="$route.path.includes('/profile') && 'sub_container_small'" class="sub_container_b psub">
        <NewsSubMenu name="Profile" route="profile" />
        <NewsSubMenu name="Notifications" route="notifications" />
        <NewsSubMenu name="Tickets" route="tickets" />
        <NewsSubMenu name="Threads" route="yourposts" />
        <NewsSubMenu name="Logout" @click="logout" />
    </div>
    <div class="profile_menu">
        <ul>
            <li>
                {{ current_user }}
            </li>
            <li>
                <img :src="profile_image" alt="" class="profile_menu_pic">
            </li>

            <li>
                <router-link :class="[$route.name == 'profile' ? 'active_bar' : '']"
                    :to="{ name: 'profile' }">Profile</router-link>
            </li>
            <li>
                <router-link :class="[$route.name.includes('notifications') ? 'active_bar' : '']"
                    :to="{ name: 'notifications' }">Notifications</router-link>
            </li>
            <li>
                <router-link :class="[$route.name.includes('tickets') ? 'active_bar' : '']"
                    :to="{ name: 'tickets' }">Tickets
                    <svg v-if="ticket_alert > 0" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                        fill="currentColor" class="bi bi-envelope-exclamation" viewBox="0 0 16 16">
                        <path
                            d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z" />
                        <path
                            d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5v1.5a.5.5 0 0 1-1 0V11a.5.5 0 0 1 1 0Zm0 3a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z" />
                    </svg></router-link>

            </li>
            <li>
                <router-link :to="{ name: 'yourposts' }">
                    Threads
                </router-link>
            </li>

            <li @click="logout">
                <span class="logout">
                    Logout<svg xmlns="http://www.w3.org/2000/svg" style="vertical-align: bottom" width="16" height="16"
                        fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                        <path d="M7.5 1v7h1V1h-1z" />
                        <path
                            d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                    </svg>
                </span>


            </li>

        </ul>

    </div>
</template>

<script>
import NewsSubMenu from '../team/NewsSubMenu.vue';
export default {
    name: 'ProfileMenu',
    inject: ['current_user', 'profile_image', 'ticket_alert', 'logout'],
    components: { NewsSubMenu }

}
</script>

<style scoped>
.psub {
    width: 100%;
    display: none;
}

div.profile_menu ul li {
    width: fit-content;
    list-style: none;
    margin: 0 auto;
    padding: 10px 0;
}

span {
    font-weight: inherit;
}

svg {
    vertical-align: middle !important;
}

a:hover,
span:hover {
    color: azure;
}
</style>