<template>
    <div class="all_matches">


        <div class="comp_title">
            <h6 class="league-info-header">{{ title }}</h6>
            <div>
                <select v-model="selectedOption" @change="sorting" name="" id="" class="year_options">
                    <option v-for="week in weeks" :key="week" :value="week"> {{ week }}</option>
                </select>
            </div>
        </div>
        <div v-for="(result, index) in results" :key="index" class="single_match"
            :class="availableResult(result.home_score)" @click="openResult($event, result.home_score)" :id="result.id">
            <span class="home_team">{{ result.home }} <img class="team_results_logo" :src="result.home_logo"
                    alt=""></span>
            <span v-if="!result.date.includes('within')" class="match_date">{{ result.date }}</span>
            <div v-if="result.home_score != -1" class="match_result" ref="chosenmatch" :id="result.id">
                <span class="home_result">
                    {{ result.home_score }}
                </span><span> - </span>
                <span class="away_result">
                    {{ result.away_score }}
                </span>
            </div>
            <div v-else class="match_result">
                <span v-if="result.status == 'postponed'" class="pp">postponed</span>
                <span v-else> {{ matchTime(result.time) }}
                </span>
            </div>


            <span class="away_team"> <img class="team_results_logo" :src="result.away_logo" alt=""> {{ result.away
                }}</span>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import TableNews from '@/components/team/TableNews.vue'
// eslint-disable-next-line no-unused-vars
import TeamResult from './TeamResults2.vue';
export default {
    name: 'WeeklyResult',
    inject: ['backendServer', 'currentHost', 'loading'],
    props: ['currentYear'],
    data() {
        return {
            results: null,
            week: 0,
            weeks: null,
            option: null,
            selectedOption: 38,
            title: 'Matchday',
            openedWindows: {}

        }
    },
    methods: {
        matchTime(time) {
            return time != '00:00' ? time : 'TBD'
        },

        weeklyResults() {
            this.loading()
            fetch(this.backendServer() + '/weeks/' + this.currentYear + '/' + this.week, {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {

                    this.results = data.results;
                    this.weeks = data.weeks
                    this.option = data.option
                    this.selectedOption = this.option
                    this.loading()
                });
        },
        availableResult(res) {

            let available = false;
            if (this.currentYear == 'default' || this.currentYear == '2023-24') {
                available = true
            }
            if (available && res != -1)
                return 'available-result'
        },
        sorting(event) {
            this.week = event.target.value

            this.weeklyResults()
        },
        openResult(event, res) {
            const targetDivId = event.target.closest('div').id;

            if (this.openedWindows[targetDivId]) {
                const existingWindow = this.openedWindows[targetDivId];

                // Check if the window is closed or not focused
                if (!existingWindow.closed) {
                    existingWindow.focus();
                    return;
                }
            }
            if (res == -1) {
                return
            }
            if (this.currentYear != 'default' && this.currentYear != '2023-24') {
                return
            }
            const width = 500;
            const height = 800;

            // Calculate the left and top positions to center the window
            const left = window.innerWidth / 2 - width / 2;
            const top = window.innerHeight / 2 - height / 2;

            const newWindow = window.open(`/serie-a/match/${event.target.closest('div').id}`, '_blank', `width=${width}, height=${height}, left=${left}, top=${top}`);
            newWindow.opener = null;
            if (newWindow) {
                this.openedWindows[targetDivId] = newWindow;

                newWindow.addEventListener('beforeunload', () => {
                    delete this.openedWindows[targetDivId];
                });
            }
        }
    },
    mounted() {
        this.weeklyResults()
    },
    watch: {
        currentYear: function (newVal) { // watch it

            this.week = 0
            this.weeklyResults()
            if (newVal != '2023-24')
                this.title = 'Latest Results'
            if (newVal == '2023-24')
                this.title = 'Current Matchday'
        }

    },

}
</script>

<style>
div.comp_title {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    justify-items: center;
}

div.comp_title a {
    text-decoration: none;
}

div.comp_title div {
    margin-left: 5px;
    z-index: 2;
}

span.home_team,
span.away_team {
    flex-basis: 40%;
}

div.match_result {
    flex-basis: 20%;
}

span.pp {
    color: red;
    font-size: .7em;
}

span.home_team {
    text-align: right;
    justify-content: flex-end;
    display: flex;
    align-items: center;
}

span.away_team {
    text-align: left;
    display: flex;
    align-items: center;
}

div.all_matches {
    background-color: rgb(255, 255, 255, 0.89);
    font-size: .85em;
    color: black;
    border-radius: 10px;
    margin-bottom: 2px;
}

div.single_match {
    display: flex;
    justify-content: space-evenly;
    position: relative;
    padding: 17px 0;
    border-bottom: 1px solid rgb(217 217 217);
    align-items: center;
}

div.single_match:last-child {
    border-bottom: none
}

div.match_info {
    display: grid;
}

span.match_date {
    position: absolute;
    top: -1px;
    left: 7px;
    font-size: .8em;
    color: darkslategray;
}

img.team_results_logo {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.available-result {
    cursor: pointer;
}

.available-result:hover {
    background-color: white;
}
</style>