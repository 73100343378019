<template>
    <main class="news all_posts">

        <article class="news news2">
            <ArticleLogo />
        </article>
        <PredictionMenu />

        <ul class="fixtures p-fixtures prediction">
            <h5 v-if="current_user == name" class="league-info-header predict-info-header">Your Previous Predictions</h5>
            <h5 v-else class="league-info-header predict-info-header">{{ name }}'s Previous Predictions</h5>

            <PredictionComplete v-for="game in games" :key="game.id" :game="game['finished_match']"
                :check="game['finished_prediction']" />
            <UserRank :player="points" />
        </ul>
    </main>
</template>

<script>
import PredictionMenu from '@/components/predictions/PredictionMenu.vue'
import ArticleLogo from '../components/ArticleLogo.vue'
import PredictionComplete from '@/components/predictions/PredictionComplete.vue'
import UserRank from '@/components/predictions/UserRank.vue'

export default {
    name: 'PredictionHistory',
    components: { ArticleLogo, PredictionMenu, PredictionComplete, UserRank },
    inject: ['backendServer', 'currentHost', 'current_user'],
    data() {
        return {
            games: null,
            id: 0,
            name: null,
            points: null
        }
    },
    methods: {
        pregressPrediction() {
            let x = window.location.href.split('/').pop()

            if (x && x != 'history') {
                this.id = x
            }


            fetch(this.backendServer() + '/prediction-history/' + this.id, {
                method: "GET",
                mode: "cors",
                cache: "no-cache",
                credentials: "include",

                redirect: "follow",
                referrerPolicy: "no-referrer",
            })
                .then((response) => response.json())
                .then((data) => {
                    this.name = data.name
                    this.games = data.all_matches
                    this.points = data.points

                });
        }
    },
    mounted() {
        document.title = 'Prediction History / Rossoneries';

        this.pregressPrediction()
    }

}
</script>

<style ></style>