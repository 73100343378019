<template>
    <main v-if="load" class="news all_posts single-match">

        <ul v-if="game" class="sm fixtures-si">
            <TeamResults2 :game="game" />

        </ul>
        <ShareWith :text="text" />

        <article class="news ">
            <ArticleLogo />
        </article>
        <ExtraNav />

    </main>
    <footer v-if="load">
        <p>all rights reserved by Rossoneries 2024</p>
    </footer>
</template>

<script>
import TeamResults2 from '@/components/team/TeamResults2.vue'
import ArticleLogo from '@/components/ArticleLogo.vue'
import ShareWith from '@/components/ShareWith.vue'
import ExtraNav from '@/components/ExtraNav.vue'
export default {
    name: 'SingleMatchResult',
    inject: ['backendServer', 'currentHost', 'loading', 'user_login_info'],
    components: { TeamResults2, ArticleLogo, ShareWith, ExtraNav },

    data() {
        return {
            load: false,
            game: null,
            currentYear: 'default',
            fullScreen: true,
            single: true,
            text: null
        }
    },

    mounted() {
        this.loading()
        let id = window.location.href.split('/').pop();

        fetch(this.backendServer() + '/single-results/milan/' + id, {
            method: "GET",
            credentials: "include"
        })
            .then((response) => response.json())
            .then((data) => {
                this.game = data[0];
                document.title = this.game.home + ' ' + this.game.ft + ' ' + this.game.away;

                this.load = !this.load
                this.text = this.game.home + '-' + this.game.away


                this.loading()

            });


    }
}
</script>

<style scoped>
li.sr {
    border-radius: none !important;
}

ul.sm {
    width: 100% !important;
    margin: 0 !important;
}

main.single-match {
    padding-top: 0 !important;
}
</style>