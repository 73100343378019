<template>
    <main class="news all_posts">

        <article class="news news2">
            <ArticleLogo :article="article" />
        </article>
        <LeagueLogo logo="ucl" />
        <div :class="$route.path.includes('/ucl') && 'sub_container_small'" class="sub_container_b">
            <NewsSubMenu name="Info" route="uclinfo" />
            <NewsSubMenu name="tables" route="uclstages" />
            <NewsSubMenu name="Matchday" route="uclmatchday" />
            <NewsSubMenu name="Goal-Scorers" route="uclgs" />
        </div>

        <ul class="league_info">
            <div class="info_table">
                <UclTable v-if="activeSubMenu == 'table'" :fullScreen="fullScreen" />
            </div>
            <div class="info_extra">
                <UclScorers v-if="activeSubMenu == 'table'" :limit="3" :currentYear="currentYear" key="currentYear" />
                <UclWeeklyResult :currentYear="currentYear" />
            </div>
        </ul>

    </main>
</template>

<script>
import UclTable from '@/components/team/UclTable.vue'
import ArticleLogo from '../components/ArticleLogo.vue'
import UclWeeklyResult from '@/components/team/UclWeeklyResult.vue'
import UclScorers from '@/components/team/UclScorers.vue'
import LeagueLogo from '@/components/LeagueLogo.vue'
import NewsSubMenu from '@/components/team/NewsSubMenu.vue'
export default {
    name: 'LeagueInfo',
    components: { ArticleLogo, UclTable, UclWeeklyResult, UclScorers, LeagueLogo, NewsSubMenu },
    emits: ['news_direction'],
    data() {
        return {
            fullScreen: true,
            article: 'News',
            limit: undefined,
            activeMenu: 'serie A',
            activeSubMenu: 'table',
            currentYear: 'default',
            title: 'info'
        }
    },
    methods: {
        yearChanged(event) {
            this.currentYear = event
        },
        activeMenuChange(event) {
            this.activeMenu = String(event)
            
        },
        activeSubMenuChange(event) {
            this.activeSubMenu = String(event)
            
        }
    },
    mounted() {
        document.title = 'Champions League / Rossoneries';

    }

}
</script>
<style>
div.sub_container_b {
    background-color: rgba(26, 28, 50, 0.8);
    width: fit-content;
    border-radius: 8px;
    color: aliceblue;
    align-items: center;
    margin: 1px auto;
    font-size: .8em;
    justify-content: space-around;
    transition: .3s right linear;
    margin: 5px auto;
    display: flex;
}
</style>