<template>
    <main class="news all_posts">

        <article class="news news2">
            <ArticleLogo />
        </article>
        <!-- <div v-if="$route.path.includes('/team')" class="sub_container_b">
            <NewsSubMenu name="fixtures" route="fixtures" />
            <NewsSubMenu name="results" route="results" />
            <NewsSubMenu name="Squad" route="squad" />

        </div> -->
        <ul class="squad">
            <SquadPlayers />
        </ul>

    </main>
</template>

<script>
// import NewsSubMenu from '@/components/team/NewsSubMenu.vue';
import SquadPlayers from '@/components/team/SquadPlayers.vue'
import ArticleLogo from '@/components/ArticleLogo.vue'
export default {
    name: 'SquadVue',
    components: { ArticleLogo, SquadPlayers },
}
</script>

<style>
ul.squad {
    display: flex;

    justify-content: center;
}
</style>