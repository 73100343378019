<template>
    <main class="admin">
        <nav>
            <li @click="changeBar" :class="!nav && 'active_bar'">results</li>
            <li @click="changeBar" :class="nav && 'active_bar'">news</li>
        </nav>
        <div></div>
        <AdminNews v-if="nav" />


        <ResultVue v-if="!nav" />

    </main>
</template>

<script>
import ResultVue from '@/components/admin/ResultVue.vue'
import AdminNews from '@/components/admin/AdminNews.vue'
export default {
    name: 'AdminsVue',
    components: { ResultVue, AdminNews },

    data() {
        return {
            nav: false,
        }
    },
    methods: {
        changeBar() {
            this.nav = !this.nav
        }
    }

}
</script>

<style scoped>
.active_bar {
    color: #ff4949 !important;
    font-weight: bold;
    pointer-events: none
}

nav {
    border-radius: 14px;
    width: 60%;
}

nav li {
    width: 50%;
    cursor: pointer;
}

main.admin {
    display: flex;
    align-items: center;
    flex-flow: column;
    color: azure;
}

main.admin nav {
    position: relative;
    z-index: 2;
}

main.admin div {
    margin-top: 100px
}

::v-deep(input,
    select) {
    border-radius: 15px !important;
    text-align: center !important;
}
</style>