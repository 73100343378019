<template>
    <div class="post_sort">
        sort by: <select @change="sorting" name="" id="select_options">
            <option value="latest">latest</option>
            <option value="oldest">oldest</option>
            <option value="most_comments">most comments</option>
            <option value="most_likes">most likes</option>

        </select>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'SortingPosts',
    methods: {
        sorting(event) {
            this.$emit('sort', event.target.value)
        }
    }
}
</script>

<style></style>