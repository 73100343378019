<template>
    <div class="home_news">
        <div class="home_news_body">
            <div v-if="news" class="image-slider" @mouseenter="stopSlideshow" @mouseleave="startSlideshow">
                <div class="slider-container">
                    <!-- Slide container to hold the images -->
                    <div class="slides" :style="{ transform: `translateX(${offset}px)` }">
                        <div v-for="(i, index) in news" :key="index" class="slide">
                            <img :src="i.img" />
                            <p class="news_content">
                                <!-- <span style="--i:0" class="float-right"></span>
                                <span style="--i:0" class="float-left"></span>

                                <span style="--i:0.4" class="float-right"></span>
                                <span style="--i:.4" class="float-left"></span>

                                <span style="--i:0.7" class="float-right"></span>
                                <span style="--i:0.7" class="float-left"></span>

                                <span style="--i:1.2" class="float-right"></span>
                                <span style="--i:1.2" class="float-left"></span> -->

                                <span style="--i:1" class="float-right"></span>
                                <span style="--i:1" class="float-left"></span>

                                <span style="--i:2.2" class="float-right"></span>
                                <span style="--i:2.2" class="float-left"></span>

                                <span style="--i:3.2" class="float-right"></span>
                                <span style="--i:3.2" class="float-left"></span>
                                <span style="--i:4.8" class="float-right"></span>
                                <span style="--i:4.8" class="float-left"></span>



                                {{ i.content }}
                            </p>
                            <router-link :to="{ name: 'news' }">
                                <span class="more_news">Go to News</span> </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NewsHome",
    inject: ["backendServer", "currentHost"],
    data() {
        return {
            news: null,
            offset: 0,
            currentIndex: 0,
            slideWidth: 0,
            slideshowInterval: null, // Store the interval ID for the slideshow
            windowWidth: window.innerWidth,

        };
    },
    mounted() {
        fetch(this.backendServer() + '/home-news', {
            method: "GET",
            credentials: "include",
        })
            .then((response) => response.json())
            .then((data) => {
                this.news = data;
            });
    },
    updated() {
        this.windowWidth = window.innerWidth
        if (this.slideWidth === 0) {
            this.slideWidth = this.$el.querySelector(".slide").offsetWidth;
            // Calculate the total width of the slides container
            const totalWidth = this.news.length * this.slideWidth;
            // console.log(this.slideWidth, totalWidth)
            // Set the width of the slides container
            this.$el.querySelector(".slides").style.width = totalWidth + "px";
            // Uncomment the following line to reset the slideshow when the window is resized
            // window.addEventListener("resize", this.resetSlideshow);
            this.startSlideshow();
        }
    },
    methods: {
        startSlideshow() {
            this.windowWidth = window.innerWidth,

                // console.log('now slide', this.currentIndex, this.offset)
                this.slideshowInterval = setInterval(() => {

                    this.currentIndex = (this.currentIndex + 1) % this.news.length;
                    if (this.windowWidth >= 760 || (this.windowWidth <= 618 && this.windowWidth >= 390)) {
                        this.offset = -this.currentIndex * 350;
                    }
                    else
                        this.offset = -this.currentIndex * 280;
                    // console.log('now slide', this.currentIndex, this.offset)

                }, 3000); // Change the time interval as needed
        },
        stopSlideshow() {
            clearInterval(this.slideshowInterval);
        },
        resetSlideshow() {
            // console.log('resetting')
            this.stopSlideshow();
            this.slideWidth = 0;
            this.offset = 0;
            this.slideshowInterval = null;
            this.currentIndex = 0
            this.startSlideshow();
        },

    },
    beforeRouteLeave(to, from, next) {
        this.stopSlideshow(); // Call stopSlideshow() before leaving the route
        next();
    },
    beforeUnmount() {
        this.stopSlideshow(); // Ensure slideshow is stopped when the component is destroyed
    }
    // watch: {

    //     windowWidth() {
    //         this.resetSlideshow()
    //     },

    // },
};
</script>

<style>
span.float-right {
    float: right;
    width: 1px;
    clear: right;
    height: 14px;
    padding: 0 calc(var(--i)*5px);

}

span.float-left {
    float: left;
    clear: left;
    width: 1px;
    height: 14px;
    padding: 0 calc(var(--i)*5px);

}

.clear-float {
    clear: both;
    /* float: left; */
}

.image-slider {
    width: 100%;
    overflow: hidden;
}

.slider-container {
    display: flex;

}

.slides {
    display: flex;
    transition: transform 0.5s ease;
}

.slide {
    width: 280px;
    height: 280px;
    position: relative;

}

.slide img {
    /* Ensure images don't exceed their container width */
    width: 100%;
    /* Set the image width to 100% of the parent container */
    height: 100%;
    object-fit: cover;
    /* Maintain the original aspect ratio */
}

div.home_news_body {
    padding: 0 !important;
    overflow: hidden;
    border-radius: 50px;

}

span.more_news {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.6);
    top: 21px;
    padding: 10px;
    transform: translate(-50%, -50%);
    width: 100%;
    left: 50%;
    opacity: 0;
    cursor: pointer;
    color: black;
    transition: 0.3s opacity linear, 0.3s background-color linear, 0.3s color linear;
}

span.more_news:hover {
    background-color: rgba(255, 255, 255, 0.9);
    color: red;
}

div.image-slider:hover .more_news {
    opacity: 1;
    visibility: visible;
}

.news_content {
    position: absolute;
    left: 50%;
    color: white;
    font-size: 11px;
    background-color: rgba(0, 0, 0, 0.8);
    height: 100px;
    padding: 0 1px;
    width: 100%;
    transform: translate(-50%, -50%);
    text-align: justify;
}
</style>