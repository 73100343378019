<template>
    <li class="fng">
        <transition name="fade" mode="out-in">
            <div v-if="!state" class="match-title" key="section1">
                <span class="match-d">{{ game.date }}</span>
                <span class="c-name">{{ game.title }}</span>
                <span class="spacer"></span>
            </div>
            <div v-else class="match-title" key="section2">
                <span class="match-d"></span>
                <span class="c-name">Your Prediction</span>
                <span class="spacer"></span>
            </div>
        </transition>
        <transition name="fade" mode="out-in">
            <div class="fixtures p-fixture" :key="state ? 'section6' : 'section5'">
                <div class="ht">
                    <!-- Only show input when not in 'state' -->

                    <div>
                        {{ game.home }} <img class="fixl" :src="game.homeLogo" alt="">
                    </div>
                    <input v-if="!state" type="number" v-model="homeTeam" min="0">
                </div>
                <span v-if="!state" class="match-t">{{ game.time != '00:00' ? game.time : 'TBD' }}</span>
                <span v-else class="match-t">{{ homeTeam }}-{{ awayTeam }}</span>
                <div class="at">
                    <input v-if="!state" type="number" v-model="awayTeam" min="0">
                    <div>
                        <img class="fixl" :src="game.awayLogo" alt=""> {{ game.away }}
                    </div>
                    <!-- Only show input when not in 'state' -->

                </div>
            </div>
        </transition>
        <transition name="fade" mode="out-in">
            <div class="submit-p" :key="state ? 'section4' : 'section3'">
                <div v-if="!state">
                    <input v-if="user_login_info" @click="stateChange" type="submit" class="submit-pb button_submit"
                        value="submit">
                    <div v-else class="p-reg">
                        please <router-link class="return_to_login" :to="{ name: 'login' }">login</router-link> or
                        <router-link class="return_to_register" :to="{ name: 'register' }">register</router-link> to use
                        this feature

                    </div>
                </div>
                <div v-else>
                    are you sure?
                    <input @click="predictionActivate" :id="game.id" type="submit" class="submit-pb button_submit"
                        value="yes">
                    <input @click="stateChange" type="submit" class="submit-pb button_submit" value="no">
                </div>
            </div>
        </transition>
    </li>
</template>

<script>
export default {
    name: 'PredictionHeader',
    inject: ['backendServer', 'currentHost', 'loading', 'setFlashedMessage', 'user_login_info'],
    props: ['game'],
    data() {
        return {
            homeTeam: null,
            awayTeam: null,
            state: false,
        }
    },
    methods: {
        stateChange() {
            if (!this.user_login_info || !this.homeTeam || !this.awayTeam || this.homeTeam < 0 || this.awayTeam < 0) {
                return
            }
            this.state = !this.state
        },
        predictionActivate(event) {
            if (!this.user_login_info || !this.homeTeam || !this.awayTeam || this.homeTeam < 0 || this.awayTeam < 0) {
                return
            }
            this.loading()
            const id = event.target.id
            const requestData = {
                home: this.homeTeam,
                away: this.awayTeam
            };
            fetch(this.backendServer() + '/user-prediction/' + id, {
                method: "POST",
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData)
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();


                })
                .then(() => {
                    // Handle success response
                    this.$emit('predictionProgress')
                    this.loading()
                })

        }
    },
}
</script>

<style>
.p-reg {
    font-size: .9em;
    text-align: center;
    width: 100%;
    height: 30px;
    line-height: 30px;
}
</style>