<template>
    <main class="news all_posts">

        <article class="news news2">
            <ArticleLogo />
        </article>
        <PredictionMenu />
        <PredictionTableVue :fullScreen="true" />

    </main>
</template>

<script>
import PredictionMenu from '@/components/predictions/PredictionMenu.vue'
import ArticleLogo from '../components/ArticleLogo.vue'
import PredictionTableVue from '@/components/predictions/PredictionTable.vue'
export default {
    name: 'PredictionHistory',
    components: { ArticleLogo, PredictionMenu, PredictionTableVue },
    inject: ['currentHost', 'current_user', 'loading'],
    data() {
        return {
            fullScreen: true
        }
    },
    mounted() {
        document.title = 'Prediction Table / Rossoneries';

    }


}
</script>

<style ></style>