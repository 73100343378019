<template>
    <img @click="chosenImage" :id="image.info" :src="image.link" alt="" :class="chosen && 'chosen'">
</template>

<script>
export default {
    name: 'SingleImage',
    props: ['image', 'value'],
    inject: ['backendServer', 'currentHost', 'loading'],
    data() {
        return {
            chosen: false
        }
    },
    methods: {
        chosenImage() {
            this.chosen = !this.chosen
            this.$parent.$emit('addPicture', this.image.info)

        }
    }
}
</script>

<style>
img.chosen {
    border: 2px red solid
}
</style>