<template>
    <ul class="fixtures p-fixtures prediction">
        <h5 class="league-info-header predict-info-header">Current Match Prediction</h5>
        <PredictionAwaits v-if="game && !check" :game="game" @predictionProgress="pregressPrediction" />
        <PredictionComplete v-else-if="check" :game="game" :check="check" />
        <div v-if="finished && finished.length" style="width: 100%;">
            <h5 class="league-info-header predict-info-header">Previous Predictions</h5>
            <PredictionComplete v-for="i in finished" :key="i" :game="i.finished_match" :check="i.finished_prediction" />
        </div>
    </ul>
</template>

<script>
import PredictionAwaits from './PredictionAwaits.vue'
import PredictionComplete from './PredictionComplete.vue'
export default {
    name: 'PredictionGame',
    inject: ['backendServer', 'currentHost', 'loading', 'setFlashedMessage', 'user_login_info'],
    components: { PredictionAwaits, PredictionComplete },
    data() {
        return {
            game: null,
            check: null,
            finished: null
        }
    },
    methods: {
        pregressPrediction() {

            fetch(this.backendServer() + '/prediction-games', {
                method: "GET",
                mode: "cors",
                cache: "no-cache",
                credentials: "include",

                redirect: "follow",
                referrerPolicy: "no-referrer",
            })
                .then((response) => response.json())
                .then((data) => {

                    this.check = data['checked']
                    this.game = data['match'];
                    this.finished = data['finished']

                });
        }
    },
    mounted() {
        this.pregressPrediction()
    }
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

@media screen and (max-width: 420px) {
    li.fng {
        font-size: .75em;
    }

}

@media screen and (max-width: 500px) {
    div.ht div {
        flex-direction: column-reverse;
    }

    div.at div {
        flex-direction: column;
    }

}

.predict-info-header {
    margin-bottom: 0;
}

div.ht,
div.at {
    flex-basis: 45%;
    display: flex;
    align-items: center;
    width: 100%;
    /* justify-content: center; */
    font-family: monospace;
    position: relative;
}

div.ht div {
    display: flex;
    align-items: center;
    text-align: center;

}

div.at div {
    text-align: center;
    align-items: center;
    display: flex;
}

input.submit-pb {
    height: 22px;
    margin: 4px;
    padding: 0 6px;
    width: auto;
}

input {
    width: 50px;
    border-radius: 8px;
    margin: 0 5px;
    border: none;
    text-align: center;
    font-size: .9em;

}

ul.p-fixtures {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
</style>