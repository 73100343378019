<template>
    <main class="news all_posts">

        <article class="news news2">
            <ArticleLogo :article="article" />
        </article>
        <LeagueLogo logo="uel" />
        <div :class="$route.path.includes('/uel') && 'sub_container_small'" class="sub_container_b">
            <NewsSubMenu name="Info" route="uelinfo" />
            <NewsSubMenu name="tables" route="uelstages" />
            <NewsSubMenu name="Matchday" route="uelmatchday" />
            <NewsSubMenu name="Goal-Scorers" route="uelgs" />
        </div>

        <ul class="league_info">
            <UelTables :fullScreen="fullScreen" />
        </ul>

    </main>
</template>

<script>
import ArticleLogo from '../components/ArticleLogo.vue'
import LeagueLogo from '@/components/LeagueLogo.vue'
import NewsSubMenu from '@/components/team/NewsSubMenu.vue'
import UelTables from '@/components/uel/UelTables.vue'
export default {
    name: 'UelStages',
    components: { ArticleLogo, UelTables, NewsSubMenu, LeagueLogo },
    data() {
        return {
            fullScreen: true,
            article: 'News',
            limit: undefined,
            activeMenu: 'serie A',
            activeSubMenu: 'table',
            currentYear: 'default',
            title: '2024 Stages'
        }
    },
    mounted() {
        document.title = 'UEL Stages / Rossoneries';

    }

}
</script>
<style></style>