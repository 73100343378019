<template>
    <main class="news">

    </main>
</template>

<script>
export default {
    name: 'ActivateAccount',
    inject: ['backendServer', 'setFlashedMessage', 'loading', 'doUpdateUserInfo'],
    mounted() {
        this.loading();
        const token = this.$route.query.token;
        const userId = this.$route.query.user_id;
        if (!token || !userId) {
            this.$router.push({ name: 'home' })
            return
        }
        fetch(this.backendServer() + `/activation/${token}/${userId}`, {
            method: "GET",
            cache: "no-cache",
            credentials: 'include',
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data == 'error') {
                this.setFlashedMessage("email is not registered on this site");
                this.loading()
            }

            else {
                this.doUpdateUserInfo(data);

                this.$router.push({ name: 'profile' })
                this.loading()
            }
        })
    }
}
</script>

<style></style>