<template>
    <main class="news all_posts create_post">
        <article class="news news2">
            <ArticleLogo :article="article" />
            <div class="article_container">
                <SortingPosts @sort="sortingPosts">
                    <div v-if="user_login_info" class="svg_post">
                        <AddPost @click="openNewPost" />
                    </div>
                </SortingPosts>

            </div>

        </article>
        <div id="post_body">

            <ul class="news post_complete" id="all_posts">
                <li v-for="post in posts" :key="post.id" class="new_posted all_posts">
                    <PostBlock :post="post" @reloadPost="fetchData" />
                </li>

            </ul>
            <PaginationVue v-if="this.pages && this.pages.length > 1" name="postsPaginate" :current_page="currentPage"
                :pages="pages" @changePage="pageChanged" />

            <transition name="fade">

                <AddPostActive v-if="add_new_post" @close="openNewPost" @NewPostSubmitted="PostReciever" />

            </transition>

        </div>
    </main>
</template>

<script>
import ArticleLogo from "../components/ArticleLogo.vue"

import PostBlock from "../components/PostBlock.vue"
import PaginationVue from "../components/PaginationVue.vue"
import AddPost from "../components/allPosts/AddPost.vue"
import AddPostActive from "../components/allPosts/AddPostActive.vue"
import SortingPosts from "../components/allPosts/SortingPosts.vue"


export default {
    name: "AllPosts",
    components: { ArticleLogo, PostBlock, PaginationVue, AddPost, AddPostActive, SortingPosts },
    props: ['user_login_info'],
    inject: ['backendServer', 'current_user', 'currentHost', 'loading'],
    data() {
        return {
            article: 'Threads',
            posts: null,
            pages: null,
            add_new_post: false,
            overlay: false,
            sort: 'latest',
            page: null,
        }
    },
    computed: {
        currentPage() {

            if (this.page)
                return this.page;

            let endOfURL = window.location.href.split('/').pop();
            let number = +endOfURL;
            number = number > 0 ? number : 1


            return number
        }

    },
    methods: {
        sortingPosts(sortName) {
            this.sort = sortName;

            this.pageChanged(1)

        },
        pageChanged(pageNumber) {
            this.loading()

            this.page = pageNumber
            fetch(this.backendServer() + '/posts', {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: "follow",
                referrerPolicy: "no-referrer",
                body: JSON.stringify({
                    'page': pageNumber,
                    'sort': this.sort
                }),
            })

                .then((response) => response.json())
                .then((data) => {
                    this.posts = data['object'];
                    this.pages = data['pages']
                    this.loading()
                });

        },
        openNewPost() {
            this.add_new_post = !this.add_new_post
            // this.overlay = !this.overlay
        },
        PostReciever(event) {
            this.loading()

            this.add_new_post = !this.add_new_post
            this.overlay = !this.overlay
            const formData = new FormData();
            formData.append('content', event.content);
            formData.append('title', event.title);

            for (let i = 0; i < event.image_file.length; i++) {
                formData.append('image_file[]', event.image_file[i], event.image_file[i].name);
            }
            fetch(this.backendServer() + '/ajaxnewpost', {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "include",
                body: formData,
            }).then((response) => {
                return response.json();
            }).then((data) => {
                // If the user is logged in:
                // Router...
                this.posts = data
                this.loading()
            })


        },
        fetchData() {

            this.loading()

            fetch(this.backendServer() + '/posts', {

                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: "follow",
                referrerPolicy: "no-referrer",
                body: JSON.stringify({
                    'sort': this.sort,
                    'page': this.currentPage
                }),
            })

                .then((response) => response.json())
                .then((data) => {
                    this.posts = data['object'];

                    this.pages = data['pages']
                    this.loading()

                });
        },
    },

    mounted() {
        document.title = 'Threads / Rossoneries';
        // console.log('node is:', process.env.VUE_APP_TITLE)

        this.fetchData()
    }

}
</script>

<style>
.fade-enter-from {
    opacity: 0;
}

.fade-enter-to {
    opacity: 1;
}

.fade-enter-active {
    transition: all 1s ease-out;
}

.fade-leave-from {
    opacity: 1;
}

.fade-leave-to {
    opacity: 0;
}

.fade-leave-active {
    transition: opacity .3s ease-out;
}

main.news {
    position: relative;
}
</style>