<template>
    <main class="news all_posts">

        <article class="news news2">
            <ArticleLogo :article="article" />
        </article>


        <ul class="news post_complete only_news">
            <NewsTwitter />
            <!-- <HeadLinesVue v-if="activeSubMenu == 'bulletin'" /> -->
        </ul>

    </main>
</template>

<script>

// import NewsMenu from '@/components/team/NewsMenu.vue'
import ArticleLogo from '../components/ArticleLogo.vue'
import NewsTwitter from '@/components/team/NewsTwitter.vue'
// import HeadLinesVue from '@/components/team/HeadLines.vue'
// import NewsSubMenuVue from '@/components/team/NewsSubMenu.vue'

export default {
    name: 'NewsVue',
    components: { ArticleLogo, NewsTwitter },
    emits: ['news_direction'],
    data() {
        return {
            fullScreen: true,
            article: 'News',
            limit: 10,
            activeMenu: 'news',
            activeSubMenu: 'reports'
        }
    },
    methods: {
        activeMenuChange(event) {
            this.activeMenu = String(event)
            
        },
        activeSubMenuChange(event) {
            this.activeSubMenu = String(event)
            
        }
    },
    mounted() {
        document.title = 'News / Rossoneries';

    }
}
</script>

<style>
ul.only_news {
    justify-content: center;
    margin-top: 20px;
}
</style>