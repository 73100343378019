<template>
    <div class="post_title">
        <!-- <img class="posted_profile_picture" :src="post.profile_image"> -->
        <div class="prpi">
            <PoppedProfile :pic="post.pic" :size="true" :clicked="pclick" :id="post.pic.id" />
            <router-link :to="{ name: 'singlePost', params: { id: post.id } }">
                {{ post.title }}
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black"
                    class="expand-post bi bi-arrows-angle-expand" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                        d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707z" />
                </svg>
            </router-link>
        </div>
        <span @click="changeStatus" class="authn">{{ post.author }}</span>
        <!-- by<router-link :to="{ name: 'userposts', params: { id: post.user_id } }" class="user_post">{{ post.author
        }}</router-link> -->
        <div class="time_posted">
            ({{ post.date }})
        </div>
    </div>
</template>

<script>
import PoppedProfile from '../profile/PoppedProfile.vue'
export default {
    name: "PostsLike",
    props: ['post'],
    components: { PoppedProfile },
    data() {
        return {
            pclick: 1
        }
    },
    methods: {
        changeStatus() {
            this.pclick += 1
        }
    }

}
</script>

<style>
div.prpi {
    padding: 0 !important;
    align-items: center !important;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
}

.profile_box_img {
    width: 50px !important;
    height: 50px !important;
}

.authn {
    cursor: pointer;
    transition: .4s linear all;
}

.authn:hover {
    color: chocolate;
}
</style>