
<template>
    <div id="overlay" class="show">

        <div class="adding_post">
            <ul class="posted_data post_complete">

                <li class="new_posted all_posts">
                    <div class="post_title">

                        <img v-if="profileImage" class="posted_profile_picture" :src="profileImage" alt="">
                        <br>by {{ current_user }}
                        <br>
                        <form class="new_discussion">
                            <input ref="title" class="title" type="text" v-model="post_title" placeholder="Title">
                            <textarea class="title" ref="textAreaReply" name="" v-model="post_content" id="" cols="30"
                                rows="10" placeholder="Content"></textarea>
                            <br>
                            <div v-if="imageFile" class="image_container">

                                <!-- <img class="post_image" src="#" alt="" ref="postImage"> -->
                                <div v-for="(m, index) in sources" :key="index">
                                    <span class="insv">
                                        <img v-if="m.type == 'image'" class="post_image" :src="m.src" alt="">
                                        <video v-else class="post_v">
                                            <source :src="m.src" type="video/mp4">

                                        </video>
                                        <svg @click="closeImage(index)" xmlns="http://www.w3.org/2000/svg" width="16"
                                            height="16" fill="currentColor" class="close_image bi bi-x-circle"
                                            viewBox="0 0 16 16">
                                            <path @click="closeImage(index)"
                                                d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path
                                                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </span>

                                </div>

                            </div>
                            <div class='buttons_and_emoji'>
                                <EmojisVue @emoji="addEmoji" />
                                <label v-if="!profile_image_button">
                                    <span class="edit_picture">
                                        <input type="file" class="buttonhide" ref="post_picture" @change="newPicture"
                                            accept=".jpg, .jpeg, .png, .gif, .webp, .mp4, .webm, .mov, .qt">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                            id="profile_picture" class="bi bi-images" viewBox="0 0 16 16">
                                            <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                                            <path
                                                d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2zM14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1zM2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1h-10z" />
                                        </svg>
                                    </span>
                                </label>

                                <input type="button" value="post" class="button_submit submit_reply" id="post_submit"
                                    @click="newPostJson">
                                <input type="button" value="cancel" class="button_submit" id="post_cancel"
                                    @click="cancelPost">
                            </div>

                            <span v-if="error" class="fierr">!{{ error }}</span>

                        </form>

                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import EmojisVue from '../EmojisVue.vue'
export default {

    inject: ['current_user', 'profile_image'],
    components: { EmojisVue }
    , data() {
        return {
            // initial data based on injected value
            profileImage: this.profile_image,
            post_content: '',
            post_title: '',
            vsrc: [],
            imageFile: false,
            media: [],
            profile_image_button: false,
            vava: false,
            type: null,
            error: null,
            sources: []

        }
    },
    methods: {
        addEmoji(emoji) {
            
            let reply = this.$refs.textAreaReply.value
            let caret = this.$refs.textAreaReply.selectionStart
            reply = reply.substring(0, caret) + emoji + reply.substring(caret)
            //this.$refs.textAreaReply.value = reply
            this.post_content = reply
            this.$refs.textAreaReply.focus()
        },
        cancelPost() {
            
            this.$emit('close')
        },
        newPostJson() {
            if (!this.post_title) {
                this.$refs.title.focus()
                this.$refs.title.placeholder = 'Title is required'
                return
            }
            if (!this.post_content && !this.image) {
                this.$refs.textAreaReply.focus()
                this.$refs.textAreaReply.placeholder = 'Content is required'
                return
            }
            
            // const formData = new FormData();
            // formData.append('image_file', this.image, this.image.name);
            this.$emit('newPostSubmitted', { 'content': this.post_content, 'title': this.post_title, 'image_file': this.media })

            // } else {
            //     this.$emit('newPostSubmitted', { 'content': this.post_content, 'title': this.post_title, 'image_file': '' })

            // }

        },
        newPicture() {
            this.error = null
            const reader = new FileReader()
            const theFile = this.$refs.post_picture.files[0]
            if (!theFile) return;
            const fileExtension = theFile.name.split('.').pop().toLowerCase();

            const acceptedFormats = ['.gif', '.jpg', '.jpeg', '.png', '.webp', '.mp4', '.webm', '.mov', '.qt'];
            if (!acceptedFormats.includes('.' + fileExtension)) {
                this.error = 'Unsupported file format.supported formats are:.gif, .jpg,.jpeg,.png,.webp,.mp4,.webm,.mov,.qt';
                return;
            }
            // Check if the file size exceeds 20MB
            if (theFile.size > 20 * 1024 * 1024) {
                this.error = 'File size exceeds 20MB limit. Please select a smaller file.'
                return;
            }
            this.imageFile = true




            reader.readAsDataURL(theFile)
            
            const theFileType = theFile.type
            reader.addEventListener('load', event => {
                

                // if (theFileType.split('/')[0] == 'image') {

                //     this.$refs.postImage.src = event.target.result;

                // if (theFileType.split('/')[0] == 'video') {
                //     this.type = theFileType
                //     this.vava = !this.vava
                //     this.vsrc.push(event.target.result)
                //     let obj ={};
                // obj[theFileType.split('/')[0]] = event.target.result
                // this.sources.push(obj)
                // }
                let obj = {
                    type: theFileType.split('/')[0],
                    src: event.target.result
                };

                this.sources.push(obj)
                

            })

            this.image = theFile
            this.media.push(theFile)
            if (this.media && this.media.length > 3) {
                
                this.profile_image_button = true
                    ;
            }            // )
            // this.profile_image_change(this.$refs.profile_picture.files[0])



        },
        closeImage(index) {
            if (this.media && this.media.length) {
                this.media.splice(index, 1)
                this.sources.splice(index, 1)
                
            }
            
            // this.imageFile = !this.imageFile
            if (this.media.length <= 3) {
                this.profile_image_button = false

            }
            this.image = null
            this.vava = false
            this.type = null
            // if (this.$refs.postImage) {
            //     this.$refs.postImage.src == ''

            // }
        }
    }

}
</script>

<style>
.fierr {
    color: rgb(187 0 0);
    font-size: .8em
}

img.post_image {
    max-width: 100px;
    max-height: 250px;
    border-radius: 12px;
    margin-bottom: 2px;
    position: relative;
}

svg.post_image {
    position: absolute;
    right: 5px;
    bottom: 5px;
}

.insv {
    position: relative
}

.post_v {
    max-height: 130px;
}

.new_discussion {
    position: relative;
}

.title::placeholder {
    opacity: .5;
}

div.buttons_and_emoji {
    display: flex;
    width: fit-content;
    align-items: baseline;
}

div.buttons_and_emoji label {
    margin: 0 5px;
}

input.submit_reply {
    margin-left: 5px;
}

div.image_container {
    display: flex;
}

svg.close_image {
    position: absolute;
    left: 2px;
    cursor: pointer;
    z-index: 2;
    fill: cyan;
}

svg.close_image path {
    cursor: pointer;
}

svg.close_image:hover {
    fill: rgb(255, 255, 255);
}
</style>