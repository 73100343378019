<template>
    <main class="news all_posts">

        <article class="news news2">
            <ArticleLogo :article="article" />
        </article>
        <LeagueLogo logo="ucl" />
        <div :class="$route.path.includes('/ucl') && 'sub_container_small'" class="sub_container_b">
            <NewsSubMenu name="Info" route="uclinfo" />
            <NewsSubMenu name="tables" route="uclstages" />
            <NewsSubMenu name="Matchday" route="uclmatchday" />
            <NewsSubMenu name="Goal-Scorers" route="uclgs" />
        </div>

        <ul class="league_info">
            <UclTable :fullScreen="fullScreen" />
        </ul>

    </main>
</template>

<script>
import UclTable from '@/components/team/UclTable.vue'
import ArticleLogo from '../components/ArticleLogo.vue'
import LeagueLogo from '@/components/LeagueLogo.vue'
import NewsSubMenu from '@/components/team/NewsSubMenu.vue'
export default {
    name: 'UclStages',
    components: { ArticleLogo, UclTable, NewsSubMenu, LeagueLogo },
    data() {
        return {
            fullScreen: true,
            article: 'News',
            limit: undefined,
            activeMenu: 'serie A',
            activeSubMenu: 'table',
            currentYear: 'default',
            title: '2024 Stages'
        }
    },
    mounted() {
        document.title = 'UCL Stages / Rossoneries';

    }

}
</script>
<style></style>