<template>
    <li class="profilebg">
        <span v-if="!profile_update" class="currentUser">
            {{ titleDecider(type) }} {{ name }}
        </span>

        <input v-if="profile_update && name !== 'bio'" type="text" class="input_profile visible" :placeholder="placeholder"
            :id="name" v-model="fieldValue">
        <textarea v-else-if="profile_update && name == 'bio'" v-model="fieldValue" :id="name" cols="30" rows="10"
            class="exptf" placeholder="100 characters Max"></textarea>
        <span v-if="profile_update && name == 'bio'" class="mll">
            {{ MaxLength() }}
        </span>
        <span v-if="!profile_update" @click="editingProfile" class="currentUser">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="uname bi bi-pen"
                viewBox="0 0 16 16">
                <path
                    d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
            </svg>
        </span>
        <input v-if="profile_update" type="button" class="update input_profile button_submit" value="submit"
            @click="validateProfileUpdate">

    </li>
    <OverlayVue v-if="profile_update" @click="editingProfile" />
</template>

<script>
import OverlayVue from "@/components/OverlayVue.vue"

export default {
    name: 'ProfileFields',
    components: { OverlayVue },
    props: ['name', 'placeholder', 'modelValue', 'type'],
    inject: ['backendServer', 'doOverlay', 'setFlashedMessage', 'doUpdateUserInfo', 'loading', 'currentHost'],

    data() {
        return {
            profile_update: false,
            fieldValue: '',
            max: 100
        }
    },
    methods: {
        MaxLength() {

            let length = this.max - this.fieldValue.length
            return length
        },
        titleDecider(title) {
            switch (title) {
                case 'Bio':
                    return ''
                case 'web':
                    return 'website or social Media: '
                default:
                    return title + ': '
            }
        },
        editingProfile() {
            this.profile_update = !this.profile_update

        },
        validateAge(value) {
            let dateString = value;
            let dateRegex = /^\d{2}\/\d{2}\/\d{4}$/; // Regular expression for "MM/DD/YYYY" format

            if (!dateRegex.test(dateString)) {
                // Invalid date format
                this.setFlashedMessage("Invalid date format. Please use MM/DD/YYYY.");
                return;
            }

            let parts = dateString.split('/');
            let year = parseInt(parts[2]);
            let month = parseInt(parts[0]); // Adjust month to be zero-based
            let day = parseInt(parts[1]);

            // Check if the date is valid
            if (isNaN(year) || isNaN(month) || isNaN(day)) {
                this.setFlashedMessage("Invalid date. Please provide a valid birthdate.");
                return;
            }

            let birthDate = new Date(year, month, day);

            if (isNaN(birthDate.getTime())) {
                this.setFlashedMessage("Invalid date. Please provide a valid birthdate.");
                return;
            }

            // If you want to check for a realistic age (e.g., not more than 100 years ago)
            let currentDate = new Date();
            let age = currentDate.getFullYear() - birthDate.getFullYear();

            if (age < 0 || age > 100) {
                // Unrealistic age
                this.setFlashedMessage("Unrealistic age! Please provide a valid birthdate.");
                return;
            }

            // If you want to convert it to a timestamp
            let timestamp = birthDate.getTime();
            this.fieldValue = timestamp / 1000

            this.updateProfileInfo()
        },
        validateProfileUpdate(event) {
            event.preventDefault()
            if (!this.fieldValue && this.type != 'Bio')
                return
            if (this.type == 'username' && this.fieldValue.length < 8) {
                this.setFlashedMessage("username must at least be 8 characters long");
                return
            }
            if (this.type == 'Bio' && this.fieldValue.length > 100) {
                this.setFlashedMessage("bio can be 100 characters max");
                return
            }
            if (this.type == 'age') {
                this.validateAge(this.fieldValue)
                return
            }
            if (this.type == 'email' && !this.fieldValue.includes('@')) {
                this.setFlashedMessage("invalid email");
                return
            }
            this.updateProfileInfo()
        },
        updateProfileInfo() {
            this.loading()
            fetch(this.backendServer() + '/UpdateProfileVue/', {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: "follow",
                referrerPolicy: "no-referrer",
                body: JSON.stringify({
                    'value': this.fieldValue,
                    'type': this.type.toLowerCase(),

                }),
            }).then((response) => {
                return response.json();
            }).then((data) => {

                if (typeof data == 'object') {
                    this.doUpdateUserInfo(data)
                    this.loading()
                    this.editingProfile()

                    this.setFlashedMessage('info updated');

                } else {
                    this.setFlashedMessage(data);
                    this.loading()

                }
            })

        }


    },

}
</script>

<style scoped>
.currentUser {
    white-space: nowrap;
    overflow: hidden;
    max-width: 230px;
    text-overflow: ellipsis;
}

.profilebg {
    position: relative;
}

input {
    z-index: 7;
    width: auto !important;
    height: auto !important;
    font-size: 13px;
}

.mll {
    z-index: 7;
    bottom: -12px;
    position: absolute;
    right: 74px;
    font-size: .68em
}

.exptf {
    z-index: 7;
    font-size: .75em;
    border: none;
    padding: 0 5px;
    border-radius: 10px;
}

.exptf:focus-visible {
    outline: none;

}
</style>