<template>
    <div class="swapped_containter">
        <div class="promoted_teams">
            <h6 class="league-info-header">Promoted Last season</h6>
            <div class="teams">
                <div v-for="team in promoted" :key="team.name" class="swapped_team">
                    <img :src="team.logo" alt="">
                    <span class="promoted">
                        {{ team.name }}
                    </span>
                </div>

            </div>

        </div>
        <div class="promoted_teams">
            <h6 class="league-info-header">Relegated Last season</h6>
            <div class="teams">
                <div v-for="team in relegated" :key="team.name" class="swapped_team">
                    <img :src="team.logo" alt="">
                    <span class="promoted">
                        {{ team.name }}
                    </span>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'SwappedTeams',
    inject: ['backendServer', 'currentHost', 'loading'],
    props: ['fullScreen', 'limit', 'currentYear'],
    data() {
        return {
            promoted: null,
            relegated: null
        }
    },
    methods: {
        swappedTeams() {
            this.loading()
            fetch(this.backendServer() + '/swapped-teams/' + this.currentYear, {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {

                    this.promoted = data.promoted;
                    this.relegated = data.relegated;
                    this.loading()
                });
        }
    },
    mounted() {
        this.swappedTeams()
    },

    watch: {
        currentYear: function () { // watch it
            this.swappedTeams()
        }
    }

}
</script>

<style>
div.swapped_containter {
    display: flex;
    justify-content: space-between;
}

div.promoted_teams {
    background-color: rgb(255, 255, 255, 0.89);
    font-size: 12px;
    color: black;
    border-radius: 10px;
    margin: 0 2px;
    flex: 0 0 49.5%;
    padding: 5px;
    margin-bottom: 2px !important;

}

.promoted_teams div.teams {
    display: flex;
    justify-content: center;
}

.swapped_team {
    display: grid;
    background-color: white;
    margin: 3px;
    padding: 3px;
    border-radius: 17px;
    flex: 0 0 23%;
    justify-items: center;
    min-width: 74px;
}

.swapped_team img {
    width: 40px;
    height: 40px;
}

@media screen and (max-width:920px) {
    div.swapped_containter {
        display: block;
        justify-content: space-between;
    }
}
</style>