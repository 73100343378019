import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AllPosts from "../views/AllPosts.vue";
import LoginVue from "../views/LoginVue.vue";
import NewsVue from "../views/NewsVue.vue";
import RegisterVue from "../views/RegisterVue";
import ProfileVue from "../views/ProfileVue";
import TicketsVue from "../views/TicketsVue";
import TicketVue from "../views/TicketVue";
import userStore from "../store/userStore";
import SinglePost from "../views/SinglePost"
import LeagueInfo from "../views/LeagueInfo.vue";
import AdminsVue from "../views/AdminsVue.vue";
import UserPosts from "../views/UserPosts.vue"
import YourPosts from "../views/YourPosts.vue"
import MilanFixtures from "@/views/MilanFixtures.vue";
import MilanResults from "@/views/MilanResults.vue";
import SquadVue from "@/views/SquadVue.vue";
import PredictionsVue from "@/views/PredictionsVue.vue";
import UclVue from "@/views/UclVue.vue";
import UelVue from "@/views/UelVue.vue";
import MatchDay from "@/views/MatchDay.vue";
import GoalScorersSerie from "@/views/GoalScorersSerie.vue"
import LeagueTable from "@/views/LeagueTable.vue"
import UclMatchday from "@/views/UclMatchday.vue"
import UelMatchday from "@/views/UelMatchday.vue";
import GoalScorersUcl from "@/views/GoalScorersUcl.vue"
import GoalScorersUel from "@/views/GoalScorersUel.vue";
import UclStages from "@/views/UclStages.vue";
import UelStages from "@/views/UelStages.vue";
import SingleMatchResult from "@/views/SingleMatchResult.vue";
import PredictionHistory from "@/views/PredictionHistory.vue";
import PredictionMainTable from "@/views/PredictionMainTable.vue";
import PredictionHistoryId from "@/views/PredictionHistoryId.vue";
import SingleMilanMatchResult from "@/views/SingleMilanMatchResult.vue";
import SingleUclMatchResult from "@/views/SingleUclMatchResult.vue";
import SingleUelMatchResult from "@/views/SingleUelMatchResult.vue";
import NotificationsVue from "@/views/NotificationsVue.vue";
import PostToComment from "@/views/PostToComment.vue";
import ActivateAccount from "../views/ActivateAccount.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import NewPassword from "@/views/NewPassword.vue";
const routes = [
  {
    path:"/adm",
    name:"admin",
    component:AdminsVue,
    meta:{is_admin:true,auth: true}
  },

  {
    path: "/home",
    name: "home",
    component: HomeView,
    alias: '/'
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path:"/news",
    name:"news",
    component: NewsVue
  },
  {
    path:"/comp/serie-a",
    name:"leagueinfo",
    component:LeagueInfo
  },
  {
    path:"/comp/serie-a/match-day",
    name:"matchday",
    component:MatchDay
  },
  {
    path:"/comp/serie-a/top-goalscorers",
    name:"serieags",
    component:GoalScorersSerie
  },
  {
    path:"/comp/serie-a/table",
    name:"leaguetable",
    component:LeagueTable
  },
  {
    path: "/serie-a/match/:id",
    name: "SingleMatchResult",
    component: SingleMatchResult
  },
  {
    path: "/team/schedule/results/:id",
    name: "SingleMilanMatchResult",
    component: SingleMilanMatchResult
  },  
  {
    path: "/comp/ucl/results/:id",
    name: "SingleUclMatchResult",
    component: SingleUclMatchResult
  },
  {
    path: "/comp/uel/results/:id",
    name: "SingleUelMatchResult",
    component: SingleUelMatchResult
  },
  {
    path:"/comp/ucl",
    name:"uclinfo",
    component:UclVue
  },
  {
    path:"/comp/uel",
    name:"uelinfo",
    component:UelVue
  },
  {
    path:"/comp/ucl/match-day",
    name:"uclmatchday",
    component:UclMatchday
  },
  {
    path:"/comp/uel/match-day",
    name:"uelmatchday",
    component:UelMatchday
  },
  {
    path:"/comp/ucl/stages",
    name:"uclstages",
    component:UclStages
  },
  {
    path:"/comp/uel/stages",
    name:"uelstages",
    component:UelStages
  },
  {
    path:"/comp/ucl/top-goalscorers",
    name:"uclgs",
    component:GoalScorersUcl
  },
  {
    path:"/comp/uel/top-goalscorers",
    name:"uelgs",
    component:GoalScorersUel
  },
  {
    path:"/team/schedule/fixtures",
    name:"fixtures",
    component:MilanFixtures,
    alias : "/team/schedule"
  },
  {
    path:"/team/schedule/results",
    name:"results",
    component:MilanResults
  },

  {
    path:"/team/squad",
    name:"squad",
    component:SquadVue
  },
  {
    path: "/threads",
    name: "allposts",
    component: AllPosts
  },
  {
    path: "/threads/:page",
    name: "postsPaginate",
    component: AllPosts
  },
  {
    path: "/threads/user/:id",
    name: "userposts",
    component: UserPosts
  },
  {
    path: "/threads/user/:id/:page",
    name: "userpostsPaginate",
    component: UserPosts
  },
  {
    path: "/profile/my-threads",
    name: "yourposts",
    component: YourPosts
  },
  {
    path: "/profile/my-threads/:page",
    name:"yourpostPaginate",
    component: YourPosts
  },
  {
    path: "/thread/:id",
    name: "singlePost",
    component: SinglePost
  },
  {
    path: "/thread/:id/:cm",
    name: "PostToComment",
    component: PostToComment
  },


  {
    path: "/login",
    name: "login",
    component: LoginVue,
    meta:{auth: false}
  },
  {
    path: "/login/reset_password",
    name: "resetpassword",
    component: ForgotPassword,
    meta:{auth: false}
  },
  {
    path: "/register",
    name: "register",
    component: RegisterVue ,
    meta:{auth: false}
  },
  {
    path: "/profile",
    name: "profile",
    component: ProfileVue,
    meta:{auth: true} // instead, specify which page requires LOGIN. Atta boooy!!xD bb
  },
  {
    path: "/profile/tickets",
    name: "tickets",
    component: TicketsVue,
    meta:{auth: true}
  },
  {
    path: "/profile/notifications",
    name: "notifications",
    component: NotificationsVue,
    meta:{auth: true}
  },
  {
    path: "/profile/notifications/:page",
    name: "notificationsPaginate",
    component: NotificationsVue,
    meta:{auth: true}
  },
  {
    path: "/profile/tickets/:page",
    name: "ticketsPaginate",
    component: TicketsVue,
    meta:{auth: true}
  },
  {
    path: "/profile/ticket/:id",
    name: "ticket",
    component: TicketVue,
    meta:{auth: true}
  },
  {
    path: "/predictions",
    name: "prediction",
    component: PredictionsVue,
  },
  {
    path: "/predictions/history",
    name: "predictionhistory",
    component: PredictionHistory,
  },
  {
    path: "/predictions/history/user/:id",
    name: "predictionhistoryid",
    component: PredictionHistoryId,
  },
    {
    path: "/predictions/table",
    name: "predictiontable",
    component: PredictionMainTable,
  },
  {
    path: '/account/activate',
    name: 'ActivateAccount',
    component: ActivateAccount
  },
  {
    path: '/password-reset/activate',
    name: 'newpassword',
    component: NewPassword
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/home', // Redirect to the home path
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach( async (to,from,next)=>{
  // 

  if (userStore.user_login_info == null)
  {
    await userStore.getUserInfo();
    
  }
  
  if(to.meta.auth == undefined){
    next()
  }
  else if(!to.name ){
    next({name:'home'})
  }
  else if(to.name == 'admin' && to.meta.is_admin != userStore.is_admin){
    next({name:'home'})
  }
  else if(to.name != 'login' && to.meta.auth != userStore.user_login_info){
    next({name:'login'})
  }else if(to.name == 'login' && to.meta.auth != userStore.user_login_info){
    next({name:'profile'})
  }else if(to.name == 'register' && to.meta.auth != userStore.user_login_info){
    next({name:'profile'})
  }
  else if(to.name == 'resetpassword' && to.meta.auth != userStore.user_login_info){
    next({name:'profile'})
  }
  else{
    next()
  }
})

export default router
 

