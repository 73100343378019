<template>
    <div id="overlay" class="show" @click="doOverlay">
        <div class="loader_container">

            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>

        </div>
    </div>
</template>

<script>
export default {
    name: 'LoadingVue',
    inject: ['doOverlay']
}
</script>

<style scoped>
.show {
    background-color: rgb(255, 255, 255, .5);
}



.loader_container {
    position: fixed;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    z-index: 100;
    display: flex
}

.loader_container span {
    animation: loader .7s linear infinite;
    height: 22px;
    border-radius: 3px;
    width: 11px;
    background-color: red;

}

.loader_container span:first-of-type,
.loader_container span:last-of-type {

    height: 22px;
    border-radius: 5px;
    width: 11px;
    animation: no 1.2s ease-in-out infinite;
    border-bottom: 2px solid white;


}

.loader_container :nth-child(n+2):nth-child(-n+8) {
    height: 45px;
    width: 9px;
    border-radius: 3px;
}

.loader_container :first-child {
    transform: rotate(10deg);
    animation: no 1.2s ease-in-out infinite;

}

.loader_container :nth-child(2) {

    width: 0 !important;
}

.loader_container :nth-child(8) {
    width: 0 !important;
}

/* .loader_container :first-child::after {
content
} */
.loader_container :last-child {

    transform: rotate(-10deg);

}

.loader_container :nth-child(even) {
    /* animation-delay: .6s; */
    background-color: black;

}

@keyframes loader {
    0% {
        margin: 2px;
    }

    50% {
        margin: 0;
    }

    100% {
        margin: 2px;
    }
}

@keyframes no {
    0% {
        background-color: black;
    }

    50% {
        background-color: black;
    }

    100% {
        background-color: black;
    }


}
</style>