<template>
    <div class="table-container">

        <li v-if="players && players.length" :class="fullScreen ? 'standings' : 'seriaa'">

            <div v-if="!fullScreen" class="league_player">
                <h6 class="league-info-header">Serie A Top Goalscorers</h6>

            </div>
            <table class="seriaa" :class="fullScreen && 'standings'">
                <thead>
                    <tr>
                        <th style="text-align: center;">Player</th>
                        <th>Team</th>
                        <th class="player-age">Age</th>
                        <th class="">Nationality</th>
                        <th class="match-played">MP</th>
                        <th class="match-played hidenow" v-if="fullScreen">TG%</th>

                        <th>Goals</th>
                    </tr>
                </thead>

                <tbody v-if="players">
                    <tr v-for="(player, index) in players.slice(0, limit)" :key="index" class="teams">
                        <td><img :class="fullScreen && 'fslogo player_fslogo'" class="player_logo" :src="player.img" alt="">
                            <br> {{ player.name }}
                        </td>
                        <td><img class="team_logo" :src="player.team_logo" alt=""> <br> {{ player.team }} </td>
                        <td>{{ player.age }}</td>
                        <td class=""> {{ player.nation }}</td>
                        <td>{{ player.mp }}</td>
                        <td v-if="fullScreen" class="hidenow">{{ player.percent }}</td>

                        <td>{{ player.goals }}</td>

                    </tr>
                </tbody>

            </table>



        </li>
        <li v-if="fullScreen" class="ei">
            <p>MP = matches played</p>
            <p class="hidenow">Tg% = Percentage of Team Goals</p>
        </li>
    </div>
</template>

<script>

export default {
    name: 'GoalScorers',
    inject: ['backendServer', 'currentHost', 'loading'],
    props: ['fullScreen', 'limit', 'currentYear', 'customStyle'],
    data() {
        return {
            players: null,
            year: null

        }
    },
    methods: {
        isFirstRow(index) {

            return index === 0;
        },
        goalscorers() {

            this.loading()
            fetch(this.backendServer() + '/goal-scorers/' + this.currentYear, {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {

                    this.players = data;

                    this.loading()
                });
        }
    },
    mounted() {
        this.goalscorers()
    },
    updated() {

    },
    watch: {
        currentYear: function () { // watch it

            this.goalscorers()
        }
    },

}
</script>

<style scoped>
ul.league_info li.ei {
    margin-top: 5px !important;
    color: black;
    font-size: .8em;
    text-align: left;
}

ul.league_info li.ei p {
    margin-left: 10px;
    margin-bottom: 0;
}

img.player_logo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}


tr:first-child img.player_logo {
    width: 50px;
    height: 50px;
}

.player-age {
    width: 30px;
}

img {
    border-radius: 50%;
}




tr td:first-child {
    width: 120px;
}

td {
    text-align: center;
}

tr {
    text-align: center;
    width: 25%;
}

div.league_player a {
    text-decoration: none;
}

@media screen and (max-width:440px) {
    .hidenow {
        display: none;
    }
}

@media screen and (min-width:500px) {
    tr img.player_fslogo {
        width: 55px;
        height: 55px;
    }

    table tr:first-child img.fslogo {
        width: 75px;
        height: 75px;
    }
}
</style>