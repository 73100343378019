<template>
  <header>
    <time id="date"></time>
    <img class="header-logo" :src="logo" alt="">
    <h1>Rossoneries</h1>
  </header>
  <nav class="main-nav">

    <profileBox />

    <div v-if="user_login_info === null">
      LOADING...
    </div>
    <ul v-else>
      <div v-if="user_login_info">
        <li><router-link :to="{ name: 'home' }">Home</router-link></li>

        <li><router-link :class="activeClass('news')" :to="{ name: 'news' }">News</router-link>
        </li>
        <li><router-link :class="activeClass('allposts')" :to="{ name: 'allposts' }">Threads</router-link>
        </li>
        <li><router-link :class="activeClass('fixtures')" :to="{ name: 'fixtures' }">Fixtures</router-link></li>
        <li><router-link :class="activeClass('results')" :to="{ name: 'results' }">Results</router-link></li>

        <li><router-link :class="activeClass('serie-a')" :to="{ name: 'leagueinfo' }">Serie A</router-link>
        </li>
        <li><router-link :class="activeClass('ucl')" :to="{ name: 'uclinfo' }">Ucl</router-link>
        </li>
        <li><router-link :class="activeClass('uel')" :to="{ name: 'uelinfo' }">Uel</router-link>
        </li>
        <!-- <li><router-link :class="activeClass('prediction')" :to="{ name: 'prediction' }">Predict Milan</router-link></li> -->
        <li><router-link :class="activeClass('squad')" :to="{ name: 'squad' }">Squad</router-link></li>

        <li><router-link :class="activeClass('profile_ticket')" :to="{ name: 'profile' }">Profile</router-link></li>
        <li v-if="admin"><router-link :class="activeClass('admin')" :to="{ name: 'admin' }">admin</router-link></li>
      </div>
      <div v-else>
        <li><router-link :to="{ name: 'home' }">Home</router-link></li>

        <li><router-link :class="activeClass('news')" :to="{ name: 'news' }">News</router-link>
        </li>
        <li><router-link :class="activeClass('allposts')" :to="{ name: 'allposts' }">Threads</router-link>
        </li>
        <li><router-link :class="activeClass('fixtures')" :to="{ name: 'fixtures' }">Fixtures</router-link></li>
        <li><router-link :class="activeClass('results')" :to="{ name: 'results' }">Results</router-link></li>
        <li><router-link :class="activeClass('serie-a')" :to="{ name: 'leagueinfo' }">Serie A</router-link>
        </li>
        <li><router-link :class="activeClass('ucl')" :to="{ name: 'uclinfo' }">Ucl</router-link>
        </li>
        <li><router-link :class="activeClass('uel')" :to="{ name: 'uelinfo' }">Uel</router-link>
        </li>
        <!-- <li><router-link :class="activeClass('prediction')" :to="{ name: 'prediction' }">Predict Milan</router-link></li> -->
        <li><router-link :class="activeClass('squad')" :to="{ name: 'squad' }">Squad</router-link></li>

        <li><router-link :class="activeClass('register')" :to="{ name: 'register' }">Register</router-link></li>
        <li><router-link :class="activeClass('login')" :to="{ name: 'login' }">Login</router-link>
        </li>
      </div>
    </ul>

  </nav>
  <!-- <div id="overlay" class="show" v-if="overlay"></div> -->
  <slot></slot>


  <div v-if="!load">
    <section class="join">

      <h2 @click="openNewTicket">contact us now! <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
          fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
          <path
            d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
        </svg></h2>


      <footer>
        <p>all rights reserved by Rossoneries 2024</p>
      </footer>
    </section>
  </div>
  <NewTicket v-if="openTicket" @close="openNewTicket" />
</template>


<script>
import NewTicket from './NewTicket.vue';
import logo from '../../public/img/h.jpg'
import ProfileBox from './base/ProfileBox.vue';
export default {
  name: "BaseScaffold",
  inject: ['backendServer', 'doUpdateUserInfo', 'loading', 'ticket_alert', 'currentHost', 'current_user', 'profile_image', 'load', 'user_alert', 'admin'],
  props: ['user_login_info', 'overlay'],
  components: { NewTicket, ProfileBox },
  data() {
    return {
      is_logged: null,
      openTicket: false,
      logo: logo,
      hover: false,
      noHover: false
    }
  },
  computed: {
    is_user_info_pending() {
      return this.user_login_info === null
    }
  },
  emits: ["userLoggedOut"],

  methods: {
    activateHover() {
      this.hover = !this.hover
    },
    noH() {
      this.noHover = !this.noHover
      setTimeout(() => {
        this.noHover = !this.noHover;
      }, 500)
    },
    deactivateHover() {
      setTimeout(() => {
        this.hover = !this.hover;
      }, 500)
    },
    activeClass(linkRouteName) {
      if (this.$route.name == linkRouteName)
        return 'active_bar';

      switch (linkRouteName) {
        case 'profile_ticket':
          if (this.$route.name == 'profile' || this.$route.name == 'notifications' || this.$route.name.includes('ticket'))
            return 'active_bar';
          break;
        case 'team':
          if (this.$route.path.includes('/team'))
            return 'active_bar';
          break
        case 'prediction':
          if (this.$route.path.includes('/prediction'))
            return 'active_bar';
          break
        case 'ucl':
          if (this.$route.path.includes('/ucl'))
            return 'active_bar';
          break
        case 'serie-a':
          if (this.$route.path.includes('/serie-a'))
            return 'active_bar';
          break
        case 'uel':
          if (this.$route.path.includes('/uel'))
            return 'active_bar';
          break
        default:
          return '';
      }

      return '';
    },
    openNewTicket() {
      this.openTicket = !this.openTicket
    },
    logout() {
      this.loading()


      fetch(this.backendServer() + '/logout', {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        cache: "no-cache", // *default, no-cache, reload, force-cache, o
        credentials: 'include',
      })
        .then((response) => response.json())
        .then((data) => {
          //if (data) {


          this.doUpdateUserInfo(data);

          //this.$emit('userLoggedOut', data)
          this.$router.push({ name: 'login' })
          this.loading()

          //}

        });

    }
  }

};
</script>

<style>
.hunw {
  top: -17px;
  right: 0;
  position: absolute;
}

nav ul li a {
  transition: all .3s linear;
}

nav ul li a:hover {
  color: white;
  text-shadow: 0 0 5px #ffffff, 0 0 25px #ffffff, 0 0 50px #ffffff;

}

img.header-logo {
  width: 50px;
}

/* svg.bi-caret-left {
  position: fixed;
  right: 22px;
  top: 48px;
  fill: white;
} */

span em {
  cursor: default;
}

img.profile_box_img {
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
  object-fit: cover;
}

span.profile_box {
  display: flex;
  align-items: center;
  color: white;
  font-size: .75em;
}

.logout {
  cursor: pointer;
  font-weight: bold;
}

section.join h2 {
  padding: 27px;
  cursor: pointer;
  width: fit-content;
  margin: 0 auto;
  transition: all .4s linear;
  color: red;
}

section.join h2:hover {
  color: black;
  /* box-shadow: 0 0 4px grey, 0 0 10px grey; */
}

.hn li {
  right: -2px !important;
}

.hn {
  display: block;
  transform: scale(1);
  visibility: visible;
  height: auto;
  width: 137px;
  right: -3px;
  top: 0;
  position: fixed;
}
</style>