<template>
    <div class="container">
        <router-link class="news-menu" :to="{ name: route }" :class="activeClass(name)">
            <span class="direction" :id=name>{{ name }}</span>
        </router-link>


        <svg v-if="activeClass(name) && route != 'squad' && !$route.path.includes('/predictions')"
            xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill"
            viewBox="0 0 16 16">
            <path
                d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
        </svg>
        <slot>

        </slot>
    </div>
</template>

<script>
export default {
    name: 'NewsMenu',
    props: ['name', 'route'],
    methods: {
        activeClass(linkRouteName) {
            if (this.$route.name == linkRouteName)
                return 'active_bar';

            switch (linkRouteName) {
                case 'serie A':
                    if (this.$route.path.includes('/serie-a'))
                        return 'active_bar';
                    break;
                case 'ucl':
                    if (this.$route.path.includes('/ucl'))
                        return 'active_bar';
                    break;
                case 'schedule':
                    if (this.$route.path.includes('/schedule'))
                        return 'active_bar';
                    break
                case 'squad':
                    if (this.$route.path.includes('/squad'))
                        return 'active_bar';
                    break
                case 'Info':
                    if (this.$route.path == '/predictions')
                        return 'active_bar';
                    break
                case 'Table':
                    if (this.$route.path.includes('/predictions/table'))
                        return 'active_bar';
                    break
                case 'My History':
                    if (this.$route.path == '/predictions/history')
                        return 'active_bar';
                    break
                default:
                    return '';
            }

            return '';
        },
    }
}
</script>

<style scoped>
a.active_bar {
    color: #ff4949 !important;
    font-weight: bold;
    /* pointer-events: none; */
    cursor: none !important;
}

a {
    margin-left: 0 !important
}

.bi {
    color: aliceblue;
    top: 29px;
    position: absolute;
    opacity: 1;
    transition: .3s opacity linear;
    left: 50%;
    transform: translate(-50%, -50%);

    z-index: 5;
}


/* .extra_items:hover {
    right: -77px;
} */
.news-menu {
    color: inherit !important;
    text-decoration: none !important;
}



div.container {
    color: white;
    transition: .2s all linear;
    border-radius: 8px;
    white-space: nowrap;
    text-align: center;
    padding: 2px;
    position: relative;
}

span.direction {
    padding: 5px;
    border-radius: 10px;
}

span.direction:hover {
    background-color: #2f2842;

}
</style>