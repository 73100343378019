<template>
    <div class="diavolo_container">

        <!-- <span class="diablo_absoloute"></span> -->
        <div class="diablo">
        </div>
    </div>
</template>

<script>


export default {
    name: 'ArticleLogo',
}
</script>

<style scoped>
main article span.diablo_absoloute {
    width: 150px;
    height: 70px;
    background-image: url('~@/assets/img/diavolo.png');
    background-size: cover;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    position: absolute;
    transition: opacity 1000ms linear;
    animation: diablo 4s linear infinite;
    /* background-position: 0 -70px; */
}

@keyframes diablo {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

div.diablo {
    width: 100%;
    background-image: url('~@/assets/img/bg32.jpg');
    height: 120px;
    background-position: center;
}
</style>
