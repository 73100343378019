<template>
    <li class="fng jr">
        <div class="match-title">
            <span v-if="game.status == 'postponed'" class="match-d">Postponed</span>
            <span v-else class="match-d">{{ game.date }}</span>

            <span class="c-name">{{ game.title }}</span>
            <span class="spacer"></span>
        </div>
        <div class="fixtures">
            <div class="ht">
                {{ game.home }} <img class="fixl" :src="game.homeLogo" alt="">

            </div>
            <span v-if="game.status == 'postponed'" class="match-t"><s>{{ game.time != '00:00' ? game.time : 'TBD' }}</s>
            </span>
            <span v-else class="match-t">{{ game.time != '00:00' ? game.time : 'TBD' }}</span>
            <div class="at"><img class="fixl" :src="game.awayLogo" alt=""> {{ game.away }}</div>
        </div>
    </li>


    <!-- <div class="effect"></div>
    <div class="effect2"></div> -->
</template>

<script>
export default {
    name: 'FixturesVue',
    inject: ['currentHost'],
    props: ['game'],


}
</script>

<style>
img.fixl {
    margin: 0 1px !important
}

div.ht {
    flex-basis: 45%;
    display: flex;
    text-align: right;
    align-items: center;
    justify-content: flex-end;
    font-family: monospace;
    position: relative;
}


div.at {
    flex-basis: 45%;
    display: flex;
    text-align: left;
    align-items: center;
    font-family: monospace;
    position: relative;
}

span.match-t {
    width: 60px;
}

li.fng.jr {
    padding: 0px 0 30px 0;
}

li.fng.jr.open {
    padding-bottom: 4px;
}

div.fixtures {
    font-size: 1.2em;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    position: relative;
}

img.fixl {
    width: 37px;
    height: 37px;
}

.effect {
    height: 0;
    border-radius: 0;
    width: 0;
    /* background-color: rgb(255, 255, 255, 0.89); */
    position: absolute;
    rotate: 53deg;
    box-shadow: 0 0 5px #ffd7d7, 0 0 25px #ffd7d7, 0 0 50px #878484, 0 0 100px #878484;
    transition: .7s all linear;
}

div.effecta {
    height: 100%;
    width: 100%;
    rotate: 0deg;
    box-shadow: 0 0 5px #ffd7d7, 0 0 25px #ffd7d7, 0 0 50px #ffe0e0, 0 0 100px #ffe0e0;

}

span.match-t {
    font-size: 1.15em;
    color: black;
}


div.effecta2 {
    height: 100%;
    width: 100%;
    rotate: 360deg;
    box-shadow: 0 0 5px #ffd7d7, 0 0 25px #ffd7d7, 0 0 50px #ffe0e0, 0 0 100px #ffe0e0;
}


.effect2 {
    height: 0;
    border-radius: 0;
    width: 0;
    /* background-color: rgb(255, 255, 255, 0.89); */
    position: absolute;
    rotate: 307deg;
    box-shadow: 0 0 5px #ffd7d7, 0 0 25px #ffd7d7, 0 0 50px #878484, 0 0 100px #878484;
    transition: .7s all linear;
}
</style>