<template>
    <div>
        <PostsTitle :post="post" />
        <div class="read_more_post">
            <p>{{ post.content }}
            </p>
            <PostMedia v-if="post.media" :media="post.media" :height="true" />

        </div>
        <PostFooter :post="post" />
    </div>
</template>

<script>
import PostFooter from './allPosts/PostFooter.vue';
import PostsTitle from './allPosts/PostsTitle.vue';
import PostMedia from './singlePost/PostMedia.vue';
export default {

    name: 'PostBlock',
    components: { PostsTitle, PostFooter, PostMedia },
    emits: ['reloadPost'],
    props: ["post"],

}
</script>

<style scoped>
div.read_more_post {
    text-align: center;
}

div.read_more_post p {
    margin-bottom: 0
}
</style>