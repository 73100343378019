<template >
    <main class="news posted_data all_posts create_post">
        <article class="news news2">
            <ArticleLogo article="Thread" />
        </article>
        <div class="onpage">
            <ul class="posted_data post_complete">
                <li :key="post" class="new_posted all_posts spl">
                    <PostTitle :post="post" @reloadPost="reloadPost" />
                    <div class="comments">
                        <PostComments @reloadComments="reloadComments" :comments="comments.length" />
                        <CommentList :comments="comments" @reloadComments="reloadComments" />
                    </div>
                </li>
            </ul>
        </div>


    </main>
</template>

<script>
import ArticleLogo from '@/components/ArticleLogo.vue'
import PostTitle from '@/components/singlePost/PostTitle.vue';
import PostComments from '@/components/singlePost/PostComments.vue';
import CommentList from '@/components/singlePost/CommentList.vue';

export default {
    name: 'SinglePost',
    inject: ['backendServer', 'currentHost', 'loading'],
    data() {
        return {
            'post': null,
            'comments': [],
            'id': window.location.href.split('/').pop(),
            'user_liked': null
        }
    },
    components: { ArticleLogo, PostTitle, PostComments, CommentList },
    methods: {
        reloadComments(target) {
            this.comments = target
        },
        reloadPost(target) {
            this.post = target
        }
    },
    mounted() {
        this.loading()
        fetch(this.backendServer() + '/posts/' + this.id, {
            method: "GET",
            credentials: 'include',
        })

            .then((response) => response.json())
            .then((data) => {
                if (data['error']) {
                    this.setFlashedMessage(data['error'])
                    this.loading()
                } else {
                    this.post = data['post']
                    this.comments = data['comments']
                    this.user_liked = data['liked']

                    document.title = 'Thread / ' + this.post.title

                    this.loading()
                }


            });

    },


}
</script>

<style>
li.spl {
    overflow: visible !important;
}
</style>