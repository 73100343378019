<template>
    <div class="single_comment" :id="`cm${comment.id}`">
        <div class="prpi">
            <div class="cs">
                <PoppedProfile :clicked="pclick" :pic="comment.pic" :id="comment.pic.id" :sm="true" />
                <span @click="changeStatus" style="font-size: 14px; cursor: pointer;">{{ comment.username }}</span>
            </div>

        </div>
        <p> {{ comment.comment }}</p>
        <div class="comment_time">({{ comment.date }}) </div>
        <div class="comments_like" data-comment_id="{{ comment.id }}">
            <svg @click="likeComment" :id="comment.id" v-if="!comment.liked"
                :class="[user_login_info ? 'bi bi-hand-thumbs-up like' : 'bi bi-hand-thumbs-up low_opacity']"
                xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path
                    d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z" />
            </svg>

            <svg @click="likeComment" :id="comment.id" v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                fill="currentColor" class="bi bi-hand-thumbs-up-fill unlike" viewBox="0 0 16 16">
                <path
                    d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a9.84 9.84 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733.058.119.103.242.138.363.077.27.113.567.113.856 0 .289-.036.586-.113.856-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.163 3.163 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.82 4.82 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z" />
            </svg>

            <span class="comment_likes"> {{ comment.likes }}</span>

            <div v-if="user_login_info" class="add_reply_container">
                <textarea @click="focusOn" v-model="comment_reply" ref="textAreaReply" :class="focus && 'add_reply_focus'"
                    placeholder="add a reply..." class="add_reply " id="add_reply" cols="30" rows="10"></textarea>
                <div :class="focus && 'reply_items_focus'" class="reply_items">
                    <EmojisVue @emoji="addEmoji" />

                    <button @click="newCommentReply" :id="comment.id" class="button_submit">reply</button>
                    <button @click="CancelReply" :id="comment.id" class="button_submit">cancel</button>

                </div>

            </div>

        </div>
        <div v-if="comment.count > 0" class="comment_replies">
            <div class="replies" @click="showReplies">
                {{ comment.count }}<svg :class="!show && 'hide_reply_svg'" xmlns="http://www.w3.org/2000/svg" width="16"
                    height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                    <path
                        d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg> <span>{{ comment.count == 1 ? 'reply' : 'replies' }}</span>
            </div>

            <template v-if="!show">
                <div v-for="sub in comment.subs" :key="sub.id" class="sub_comment single_comment">
                    <div class="prpi">
                        <div class="cs">
                            <PoppedProfile :clicked="pclick" :pic="sub.pic" :id="sub.pic.id" :sm="true"
                                :user="sub.username" />

                        </div>

                    </div>

                    <p> {{ sub.comment }}</p>
                    <div class="comment_time">({{ sub.date }}) </div>
                </div>
            </template>

        </div>
    </div>
</template>

<script>
import EmojisVue from '../EmojisVue.vue'
import PoppedProfile from '../profile/PoppedProfile.vue'
export default {

    name: 'SingleComment',
    inject: ['backendServer', 'user_login_info', 'currentHost', 'loading', 'emojis'],
    props: ['comment'],
    components: { EmojisVue, PoppedProfile },
    data() {
        return {
            'pclick': 1,
            'hidden_button': true,
            'hidden_field': false,
            'id': window.location.href.split('/').pop(),
            'comment_reply': null,
            'reply_to_emit': null,
            'show': true,
            'focus': false
        }
    },
    methods: {

        addEmoji(emoji) {

            let reply = this.$refs.textAreaReply.value
            let caret = this.$refs.textAreaReply.selectionStart
            reply = reply.substring(0, caret) + emoji + reply.substring(caret)
            //this.$refs.textAreaReply.value = reply
            this.comment_reply = reply
            this.$refs.textAreaReply.focus()
        },
        showReplies() {
            this.show = !this.show
        },
        changeStatus() {
            this.pclick += 1
        },
        likeComment(event) {
            if (!this.user_login_info) {
                return
            }
            this.loading()
            let like_id = event.target.id || event.target.closest('svg').id
            fetch(this.backendServer() + '/posts/' + this.id + '/comment-reply', {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: "follow",
                referrerPolicy: "no-referrer",

                body: JSON.stringify({
                    'like_comment': like_id
                }),
            }).then((response) => {
                return response.json();
            }).then((data) => {
                let like_change = data['comments']
                this.$parent.$emit('reloadComments', like_change)
                this.loading()

            })
        },
        newCommentReply(event) {

            if (!this.user_login_info) {
                return
            }
            if (this.comment_reply == null)
                return
            // setTimeout(()=>)    
            // this.textAreaReply.focus()
            this.loading()

            fetch(this.backendServer() + '/posts/' + this.id + '/comment-reply', {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: "follow",
                referrerPolicy: "no-referrer",

                body: JSON.stringify({
                    'comment_reply': this.comment_reply,
                    'id': event.target.id

                }),
            }).then((response) => {
                return response.json();
            }).then((data) => {
                this.comment_reply = null

                let comment_change = data['comments']
                this.$parent.$emit('reloadComments', comment_change)
                this.loading()

            })
        },
        focusOn() {
            this.focus = true
        },
        CancelReply() {
            this.focus = false
            this.comment_reply = null
        }
    },

}
</script>

<style>
div.prpi {
    padding: 0 !important;
    align-items: center !important;
    display: flex;
    flex-wrap: wrap;
}

div.sub_comment {
    margin: 7px !important;
}

.cs {
    display: flex;
    align-items: center;
}

div.sub_comment div.comment_time {
    margin-right: -21px !important;
}

div.single_comment p {
    overflow-wrap: break-word
}

.replies {
    cursor: pointer
}

.reply_items {
    margin: 2px !important;
    display: none;
}

.low_opacity {
    opacity: .3
}


.hide_reply_svg {
    transform: rotate(180deg);
}

.bi-caret-down-fill {
    transition: all .2s linear;

}

.bi-emoji-smile {
    transition: all .2s linear;

}

.faded {
    color: #b2b2b2;
    cursor: default;
}

textarea.add_reply {
    width: 100px !important;
    opacity: .3;
    transition: all .2s linear;

}

textarea.add_reply_focus {
    opacity: 1;
    width: 97% !important;

}

div.reply_items_focus {
    display: flex;
    align-items: baseline;
}

div.replies {
    max-width: max-content;
}
</style>