<template >
    <main class="news posted_data all_posts create_post">
        <article class="news news2">
            <ArticleLogo article="Thread" />
        </article>
        <div class="onpage">
            <ul class="posted_data post_complete">
                <li :key="post" class="new_posted all_posts">
                    <PostTitle :post="post" @reloadPost="reloadPost" />
                    <div class="comments">
                        <PostComments @reloadComments="reloadComments" :comments="comments.length" />
                        <CommentList :comments="comments" @reloadComments="reloadComments" />
                    </div>
                </li>
            </ul>
        </div>


    </main>
</template>

<script>
import ArticleLogo from '@/components/ArticleLogo.vue'
import PostTitle from '@/components/singlePost/PostTitle.vue';
import PostComments from '@/components/singlePost/PostComments.vue';
import CommentList from '@/components/singlePost/CommentList.vue';

export default {
    name: 'SinglePost',
    inject: ['backendServer', 'currentHost', 'loading'],
    data() {
        return {
            'post': null,
            'comments': [],
            'seg': window.location.href.split('/'),
            'user_liked': null
        }
    },
    components: { ArticleLogo, PostTitle, PostComments, CommentList },
    methods: {
        reloadComments(target) {
            this.comments = target
        },
        reloadPost(target) {
            this.post = target
        }
    },
    mounted() {
        document.title = 'Thread / Rossoneries';

        let id = this.seg[this.seg.length - 2]


        this.loading()
        fetch(this.backendServer() + '/posts/' + id, {
            method: "GET",
            credentials: 'include',
        })

            .then((response) => response.json())
            .then((data) => {
                if (data['error']) {
                    this.setFlashedMessage(data['error'])
                    this.loading()
                } else {
                    this.post = data['post']
                    this.comments = data['comments']
                    this.user_liked = data['liked']


                    this.loading()


                }


            });

    },
    updated() {

        let comment = this.seg.pop().toString()

            ;
        if (comment) {
            let element = document.getElementById(`cm${comment}`);

            if (element) {
                element.classList.add('zsc')
                setTimeout(() => element.classList.remove('zsc'), 10000)


                let div = element.querySelector('.replies')
                if (div) {
                    div.click()
                    let otherElement = element.querySelector('.comment_replies');
                    setTimeout(() => otherElement.scrollIntoView({ behavior: 'smooth', block: 'end' }), 400);

                } else {

                    //element.scrollIntoView({ behavior: 'smooth', block: 'start' });

                    //setTimeout(() => window.scrollTo(0, element.offsetTop), 1000);
                    setTimeout(() => element.scrollIntoView({ behavior: 'smooth', block: 'center' }), 400);

                }


            }
        }
    }


}
</script>

<style>
.zsc {
    transition: all 1s linear;
    background-color: #e7e7e7
}

.zsca {
    background-color: rgb(212, 212, 212);
}
</style>