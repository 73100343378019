<template>
  <router-view v-if="checkRoute()" />
  <BaseScaffold v-else :user_login_info="user.user_login_info">
    <router-view :user_login_info="user.user_login_info"
      :key="$route.name === 'singlePost' ? $route.params.id : $route.fullPath" />
    <LoadingVue v-if="loading" />
  </BaseScaffold>
  <ImageZoomer v-if="zoom" :source="imageSrc" />
  <FlashedMessages :message="flashedMessage" v-if="flashedMessage" @click.self="removeFlashedMessage" />
</template>

<script>

import { computed } from 'vue';
import FlashedMessages from './components/FlashedMessages.vue'
import BaseScaffold from "./components/BaseScaffold.vue"
import userStore from "./store/userStore"
import LoadingVue from './components/LoadingVue.vue';
import ImageZoomer from './components/ImageZoomer.vue';
// import emojis from "./store/emojis"


export default {
  components: { FlashedMessages, BaseScaffold, LoadingVue, ImageZoomer },
  data() {
    return {
      zoom: false,
      imageSrc: null,
      user: userStore,
      flashedMessage: '',
      loading: false,
      emojis: ['😂', '🤣', '😅', '😁', '😇', '🙂', '😉', '😍', '😝', '🥳', '😔', '☹️', '😢', '😭', '😤', '🤯', '😐', '😑', '😮', '🤐', '👍', '👎', '🤏', '✌', '🙏', '💪', '❤️', '🖤']
    }


  },
  computed: {
    currentHost() {
      let hostFragments = location.host.split(':');

      return hostFragments[0];

    }
  },
  methods: {
    checkRoute() {
      if (!this.$route.name
        || this.$route.name === 'home'
        || this.$route.name === 'SingleMatchResult'
        || this.$route.name === 'SingleMilanMatchResult'
        || this.$route.name === 'SingleUclMatchResult'
        || this.$route.name === 'SingleUelMatchResult') {
        return true
      }
    },
    profile_image_change(value) {
      this.profile_image = value
    },
    isProduction() {
      // console.log('production is ', process.env, process.env.NODE_ENV);
      return process.env.NODE_ENV === 'production';

    },
    backendServer() {
      return (this.isProduction() ? 'https://' : 'http://') + this.currentHost + (this.isProduction() ? '/api' : ':5000');
    },
    // backendImages(link) {
    //   if (!this.isProduction()) {
    //     console.log('here here', link)
    //     return link.replace('localhost:5000', '/api')
    //     // return response.text().replaceAll('localhost:5000', '/api')
    //   }
    //   return link
    // },
    doZoom(src) {
      this.imageSrc = src;
      this.zoom = true;

    },
    deactivateZoom() {
      this.zoom = false;
      this.imageSrc = null;


    },
    doOverlay() {
      this.overlay = !this.overlay;

    },
    updateUserInfo(data) {
      userStore.update(data);
    },

    logout() {
      this.loadingProgress()
      userStore.logout();
      this.loadingProgress()
    },
    removeFlashedMessage() {
      this.flashedMessage = '',
        this.overlay = !this.overlay;

    },
    setFlashedMessage(value) {
      this.flashedMessage = value;
    },
    loadingProgress() {


      this.loading = !this.loading

    }
  },
  mounted() {

    // 
    userStore.startAutoRefresh()
    // 
  },
  updated() {

  },

  provide() {
    return {
      logout: this.logout,
      emojis: computed(() => this.emojis),
      load: computed(() => this.loading),
      loading: this.loadingProgress,
      user_login_info: computed(() => this.user.user_login_info),
      ticket_alert: computed(() => this.user.ticket_alert),
      currentHost: this.currentHost,
      doOverlay: this.doOverlay,
      flashedMessage: computed(() => this.flashedMessage),
      setFlashedMessage: this.setFlashedMessage,
      removeFlashedMessages: this.removeFlashedMessage,
      doUpdateUserInfo: this.updateUserInfo,
      current_user: computed(() => this.user.username),
      admin: computed(() => this.user.is_admin),
      user_age: computed(() => this.user.age),
      show_age: computed(() => this.user.show_age),
      user_bio: computed(() => this.user.bio),
      user_web: computed(() => this.user.web),
      user_nation: computed(() => this.user.nation),
      show_nation: computed(() => this.user.show_nation),
      show_email: computed(() => this.user.show_email),
      user_email: computed(() => this.user.email),

      zoomer: this.doZoom,
      deactivateZoom: this.deactivateZoom,
      backendServer: this.backendServer,
      // backendImages: this.backendImages,
      user_alert: computed(() => this.user.alert),
      current_email: computed(() => this.user.email),
      profile_image_change: this.profile_image_change,
      profile_image: computed(() => this.user.profile_image)
      // profile_image: computed({
      //   get: () => this.profile_image,
      //   set(value) {
      //     this.profile_image = value
      //   }
      // })

    }

  },

};

</script>


<style>
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('assets/fonts/quicksand-v30-latin-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('assets/fonts/quicksand-v30-latin-regular.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* quicksand-600 - latin */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  src: local(''),
    url('assets/fonts/quicksand-v30-latin-600.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('assets/fonts/quicksand-v30-latin-600.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
} */

/* nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}
 */
a.router-link-exact-active {
  margin-left: 5px;
}
</style>
