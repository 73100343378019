<template>
    <div class="head">
        <time id="date"></time>
        <img class="header-logo" :src="logo" alt="">
        <h1>Rossoneries</h1>
    </div>
    <li class="fng jr jr2 single-match-result">
        <div class="match-title">

            <span class="match-d">{{ game.date }}</span>
            <span class="c-name">{{ game.title }}</span>
            <span class="spacer"></span>
        </div>
        <div class="smr fixtures">

            <div class="ht htsmr">{{ game.home }}<img class="fixl" :src="game.homeLogo" alt="">
            </div>
            <div class="match-res">
                <span class="match-t">{{ game.ft }}</span>
            </div>
            <div class="at atsmr"><img class="fixl" :src="game.awayLogo" alt=""> {{
                            game.away
                            }}

            </div>
        </div>
        <div class="m-events">
            <div v-for="(p, index) in  game.events " :key="index" :class="classDecider(p.align)">
                <span v-if="classDecider(p.align) == 'right'">
                    <p class="gmode">{{ typeMode(p.type) }} </p>
                    <p class="gname">{{ p.player != '-1' ? transformName(p.player) : `(${p.result})` }}</p>
                    <p v-if="resCondition(p)" class="gres">
                        {{ p.result }}
                    </p>
                    <img v-if="p.player != '-1'" class="gball" :src="imgDecider(p)" alt="">
                    {{ p.player != '-1' ? `${p.min}'` : '' }}
                </span>
                <span v-else-if="classDecider(p.align) == 'left'">
                    {{ p.player != '-1' ? `${p.min}'` : '' }}
                    <img v-if="p.player != '-1'" class="gball" :src="imgDecider(p)" alt="">
                    <p v-if="resCondition(p)" class="gres">
                        {{ p.result }}
                    </p>
                    <p class="gname">{{ p.player != '-1' ? transformName(p.player) : `(${p.result})` }}</p>
                    <p class="gmode">{{ typeMode(p.type) }} </p>


                </span>
                <span v-else>
                    <p class="gmode">{{ typeMode(p.type) }} </p>
                    <p class="gname">{{ p.player != '-1' ? p.player : `${p.min}(${p.result})` }}</p>
                    <p v-if="resCondition(p)" class="gres">
                        {{ p.result }}
                    </p>
                    <img v-if="p.player != '-1'" class="gball" :src="imgDecider(p)" alt="">
                    {{ p.player != '-1' ? `${p.min}'` : '' }}


                </span>

            </div>
        </div>


        <!-- <div class="effect" :ref="'effect' + index"></div>
        <div class="effect2" :ref="'effect2' + index"></div> -->
    </li>
</template>

<script>
import logo from '../../../public/img/h.jpg'

import goalb from '@/assets/img/icons8.com.png'
import mg from '@/assets/img/icons8.com2.png'
import red from '@/assets/img/icons8-red.png'
import yellow from '@/assets/img/icons8-soccer-yellow-card-48.png'

export default {
    name: 'ResultsVue',
    props: ['game'],
    data() {
        return {
            logo: logo,
            mg: mg,
            goalb: goalb,
            red: red,
            yellow: yellow
        }
    },
    methods: {



        imgDecider(p) {
            
            if (p.type == 'yellow')
                return this.yellow
            if (p.type == 'red')
                return this.red
            if (p.type == 'penm')
                return this.mg
            else
                return this.goalb
        },
        secondResDecider(ht, et, pen) {
            if (pen != '-1')
                return `p(${pen})`
            else if (et != '-1')
                return `AET(${et})`
            else
                return `HT(${ht})`
        },
        classDecider(align) {
            if (align == 'left')
                return 'left'
            if (align == 'right')
                return 'right'
            else
                return 'center'
        },

        typeMode(type) {
            if (type == 'pen')
                return '(pen)'
            if (type == 'penm')
                return '(pen missed)'
            if (type == 'own')
                return '(og)'
            else
                return ''
        },
        resCondition(p) {
            if (p.type == 'red' || p.type == 'penm' || p.player == '-1')
                return false
            else
                return true
        },
        transformName(name) {
            
            const parts = name.trim().split(' ');
            
            if (parts.length === 1) {
                return name; // If there is only one part, return the name as is
            } else {
                
                return parts[0][0] + '.' + parts[parts.length - 1];

            }

        }
    },


}
</script>

<style>
.single-match-result {
    padding: 5px !important;
    border-radius: 0 !important;
}

div.match-title {
    display: flex;
    justify-content: space-between;
    height: 30px;
}

div.center span p.gname {
    width: 8%;
    min-width: 80px;
    color: black;
    border-radius: 12px;
    background-color: #ffabbb;
    font-family: monospace;
}

.gball {
    width: 16px;
    vertical-align: sub;
    transition: .2s width linear;

}

/* .gballshow {
    width: 16px;
} */



.m-events div span p.gres {
    margin: 0 5px !important;
    padding: 0 5px;
    border-radius: 8px;
    background: white;
}

.m-events div span p {
    margin: 0 !important;
    display: inline-block;
}

div.m-events div span span.lres {
    position: absolute;
    left: -42px
}

div.m-events div span span.rres {
    position: absolute;
    right: -42px
}



div.m-events {
    margin-top: -15px;
    height: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: 0;
    width: 100%;
    transition: .2s all linear;
    display: none;
}

div.m-events-show {
    font-size: .8em;
    transition: .2s all linear;
    display: block;
}



div.m-events div span {
    position: relative;
}

.right {
    text-align: left;
    margin-left: auto;
    padding: 1px;
    box-sizing: border-box;
    width: 40%;
    white-space: nowrap;
}

.center {
    box-sizing: border-box;
    padding: 1px;
    position: relative;
}

.left {
    text-align: right;
    margin-right: auto;
    padding: 1px;
    box-sizing: border-box;
    width: 40%;
    white-space: nowrap;

}

div.scorers {
    position: absolute;
    transform: translateY(100%);
    font-size: .58em;
    display: flex;
    flex-direction: column;
    bottom: 0px;
    right: 32px;
}

div.ascorers {
    position: absolute;
    transform: translateY(100%);
    font-size: .58em;
    display: flex;
    flex-direction: column;
    bottom: 0px;
    left: 32px;
}

@media screen and (max-width:500px) {
    li.fng {
        font-size: .8em;
    }
}

div.match-t {
    padding: 0
}

span.winner {
    font-weight: bold;
}

div.match-res {
    display: grid;
    position: relative;
    width: 60px
}

.match-st {
    font-size: .7em;
    position: absolute;
    bottom: 0;
    transform: translate(-50%, 10px);
    left: 50%;
    width: 100%;
}

div.smr {
    font-size: 1.35em;
}

div.head {
    background: radial-gradient(circle, rgb(0 0 0) 55%, rgb(73 0 0) 100%);
    box-sizing: border-box;
    padding: 20px;
    z-index: 9;
    width: 100%;
    color: rgb(239 150 150);
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    top: 0;
    left: 0;
    height: 80px;
}
</style>