<template>
    <main class="register background all_posts">

        <div v-if="tokenConfirmed">

            <article class="news news2">
                <ArticleLogo />
                <h3>New Password</h3>
            </article>
            <div class="regl">

                <div class="comp_title comp_title_matchday">
                    <h5 class="league-info-header">
                        Set a new Password
                    </h5>
                </div>
                <form action="" class="login_form">
                    <div>
                        <TextField name="password" type="password" v-model="password" />
                        <TextField name="confirm password" type="password" v-model="passwordConfirm" />
                    </div>

                    <input type="submit" name="submit" value="update Password" class="button_submit" id="subone"
                        @click="newPassword">
                </form>
            </div>
        </div>


    </main>
</template>

<script>
import TextField from '@/components/TextField.vue';
import ArticleLogo from '@/components/ArticleLogo.vue';
export default {
    name: 'NewPassword',
    inject: ['backendServer', 'setFlashedMessage', 'loading', 'doUpdateUserInfo'],
    components: { TextField, ArticleLogo },
    data() {
        return {
            tokenConfirmed: null,
            password: '',
            passwordConfirm: ''
        }
    },
    methods: {
        newPassword(event) {
            event.preventDefault();
            if (this.password.length < 8) {
                this.setFlashedMessage("password must at least be 8 characters");
                return
            }
            else if (this.password != this.passwordConfirm) {
                this.setFlashedMessage("Passwords does'nt match");
                return
            }
            fetch(this.backendServer() + '/set-new-password/' + this.$route.query.token, {
                method: "POST", // *GET, POST, PUT, DELETE, etc.

                mode: "cors", // no-cors, *cors, same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "include", // include, *same-origin, omit
                headers: {
                    //"Content-Type": "application/json",
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: "follow", // manual, *follow, error
                referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify({
                    'password': this.password,
                    'password_confirm': this.passwordConfirm,
                }), // body data type must match "Content-Type" header
            }).then((response) => {
                return response.json();
            }).then((data) => {
                // If the user is logged in:
                // Router...

                if (data.success) {
                    this.setFlashedMessage("Password successfully changed,you can login now")
                    setInterval(() => {
                        this.$router.push({ name: 'login' })

                    }
                        , 5000)
                }




            })

        },
    },
    mounted() {
        console.log('starting')
        this.loading();
        const token = this.$route.query.token;
        const mail = this.$route.query.email;
        console.log(mail)
        if (!token) {
            console.log('no token')

            this.$router.push({ name: 'login' })
            return
        }
        fetch(this.backendServer() + `/new-password/${token}/${mail}`, {
            method: "GET",
            cache: "no-cache",
            credentials: 'include',
        }).then((response) => {
            return response.json();
        }).then((data) => {
            console.log('data is', data)
            if (data == 'error') {
                this.loading()
                this.setFlashedMessage("unvalid token");
                setInterval(() => {
                    this.$router.push({ name: 'login' })

                }
                    , 2000)

            }


            else {
                console.log('right hewre')
                this.tokenConfirmed = true;
                this.loading()
            }
        })
    }
}
</script>

<style></style>