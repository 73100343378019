<template>
    <main class="background imageless_bg" id="profileid">
        <div class="pcon">
            <ProfileMenu />
            <div id="profile">
                <div class="profile">
                    <div class="viv">
                        <span v-html="checked"></span> visible / <span v-html="unchecked"></span> invisible to
                        others <br>
                        click to change
                    </div>
                    <AvatarVue />
                    <form action="" class="bg" method="post" enctype="multipart/form-data">
                        <span class="ua">Username: {{ current_user }} </span>
                        <div class="emsc">
                            <ProfileFields type='Email' :name="current_email" :placeholder="current_email" />
                            <span v-html="show_email ? checked : unchecked" @click="checkVisibility" class="cusvg"
                                id="show_email"></span>
                        </div>

                        <ProfileFields v-if="!user_age" type='age' name="age" placeholder="MM/DD/YYYY" />
                        <span class="ua" v-else>Age: {{ user_age }} <span v-html="show_age ? checked : unchecked"
                                @click="checkVisibility" id="show_age" class="cusvg"></span></span>
                        <div v-if="!user_nation">
                            <span v-if="[countries, !user_nation]" class="ua">
                                Country:
                                <select ref="cname" class="nlist" name="list" id="">
                                    <option v-for="(c, index) in countries" :key="index" :value="c">{{ c }}</option>
                                </select> <input type="button" class="nsub update input_profile button_submit"
                                    value="submit" @click="decideNation">

                            </span>
                        </div>
                        <div v-else>
                            <span class="ua">
                                Nation: {{ user_nation }} <span v-html="show_nation ? checked : unchecked" class="cusvg"
                                    @click="checkVisibility" id="show_nation"></span>
                            </span>
                        </div>
                        <!-- <ProfileFields type='web' :name="current_web" :placeholder="current_web" /> -->

                        <ProfileFields type='Bio' name="Bio" placeholder="" />

                    </form>
                </div>
                <div class="ubio">
                    {{ user_bio }}
                </div>
            </div>
        </div>

    </main>
</template>

<script>
import ProfileFields from '@/components/profile/ProfileFields.vue';
import ProfileMenu from '@/components/profile/ProfileMenu.vue';
import AvatarVue from '@/components/profile/AvatarVue.vue';

export default {
    name: 'ProfileVue',
    components: { ProfileFields, ProfileMenu, AvatarVue },
    inject: ['backendServer', 'show_nation', 'show_age', 'show_email', 'current_user', 'current_email', 'user_age', 'user_web', 'user_bio', 'currentHost', 'doUpdateUserInfo', 'loading', 'setFlashedMessage', 'user_nation'],
    data() {
        return {
            countries: null,
            checked: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                        <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
                        </svg>`,
            unchecked: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-slash-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                        <path d="M11.354 4.646a.5.5 0 0 0-.708 0l-6 6a.5.5 0 0 0 .708.708l6-6a.5.5 0 0 0 0-.708"/>
                        </svg>`
        }
    },
    methods: {
        decideNation() {
            this.loading()

            fetch(this.backendServer() + '/country-submit/' + this.$refs.cname.value, {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {

                    if (typeof data == 'object') {
                        this.doUpdateUserInfo(data)
                        this.loading()

                        this.setFlashedMessage('nationality updated');

                    } else {
                        this.setFlashedMessage(data);
                        this.loading()

                    }
                });
        },
        checkVisibility(event) {
            this.loading()

            fetch(this.backendServer() + '/visibility/' + event.target.closest('span').id, {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {

                    if (typeof data == 'object') {
                        this.doUpdateUserInfo(data)
                        this.loading()


                    } else {
                        this.setFlashedMessage(data);
                        this.loading()

                    }
                });
        }
    },
    mounted() {
        document.title = this.current_user + ' / Rossoneries';
        if (!this.user_nation) {
            fetch(this.backendServer() + '/countries-list', {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {

                    this.countries = data;

                });
        }


    }
}

</script>
<style >
.cusvg {
    cursor: pointer;
    color: white
}

.pcon {
    display: flex;
    margin-top: 50px;
}

.emsc {
    display: flex;
    align-items: center;
}



div.viv {
    position: absolute;
    left: 10px;
    font-size: .85em;
    top: 10px;
}

span.ua {
    margin: 0 10px;
    padding: 4px;
    white-space: nowrap;
}

.nsub,
.nlist {
    font-size: 13px;
}

.nlist {
    width: 100px;
    border: none;
    padding: 2px;
    border-radius: 10px;
    outline-color: white;
}



div.ubio {
    position: absolute;
    transform: translate(-50%, -50%);
    bottom: -7px;
    left: 50%;
    color: aliceblue;
    word-wrap: break-word;
    font-size: .7em;
    width: 100%;
    text-align: center;
}

.ua {
    white-space: nowrap;
    overflow: hidden;
    max-width: 230px;
    text-overflow: ellipsis;
}
</style>