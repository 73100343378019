<template>
    <li class="standings seriaa sr">

        <table class="seriaa standings">
            <thead>
                <tr>
                    <th style="padding-left: 5px;">User</th>
                    <th>Rank</th>

                    <th>Mp</th>
                    <th class="hidenow">exact</th>
                    <th class="hidenow">marg</th>
                    <th class="hidenow">just</th>
                    <th>points</th>
                </tr>
            </thead>

            <tbody v-if="player">
                <tr class="teams">

                    <td class="team pre-p pre-r"><img :src="player.image" class="pre-pic pre-rank">
                        {{ player.name }}

                    </td>
                    <td><span>{{ player.position }}</span></td>
                    <td>{{ player.mp }}</td>
                    <td class="hidenow">{{ player.exact }}</td>
                    <td class="hidenow">{{ player.margin }}</td>
                    <td class="hidenow">{{ player.just }}</td>
                    <td>{{ player.points }}</td>

                </tr>
            </tbody>


        </table>
        <div class="pre_full_table">
            <router-link class="small_table" :to="{ name: 'predictiontable' }">View Full Table</router-link>
        </div>

    </li>
</template>

<script>
export default {
    name: 'UserRank',
    props: ['player']
}
</script>

<style>
.pre-rank {
    width: 30px !important;
    height: 30px !important;
}

.pre-r {
    align-items: center;
}

div.pre_full_table a {
    font-size: 13px !important;
    cursor: pointer;
    text-decoration: none;
    transition: all .3s linear;
    color: black;
}

div.pre_full_table a:hover {
    cursor: pointer;
    text-decoration: underline;
    transition: all .3s linear;
    color: red;
}
</style>