<template>
    <div id="overlay" class="show">

        <div class="adding_post ">
            <ul class="posted_data post_complete">

                <li class="new_posted all_posts">
                    <div class="post_title">
                        <span v-if="user_login_info">
                            <img class="posted_profile_picture" :src="profile_image" alt="">
                            <br>
                            by {{ current_user }} </span>
                        <form action="" method="post">
                            <!-- <span>title:</span> -->
                            <input v-if="!user_login_info" class="subject" type="text" v-model="email"
                                placeholder="Email">
                            <br v-if="!user_login_info">
                            <input class="subject" type="text" v-model="title" placeholder="Subject"
                                @input="limitChars">
                            <textarea name="" v-model="content" id="" cols="30" rows="10"></textarea>
                            <br>
                            <input type="button" value="send" class="button_submit" id="post_submit"
                                @click="SendNewTicket">
                            <input type="button" value="cancel" class="button_submit" id="post_cancel"
                                @click="cancelTicket">

                        </form>

                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {

    inject: ['backendServer', 'current_user', 'profile_image', 'currentHost', 'user_login_info', 'setFlashedMessage', 'loading'],
    data() {
        return {
            email: '',
            content: '',
            title: '',
            maxinput: 25
        }
    },
    methods: {
        limitChars() {
            if (this.title.length > this.maxinput)
                this.title = this.title.slice(0, this.maxinput)
        },
        cancelTicket() {
            this.$emit('close')
        },
        SendNewTicket() {
            this.loading()
            fetch(this.backendServer() + '/newTicketvue', {
                method: "POST",
                mode: "cors", // no-cors, *cors, same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "include", // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: "follow", // manual, *follow, error
                referrerPolicy: "no-referrer",

                body: JSON.stringify({
                    'content': this.content,
                    'title': this.title,
                    'email': this.email

                }), // body data type must match "Content-Type" header
            }).then((response) => {
                return response.json();
            }).then((data) => {
                this.cancelTicket()
                this.loading()
                if (data == 'current_user') {
                    this.setFlashedMessage('thank you dear ' + this.current_user + ',you can access your tickets from your profile')
                } else {
                    this.setFlashedMessage('we will get back tou you as soon as possible,please make sure to check your email')
                }
            })
        }
    }

}
</script>

<style scoped>
li {
    border: 2px solid #8b9189 !important
}

input {
    margin: 0 auto !important;
    margin-bottom: 2px !important;
}

input.subject::placeholder {
    opacity: .5
}

.adding_post {
    top: 50% !important;
}

div.adding_post ul.posted_data {
    width: 100%;
}
</style>