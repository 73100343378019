<template>
    <div :class="show && 'home_league_table_show'" class="home_league_table">
        <div class="home_league_table_body">
            <span class="home-titles">Serie A</span>
            <table class="home_standings">
                <thead>
                    <tr>
                        <th>club</th>
                        <th>mp</th>
                        <th>pts</th>
                    </tr>
                </thead>
                <tbody v-if="table">
                    <tr v-for="(team, index) in table.slice(0, 9)" :key="index" class="teams">
                        <td><span style="width: 17px; display: inline-block; text-align: right;">{{ index + 1
                        }}-</span><img style="width: 16px;height: 16px;" :src="team.logo" alt=""> {{ team.name }}
                        </td>
                        <td>{{ team.wins + team.draws + team.losses }} </td>
                        <td>{{ team.points }}</td>
                    </tr>
                </tbody>
            </table>
            <div class="seriaa_full_table">
                <router-link class="small_table" :to="{ name: 'leagueinfo' }">Full Table</router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TableHome',
    inject: ['backendServer', 'currentHost'],
    props: ['show'],
    data() {
        return {
            table: null,
        }
    },
    mounted() {

        fetch(this.backendServer() + '/serie-a/default', {
            method: "GET",
            credentials: "include"
        })
            .then((response) => response.json())
            .then((data) => {

                this.table = data.teams;

            });

    }

}
</script>

<style></style>