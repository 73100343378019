<template>
    <main class="register background all_posts">
        <div>

            <article class="news news2">
                <ArticleLogo />
                <h3>Reset Password</h3>
            </article>

            <div id="buttonDiv"></div>
            <div class="comp_title comp_title_matchday">
                <h5 class="league-info-header">
                    Please enter your Email
                </h5>
            </div>
            <form class="login_form">


                <div class="choose_login_method">

                    <TextField name="email" type="text" v-model="email" />

                </div>


                <input type="submit" name="submit" value="Submit email" class="button_submit" id="subone"
                    @click="checkEmail">


            </form>
        </div>
    </main>
</template>

<script>
import ArticleLogo from '../components/ArticleLogo.vue'
import TextField from "../components/TextField.vue"

export default {
    name: 'LoginVue',
    inject: ['backendServer', 'setFlashedMessage', 'doUpdateUserInfo', 'currentHost', 'loading'],
    components: { ArticleLogo, TextField },
    data() {
        return {
            email: '',
        }
    },
    //emits: ["userInfoHasBeenUpdated"],

    methods: {
        checkEmail(event) {
            event.preventDefault();

            this.loading()

            fetch(this.backendServer() + '/reset-password/' + this.email, {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {
                    this.loading()
                    this.setFlashedMessage(data);

                })

        }

    }
}
</script>

<style>
#buttonDiv {
    margin: 10px auto;
    max-width: 200px;
    min-height: 44px;
}

#remember {
    margin-left: 5px;
}
</style>