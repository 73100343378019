import { reactive } from "vue";
import router from "@/router";
 
const userStore = reactive({
  user_login_info: null,
  username: "guest",
  email: "",
  is_admin: "",
  profile_image: "",
  age:"",
  nation:"",
  ticket_alert: null,
  bio: null,
  alert:null,
  alertInterval: null,
  show_nation:null,
  show_age:null,
  show_email:null,
    currentHost() {
      let hostFragments = location.host.split(':');

      return hostFragments[0];

    },
  isProduction() {
    return process.env.NODE_ENV === 'production';

  },
  backendServer() {
    return (this.isProduction() ? 'https://' : 'http://') + this.currentHost() + (this.isProduction() ? '/api' : ':5000');
  },
  async getUserInfo() {
    // console.log(this.backendServer())
    await fetch(this.backendServer(), {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      cache: "no-cache", // *default, no-cache, reload, force-cache, o
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          this.update(data);
        }
      });
  },
  update(data) {
    this.user_login_info = data.user_login_info;
    this.username = data.username;
    this.email = data.email;
    this.profile_image = data.profile_image;
    this.ticket_alert = data.ticket_alert;
    this.is_admin = data.is_admin;
    this.age = data.age
    this.bio = data.bio
    this.alert = data.alert
    this.nation = data.nation
    this.show_nation = data.show_nation
    this.show_age = data.show_age
    this.show_email = data.show_email
  },
  startAutoRefresh() {
    // Call fetchAlert initially
    this.getUserInfo();

    // Set up interval to call fetchAlert every minute (60,000 milliseconds)
    this.alertInterval = setInterval(() => {
        this.getUserInfo();
    }, 60000);
},
  logout() {
    // console.log("logging out");
    fetch(this.backendServer() +"/logout", {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      cache: "no-cache", // *default, no-cache, reload, force-cache, o
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        this.update(data);
        router.push({ name: "login" });

      });
  },
  changeStage(name) {
    // console.log('name is now :', name)
    switch (name) {
        case '1/8 Final':
            return 7
        case 'Quarter Final':
            return 8
        case 'Semi Final':
            return 9
        case 'Final':
            return 10
        default:
            return name
    }

},
});

export default userStore;
