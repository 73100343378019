import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
//import userStore from "./store/userStore";
import '../public/css/maincss.css';


let app = createApp(App);
app.config.unwrapInjectedRef = true;

// const usrInfo = {};
// app.provide('usrInfo', usrInfo);
//userStore.getUserInfo();

app.use(router).mount("#app");

