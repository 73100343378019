<template>
    <div id="overlay" class="show" @click="doOverlay">

        <div class="izm">
            <span class="izmc">
                <img :src="source" alt="" class="izcom">

                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                    class="czoom bi bi-x-circle-fill" viewBox="0 0 16 16">
                    <path
                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                </svg>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ImageZoomer',
    props: ['source'],
    inject: ['doOverlay', 'deactivateZoom'],
    methods: {
        attachEvent(event) {

            if (!event.target.closest('.izcom')) {
                this.deactivateZoom()
            }
        }
    },
    mounted() {

        setTimeout(() => document.addEventListener('click', this.attachEvent), 1000);
    },
    unmounted() {
        document.removeEventListener('click', this.attachEvent);
    }
}
</script>

<style>
div.izm {
    text-align: center;
    position: absolute;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
    width: 95%;

}

.izmc {
    position: relative;
    height: fit-content;
    margin: 0 auto;
    border-radius: 10px;
    display: block;
    width: fit-content;
    background: black;
}

.czoom {
    position: absolute;
    right: 1px;
    top: 2px;
    color: white;
    cursor: pointer;
}

.izcom {
    max-width: 100%;
    max-height: 500px;
    border-radius: 10px;
}
</style>