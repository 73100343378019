<template>
    <div>

        comments<span id="comments_length">({{ comments }}) </span>


        <br>
        <div v-if="user_login_info" class="user_comment">
            <img class="posted_profile_picture" :src="profile_image" alt="">
            <form action="" @submit.prevent="">
                <textarea @click="focusChange" ref="textAreaReply" v-model="comment_body" placeholder="new comment"
                    class="comments" name="" id="" cols="30" rows="10"></textarea>
                <!-- <svg @mousedown="newComment" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-send" viewBox="0 0 16 16">
                    <path
                        d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                </svg> -->
                <div :class="focus && 'comments_buttons_visible'" class="comment_buttons">
                    <EmojisVue @emoji="addEmoji" />

                    <button @click="newComment" class="button_submit">reply</button>
                    <button @click="CancelReply" class="button_submit">cancel</button>
                </div>
            </form>

        </div>
        <div v-else id="app" class="login_to_comment">
            <p>please <router-link class="return_to_login" :to="{ name: 'login' }">login</router-link> or
                <router-link class="return_to_register" :to="{ name: 'register' }">register</router-link> to leave
                a comment
            </p>
        </div>

        <!-- <div>
                <div id="all_comments" class="all_comments">
                    <div>
                        {% include 'single_comment.html' %}
                    </div>
                </div>

            </div> -->
    </div>
</template>

<script>
import EmojisVue from '../EmojisVue.vue'

export default {
    name: 'PostComments',
    components: { EmojisVue },
    props: ['comments'],
    inject: ['backendServer', 'user_login_info', 'profile_image', 'currentHost', 'emojis'],
    data() {
        return {
            'comment_body': null,
            'comments_to_emit': null,
            'id': window.location.href.split('/'),
            'focus': false
        }
    },
    methods: {
        addEmoji(emoji) {

            let reply = this.$refs.textAreaReply.value
            let caret = this.$refs.textAreaReply.selectionStart
            reply = reply.substring(0, caret) + emoji + reply.substring(caret)
            //this.$refs.textAreaReply.value = reply
            this.comment_body = reply
            this.$refs.textAreaReply.focus()
        },
        focusChange() {

            this.focus = true
        },
        CancelReply() {
            this.focus = false
            this.comment_body = null
        },
        newComment() {
            if (this.comment_body == null)
                return
            if (this.$route.name == 'PostToComment') {
                this.id = this.id[this.id.length - 2]

            } else {
                this.id = this.id.pop()
            }
            fetch(this.backendServer() + '/posts/' + this.id + '/comment', {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: "follow",
                referrerPolicy: "no-referrer",

                body: JSON.stringify({
                    'comment_body': this.comment_body,

                }),
            }).then((response) => {
                return response.json();
            }).then((data) => {
                this.comments_to_emit = data['comments']
                this.$emit('reloadComments', this.comments_to_emit)
                this.CancelReply()
                this.id = window.location.href.split('/')
            })
        }
    }
}
</script>

<style scoped>
.comment_buttons {
    display: none;
    margin-block: -3px;
}

.comments_buttons_visible {
    display: flex;
    align-items: baseline;
}

form {
    width: 100% !important;
    flex-flow: column !important;
    align-items: baseline !important;
}

textarea {
    font-size: .7em;
    height: 40px !important;
    width: 90% !important;
    vertical-align: middle;
    margin: 5px 0;
    border: none;
    transition: height .3s linear;
}

/* textarea:focus {
    height: 40px !important;
} */

textarea::after {
    height: 40px !important;
}

textarea:focus~.bi-send {
    opacity: 1;

}
</style>