<template>
    <main class="news all_posts">

        <article class="news news2">
            <ArticleLogo :article="article" />
        </article>
        <LeagueLogo :logo="logo" />
        <div v-if="$route.path.includes('/serie-a')" :class="$route.path.includes('/serie-a') && 'sub_container_small'"
            class="sub_container_b">
            <NewsSubMenu name="Info" route="leagueinfo" />
            <NewsSubMenu name="Table" route="leaguetable" />
            <NewsSubMenu name="Matchday" route="matchday" />
            <NewsSubMenu name="Goal-Scorers" route="serieags" />
        </div>
        <div class="comp_title comp_title_matchday">
            <h5 class="league-info-header">{{ title }}</h5>
            <div>
                <select v-model="selectedOption" @change="sorting" name="" id="" class="year_options">
                    <option v-for="week in weeks" :key="week" :value="week">{{ week }}</option>
                </select>
            </div>
        </div>
        <ul class="league_info matchday fixtures">
            <TeamResult v-for="(game, index) in done" :key="week + '-' + index" :game="game" :reset="reset" />
            <FixturesVue v-for="(game, index) in  undone " :key="index" :game="game" />
        </ul>

    </main>
</template>

<script>
import LeagueLogo from '@/components/LeagueLogo.vue'
import NewsSubMenu from '@/components/team/NewsSubMenu.vue'
import ArticleLogo from '../components/ArticleLogo.vue'
import FixturesVue from '@/components/team/FixturesVue.vue'
import TeamResult from '@/components/team/TeamResult.vue'
// import LastWeekResult from '@/components/team/LastWeekResult.vue'

export default {
    name: 'MatchDay',
    components: { ArticleLogo, NewsSubMenu, FixturesVue, TeamResult, LeagueLogo },
    emits: ['news_direction'],
    inject: ['backendServer', 'loading', 'currentHost'],
    data() {
        return {
            fullScreen: true,
            reset: false,
            article: 'News',
            limit: undefined,
            activeMenu: 'serie A',
            activeSubMenu: 'match day',
            currentYear: 'default',
            results: null,
            week: 0,
            weeks: null,
            option: null,
            selectedOption: 38,
            title: 'Matchday',
            done: null,
            undone: null,
            logo: 'logo-serie-a',


        }
    },
    methods: {

        sorting(event) {

            this.weeklyResults(event.target.value)
        },
        weeklyResults(newWeek = 0) {
            this.loading()
            fetch(this.backendServer() + '/all-league-results/' + newWeek, {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {
                    this.week = newWeek
                    this.done = data.done;
                    this.undone = data.undone;
                    this.weeks = data.weeks
                    this.option = data.option
                    this.selectedOption = this.option
                    // this.title = 'Matchday ' + this.option
                    this.loading()
                });
        },
    },
    mounted() {
        this.weeklyResults()
        document.title = 'Serie A Matchday / Rossoneries';
    },

}
</script>

<style >
.matchday {
    display: block !important;
}

.comp_title_matchday h5 {
    padding: 0;
    margin-bottom: 0;
    /* margin-top: 50px */
}

.comp_title_matchday {
    margin-top: 5px;
}

main div.comp_title_matchday div {
    position: relative;
}
</style>