<template>
    <main class="news all_posts single-match">
        <ul v-if="game" class="sm fixtures-si">
            <TeamResults2 :game="game" />
            <TableNews :single="single" :fullScreen="fullScreen" :currentYear="currentYear" :home="game.home"
                :away="game.away" />

        </ul>
    </main>
</template>

<script>
import TeamResults2 from '@/components/team/TeamResults2.vue'
import TableNews from '@/components/team/TableNews.vue'
export default {
    name: 'SingleMatchResult',
    inject: ['backendServer', 'currentHost'],
    components: { TeamResults2, TableNews },

    data() {
        return {
            game: null,
            currentYear: 'default',
            fullScreen: true,
            single: true
        }
    },

    mounted() {
        let id = window.location.href.split('/').pop();

        fetch(this.backendServer() + '/single-results/' + id, {
            method: "GET",
            credentials: "include"
        })
            .then((response) => response.json())
            .then((data) => {
                this.game = data[0];

                document.title = this.game.home + ' ' + this.game.ft + ' ' + this.game.away;

            });

    }
}
</script>

<style scoped>
li.sr {
    border-radius: none !important;
}

ul.sm {
    width: 100% !important;
    margin: 0 !important;
}

main.single-match {
    padding-top: 0 !important;
}
</style>