<template>
    <li class="fng jr jr2" :class="expanded && 'open'" :id="game.id" @click="openResult">

        <div class="match-title">

            <span class="match-d">{{ game.date }}</span>
            <span class="c-name">{{ game.title }}</span>
            <span class="spacer"></span>
            <!-- <span :id="game.id" class="spacer"><svg @click="openResult" xmlns="http://www.w3.org/2000/svg" x="0px"
                    y="0px" width="16" height="16" viewBox="0 0 30 30">
                    <path
                        d="M 25.980469 2.9902344 A 1.0001 1.0001 0 0 0 25.869141 3 L 20 3 A 1.0001 1.0001 0 1 0 20 5 L 23.585938 5 L 13.292969 15.292969 A 1.0001 1.0001 0 1 0 14.707031 16.707031 L 25 6.4140625 L 25 10 A 1.0001 1.0001 0 1 0 27 10 L 27 4.1269531 A 1.0001 1.0001 0 0 0 25.980469 2.9902344 z M 6 7 C 4.9069372 7 4 7.9069372 4 9 L 4 24 C 4 25.093063 4.9069372 26 6 26 L 21 26 C 22.093063 26 23 25.093063 23 24 L 23 14 L 23 11.421875 L 21 13.421875 L 21 16 L 21 24 L 6 24 L 6 9 L 14 9 L 16 9 L 16.578125 9 L 18.578125 7 L 16 7 L 14 7 L 6 7 z">
                    </path>
                </svg></span> -->
        </div>

        <div class="fixtures">

            <div class="ht">{{ game.home }}<img class="fixl" :src="game.homeLogo" alt="">
            </div>
            <div class="match-res">
                <span class="match-t">{{ game.ft }}</span>
                <span v-if="!expanded" class="match-st">{{ secondResDecider(game.ht, game.et, game.pen) }}</span>
            </div>
            <div class="at"><img class="fixl" :src="game.awayLogo" alt=""> {{
        game.away
    }}

            </div>
        </div>
        <Transition name="slide">
            <div v-if="expanded" class="m-events" ref="result">
                <div v-for="(p, index) in  game.events " :key="index" :class="classDecider(p.align)">
                    <span v-if="classDecider(p.align) == 'right'">
                        <p class="gmode">{{ typeMode(p.type) }} </p>
                        <p class="gname">{{ p.player != '-1' ? transformName(p.player) : `(${p.result})` }}</p>
                        <p v-if="resCondition(p)" class="gres">
                            {{ p.result }}
                        </p>
                        <img v-if="p.player != '-1'" class="gball" :src="imgDecider(p)" alt="">
                        {{ p.player != '-1' ? `${p.min}'` : '' }}
                    </span>
                    <span v-else-if="classDecider(p.align) == 'left'">
                        {{ p.player != '-1' ? `${p.min}'` : '' }}
                        <img v-if="p.player != '-1'" class="gball" :src="imgDecider(p)" alt="">
                        <p v-if="resCondition(p)" class="gres">
                            {{ p.result }}
                        </p>
                        <p class="gname">{{ p.player != '-1' ? transformName(p.player) : `(${p.result})` }}</p>
                        <p class="gmode">{{ typeMode(p.type) }} </p>


                    </span>
                    <span v-else>
                        <p class="gmode">{{ typeMode(p.type) }} </p>
                        <p class="gname">{{ p.player != '-1' ? p.player : `${p.min}(${p.result})` }}</p>
                        <p v-if="resCondition(p)" class="gres">
                            {{ p.result }}
                        </p>
                        <img v-if="p.player != '-1'" class="gball" :src="imgDecider(p)" alt="">
                        {{ p.player != '-1' ? `${p.min}'` : '' }}


                    </span>

                </div>
            </div>
        </Transition>

        <svg ref="svg" @click="expand" xmlns="http://www.w3.org/2000/svg" :class="expanded && 'mres-rotate'" width="16"
            height="16" fill="currentColor" class="mres bi bi-chevron-double-down" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
            <path fill-rule="evenodd"
                d="M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
        </svg>
    </li>
</template>

<script>
import goalb from '@/assets/img/icons8.com.png'
import mg from '@/assets/img/icons8.com2.png'
import red from '@/assets/img/icons8-red.png'
import yellow from '@/assets/img/icons8-soccer-yellow-card-48.png'
export default {
    name: 'ResultsVue',
    props: ['game', 'reset'],
    data() {
        return {
            mg: mg,
            goalb: goalb,
            red: red,
            expanded: false,
            yellow: yellow
        }
    },
    updated() { },
    methods: {
        openResult(event) {
            const width = 500;
            const height = 800;
            let link
            let theId = event.target.closest('li').id
            // Calculate the left and top positions to center the window
            const left = window.innerWidth / 2 - width / 2;
            const top = window.innerHeight / 2 - height / 2;
            if (this.$route.name == 'uclmatchday') {
                link = '/comp/ucl/results/'
            }
            else if (this.$route.name == 'uelmatchday') {
                link = '/comp/uel/results/'
            }
            else if (this.$route.name == 'results') {
                link = '/team/schedule/results/'
            }
            else {
                link = '/serie-a/match/'
            }
            const newWindow = window.open(`${link}${theId}`, '_blank', `width=${width}, height=${height}, left=${left}, top=${top}`);
            newWindow.opener = null;
        },
        expand(event) {
            event.stopImmediatePropagation();

            this.expanded = !this.expanded;

            if (!this.expanded)
                return;

            this.$nextTick(() => {
                this.$refs['result'].style.maxHeight = 'none';
                //this.$refs['result'].style.visibility = 'hidden';
                void this.$refs['result'].offsetHeight;

                //this.$refs['result'].style.maxHeight = this.$refs['result'].offsetHeight + 'px';
                this.$refs['result'].style.setProperty('--myheight', this.$refs['result'].offsetHeight + 'px');
                this.$refs['result'].style.maxHeight = null;
            });

        },


        imgDecider(p) {

            if (p.type == 'yellow')
                return this.yellow
            if (p.type == 'red')
                return this.red
            if (p.type == 'penm')
                return this.mg
            else
                return this.goalb
        },
        secondResDecider(ht, et, pen) {
            if (pen != '-1')
                return `p(${pen})`
            else if (et != '-1')
                return `AET(${et})`
            else
                return `HT(${ht})`
        },
        classDecider(align) {
            if (align == 'left')
                return 'left'
            if (align == 'right')
                return 'right'
            else
                return 'center'
        },

        typeMode(type) {
            if (type == 'pen')
                return '(Pen)'
            if (type == 'penm')
                return '(Pen missed)'
            if (type == 'own')
                return '(OG)'
            else
                return ''
        },
        resCondition(p) {
            if (p.type == 'red' || p.type == 'penm' || p.player == '-1')
                return false
            else
                return true
        },
        transformName(name) {

            const parts = name.trim().split(' ');

            if (parts.length === 1) {
                return name; // If there is only one part, return the name as is
            } else {

                return parts[0][0] + '.' + parts[parts.length - 1];

            }

        },

    },
    watch: {
        reset: function () { // watch it

            this.expanded = false;
        }
    },



}
</script>
<style>
/* li.ucl table.standings td {
    width: 30px;
} */
li.jr2 {
    cursor: pointer;
}

li.ucl table.standings td:not(:first-child) {
    /* Adjust the width for the excluded nth child */
    width: 30px;
}


.slide-enter-from,
.slide-leave-to {
    max-height: 0;
    opacity: 0;
}

.mres-rotate {
    rotate: 180deg;
}

.slide-enter-to,
.slide-leave-from {
    max-height: var(--myheight, 500px);
    opacity: 1;
}

.slide-enter-active,
.slide-leave-active {
    transition: all 0.3s linear;
}

div.center span p.gname {
    width: 8%;
    min-width: 80px;
    color: black;
    border-radius: 12px;
    background-color: #ffabbb;
    font-family: monospace;
}

/* .fng.open div.m-events {} */

.gball {
    width: 16px;
    vertical-align: sub;
    transition: .2s width linear;

}

.gballshow {
    width: 16px;
}



.m-events div span p.gres {
    margin: 0 2px !important;
    padding: 0 5px;
    border-radius: 8px;
    background: white;
}

.m-events div span p {
    margin: 0 !important;
    display: inline-block;
}

div.m-events div span span.lres {
    position: absolute;
    left: -42px
}

div.m-events div span span.rres {
    position: absolute;
    right: -42px
}



div.m-events {
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: 0.9em;
    width: 100%;
    margin-top: 10px;
}

div.m-events div span {
    position: relative;
}

.right {
    text-align: left;
    margin-left: auto;
    padding: 1px;
    box-sizing: border-box;
    width: 45%;
    white-space: nowrap;
}

.center {
    box-sizing: border-box;
    padding: 1px;
    position: relative;
}

.left {
    text-align: right;
    margin-right: auto;
    padding: 1px;
    box-sizing: border-box;
    width: 45%;
    white-space: nowrap;

}

div.scorers {
    position: absolute;
    transform: translateY(100%);
    font-size: .58em;
    display: flex;
    flex-direction: column;
    bottom: 0px;
    right: 32px;
}

div.ascorers {
    position: absolute;
    transform: translateY(100%);
    font-size: .58em;
    display: flex;
    flex-direction: column;
    bottom: 0px;
    left: 32px;
}

@media screen and (max-width:500px) {
    li.fng {
        font-size: .9em;
    }
}

@media screen and (max-width:440px) {
    li.fng {
        font-size: .82em;
    }

    .left,
    .right {
        width: 50%;
    }
}

div.match-t {
    padding: 0
}

span.winner {
    font-weight: bold;
}

div.match-res {
    display: grid;
    position: relative;
    width: 60px
}

.match-st {
    font-size: .7em;
    position: absolute;
    bottom: 0;
    transform: translate(-50%, 10px);
    left: 50%;
    width: 100%;
}
</style>