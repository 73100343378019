<template>
    <div class="login_labels">
        <!-- @focus="onFocus" @focusout="offFocus" -->
        <input :type="type" @input="$emit('update:modelValue', $event.target.value)" :value="modelValue" class="login"
            ref="input" :aria-label="name" required>
        <label @click="focusInput" :for="name" ref="label">{{ name }}</label>
        <span v-if="isPassword() && modelValue.length" @mousedown="revealPassword" class="glasses" ref="glasses">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffffff" class="bi bi-eyeglasses"
                viewBox="0 0 16 16">
                <path
                    d="M4 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm2.625.547a3 3 0 0 0-5.584.953H.5a.5.5 0 0 0 0 1h.541A3 3 0 0 0 7 8a1 1 0 0 1 2 0 3 3 0 0 0 5.959.5h.541a.5.5 0 0 0 0-1h-.541a3 3 0 0 0-5.584-.953A1.993 1.993 0 0 0 8 6c-.532 0-1.016.208-1.375.547zM14 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" />
            </svg></span>
    </div>
</template>

<script>
export default {
    props: ['name', 'type', 'modelValue'],
    data() {

        return {
            emailLabel: false,
            focusOutTimer: null,
        }

    },
    methods: {
        focusInput() {
            this.$refs.input.focus()
        },
        revealPassword(event) {

            event.stopImmediatePropagation();

            let type = this.$refs.input.type
            type = type == 'password' ? 'text' : 'password'
            this.$refs.input.type = type
            if (document.activeElement === this.$refs.input) {

                this.$refs.input.classList.add('keepFocus')


                setTimeout(() => {
                    this.$refs.input.focus()
                    this.$refs.input.classList.remove('keepFocus')
                }, 1);

            }

            // this.$refs.input.focus()
            // this.$refs.input.setAttribute('type', 'text')
            // this.$refs.input.classList.toggle('input_focus')
            // if (this.$refs.input.classList.contains('input_focus')) {
            //     

            // }

        },
        isPassword() {

            if (this.type == 'password') {

                return true
            }

            else {

                return false
            }
        },
        //     moveLabel() {
        //         if (this.modelValue.length)
        //             this.$refs.label.classList.add('after_focus')

        //         if (!this.modelValue.length) {
        //             this.$refs.label.classList.remove('after_focus')
        //             this.$refs.label.classList.remove('end_focus')

        //         }
        //     },
        //     onFocus() {
        //         this.$refs.label.classList.remove('end_focus')
        //         this.$refs.label.classList.add('after_focus')
        //         this.$refs.input.classList.toggle('input_focus')
        //     },
        //     offFocus(event) {
        //         
        //         //if (event.target == )
        //         //    return;


        //         this.focusOutTimer = setTimeout(() => {
        //             if (this.modelValue.length)
        //                 this.$refs.label.classList.add('end_focus')
        //             else this.$refs.label.classList.remove('after_focus')

        //             this.$refs.input.classList.toggle('input_focus')
        //         }, 200);
        //         //this.$refs.input.classList.toggle('input_focus')
        //     },

    },
}
</script>

<style>
span.glasses {
    position: absolute;
    right: -8px;
    bottom: 50%;
    transform: translate(10px, 10px);
    cursor: pointer;
    z-index: 1000;
}

#remember {
    width: 12px;
    height: 12px;
}

div.login_labels input:focus,
div.login_labels input.keepFocus {
    width: 250px;
    height: 34px;
    font-size: 13px;
}

input:focus-visible {
    outline: none;
}

div.login_labels {
    display: inline-grid;
    text-align: left;
    position: relative;
}

div.login_labels label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: .15s all linear;
    cursor: text;
    opacity: .5;
    white-space: nowrap;

}

div.login_labels input:focus~label {
    top: 22%;
    left: 8px;
    transform: none;
    color: #898989;
    font-size: .7em;
    opacity: .41;
}



div.login_labels input:valid:not(:focus)~label {
    top: -15%;
    left: 8px;
    transform: none;
    color: #ffffff;
    font-size: .85em;
    opacity: 1;
}
</style>