<template>
    <div v-if="post" class="post_title">
        <div class="prpi">
            <PoppedProfile :pic="post.pic" :size="true" :clicked="pclick" :id="post.pic.id" />
            <span class="post_title">
                {{ post.title }}
            </span>
        </div>
        <span @click="changeStatus" class="authn">by {{ post.author }}</span>
        <div class="time_posted">
            ({{ post.date }})
        </div>

        <p>{{ post.content }}</p>
        <PostMedia v-if="post.media" :media="post.media" />

        <div class="image_container">
            <img class="post_image_full" :src="post.img" alt=""> <br>

        </div>
        <PostLike :post="post" />
    </div>
</template>

<script>
import PostLike from './PostLike.vue'
import PostMedia from './PostMedia.vue'
import PoppedProfile from '../profile/PoppedProfile.vue';
export default {
    name: 'PostTitle',
    props: ['post'],
    data() {
        return {
            pclick: 1
        }
    },
    emits: ['reloadPost'],
    components: { PostLike, PostMedia, PoppedProfile },
    methods: {
        changeStatus() {
            this.pclick += 1
        }
    }
}
</script>

<style scoped>
p {
    margin: 0 !important;
}

.image_container {
    text-align: center;
}

span.post_title {
    margin-left: 5px;
}

img.post_image_full {
    max-width: 85%;
    margin: 0 auto;
    border-radius: 21px;
    margin-bottom: 10px;
}
</style>