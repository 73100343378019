<template>
    <div :class="show && 'home_league_table_show'" class="home_league_table">
        <div class="home_league_table_body">
            <span class="home-titles">Serie A Matchday {{ week }}</span>
            <div v-for="(result, index) in results" :key="index" class="single_match"
                :class="availableResult(result.home_score)" @click="openResult($event, result.home_score)"
                :id="result.id">
                <span class="home_team">{{ result.home }} <img class="team_results_logo" :src="result.home_logo"
                        alt=""></span>
                <!-- <span v-if="!result.date.includes('within')" class="match_date">{{ result.date }}</span> -->
                <div v-if="result.home_score != -1" class="match_result" ref="chosenmatch" :id="result.id">
                    <span class="home_result">
                        {{ result.home_score }}
                    </span><span> - </span>
                    <span class="away_result">
                        {{ result.away_score }}
                    </span>
                </div>
                <div v-else class="match_result">
                    <span v-if="result.status == 'postponed'" class="pp">postponed</span>
                    <span v-else> {{ matchTime(result.time) }} </span>

                </div>


                <span class="away_team"> <img class="team_results_logo" :src="result.away_logo" alt=""> {{ result.away
                    }}</span>
            </div>
            <div class="seriaa_full_table">
                <router-link class="small_table" :to="{ name: 'matchday' }">More</router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TableHome',
    inject: ['backendServer', 'currentHost'],
    props: ['show'],
    data() {
        return {
            results: null,
            openedWindows: {},
            week: null
        }
    },
    methods: {
        matchTime(time) {
            return time != '00:00' ? time : 'TBD'
        },


        availableResult(res) {

            if (res != -1)
                return 'available-result'
        },

        openResult(event, res) {
            const targetDivId = event.target.closest('div').id;

            if (this.openedWindows[targetDivId]) {
                const existingWindow = this.openedWindows[targetDivId];

                // Check if the window is closed or not focused
                if (!existingWindow.closed) {
                    existingWindow.focus();
                    return;
                }
            }
            if (res == -1) {
                return
            }

            const width = 500;
            const height = 800;

            // Calculate the left and top positions to center the window
            const left = window.innerWidth / 2 - width / 2;
            const top = window.innerHeight / 2 - height / 2;

            const newWindow = window.open(`/serie-a/match/${event.target.closest('div').id}`, '_blank', `width=${width}, height=${height}, left=${left}, top=${top}`);
            newWindow.opener = null;
            if (newWindow) {
                this.openedWindows[targetDivId] = newWindow;

                newWindow.addEventListener('beforeunload', () => {
                    delete this.openedWindows[targetDivId];
                });
            }
        }
    },
    mounted() {

        fetch(this.backendServer() + '/serie-a/matchday', {
            method: "GET",
            credentials: "include"
        })
            .then((response) => response.json())
            .then((data) => {


                this.results = data.res;
                this.week = data.week
            });

    }

}


</script>

<style scoped>
div.single_match {
    padding: 1px 0;
    border-bottom: none;
    font-size: 12px;
}

.available-result:hover {
    background-color: inherit;
}
</style>