<template>
    <div class="pcp">
        <img @click="profilePop(pic.id)" :class="[size && 'profile_box_img2', sm && 'posted_profile_picture']"
            class="profile_box_img pnots" :src="pic.pic" alt=""> <span v-if="user" @click="profilePop(pic.id)">{{ user
            }}</span>

        <div v-if="popUser" class="puinfo">
            <img :src="popUser.img" alt="">
            <div class="unb">
                <span class="uup" :class="!popUser.bio && 'uup2'">
                    {{ popUser.username }}
                </span>
                <span v-if="popUser.nation" class="upn"><b>Nation:</b> {{ popUser.nation }}</span>
                <span v-if="popUser.email" class="upn"><b>Email:</b> {{ popUser.email }}</span>
                <span v-if="popUser.age" class="upn"><b>Age:</b> {{ popUser.age }}</span>
                <span v-if="popUser.bio" class="ubpp"><b>Bio:</b> {{ popUser.bio }}</span>
                <router-link class="ubta" v-if="popUser.threads"
                    :to="{ name: 'userposts', params: { id: popUser.user_id } }">
                    <span class="utpp"><b>Threads:</b> {{ popUser.threads }}</span>
                </router-link>
            </div>

        </div>
    </div>
    <div id="overlay" v-if="popUser" class="show" @click="doOverlay">

    </div>
</template>

<script>
export default {
    name: 'PoppedProfile',
    inject: ['backendServer', 'loading', 'currentHost', 'doOverlay'],
    props: ['pic', 'size', 'clicked', 'id', 'sm', 'user'],
    data() {
        return {

            popUser: null
        }
    },
    methods: {
        profilePop(id, event) {
            if (event) {
                event.stopPropagation();
            }


            this.loading();

            fetch(this.backendServer() + '/userprofile/pop/' + id, {
                method: "GET",
                credentials: "include",
            })
                .then((response) => response.json())
                .then((data) => {
                    this.popUser = data;

                    this.loading();

                    // Add the event listener dynamically after fetch
                    const clickHandler = (e) => {
                        if (!e.target.closest('.puinfo')) {
                            this.popUser = null;

                            const removeClickHandler = () => {
                                document.removeEventListener('click', clickHandler);
                            };

                            document.addEventListener('click', removeClickHandler, { once: true });
                        }
                    };

                    document.addEventListener('click', clickHandler);

                    // If you want to remove the listener after some condition is met
                    // you can store the reference to the handler and use removeEventListener
                    // For example, remove the listener after the next click

                });
        }
    },
    mounted() {

    },
    watch: {
        clicked: function () {
            if (this.clicked) // watch it
            {
                this.profilePop(this.id)

            }
        },


    },
}
</script>

<style>
.ubta:hover {
    color: chocolate
}

.upn b,
.ubpp b,
.utpp b {
    color: rgb(255 94 94)
}

.pcp {
    display: flex;
    align-items: center;
}

.upn {
    font-size: .75em;
    text-align: justify;
    color: rgb(255, 255, 255);
}

.profile_box_img2 {
    width: 50px !important;
    height: 50px !important;
}

.pnots {
    transition: .2s linear all;
}

.ubta {
    color: white;
    font-size: .8em !important;
    text-decoration: none;
    transition: .3s linear all;
    width: fit-content;
    display: block;
}

.ubpp {
    font-size: .75em;
    text-align: justify;
    color: rgb(255, 255, 255);
    padding-right: 7px;
    align-items: center;
}

.uup {
    text-align: center;
    margin-right: 45px;
}

.uup2 {
    margin-right: 20px !important;
}

.puinfo img {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    left: -40px;
    border: red solid 3px;
    position: absolute;
    object-fit: cover;
    top: -1px
}

.puinfo2 img {
    top: 0 !important
}

.prpi li {
    padding: 0 !important;
}

.unb {
    margin-left: 45px !important;
    width: 100%;
}

.unb span {
    display: block;
}

.puinfo {
    z-index: 8;
    color: wheat;
    position: absolute;
    background-color: rgb(0 0 0);
    display: flex;
    max-width: 260px;
    left: 65px;
    /* top: 50%; */
    border-radius: 15px;
    border: 1px solid red;
}

.puinfo2 {
    width: fit-content !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    height: 80px;
    align-items: center;
    border: 1px solid red
}
</style>