
<template>
    <div v-if="user_login_info" class="dropdown">
        <span class="profile_box">
            <NotificationButton :parentCommand="command" @closeBox="deactivateHover" />
            <div class="uic">
                <em class="hunw" style="font-style: normal;">{{ current_user }}</em>
                <img @click="activateHover" class="profile_box_img" :src="profile_image" alt="">
            </div>

        </span>



    </div>

    <ul class="drop-content" :class="[hover && 'hn', noHover && 'no-hover']">
        <li style="--i:0;">
            <router-link @click="deactivateHover" :class="activeClass('profile')" :to="{ name: 'profile' }">

                Profile
            </router-link>
        </li>

        <li style="--i:01;">
            <router-link @click="deactivateHover" :class="activeClass('tickets')" :to="{ name: 'tickets' }">Tickets
                <svg v-if="ticket_alert > 0" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-envelope-exclamation" viewBox="0 0 16 16">
                    <path
                        d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z" />
                    <path
                        d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5v1.5a.5.5 0 0 1-1 0V11a.5.5 0 0 1 1 0Zm0 3a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z" />
                </svg></router-link>

        </li>
        <li style="--i:2;">
            <router-link @click="deactivateHover" :class="activeClass('yourposts')" :to="{ name: 'yourposts' }">
                Threads
            </router-link>
        </li>
        <li style="--i:3;">
            <router-link @click="deactivateHover" :class="activeClass('notifications')" :to="{ name: 'notifications' }">
                Notifications
            </router-link>
        </li>

        <li style="--i:4;" @click="logout(); deactivateHover();" class="logout">
            logout<svg xmlns="http://www.w3.org/2000/svg" style="vertical-align:middle" width="14" height="14"
                fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                <path d="M7.5 1v7h1V1h-1z" />
                <path
                    d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
            </svg>
        </li>

    </ul>
</template>

<script>
import NotificationButton from './NotificationButton.vue';
export default {
    name: "BaseScaffold",
    inject: ['loading', 'ticket_alert', 'current_user', 'profile_image', 'user_login_info', 'logout'],
    data() {
        return {
            command: false,
            hover: false,
            noHover: false
        }
    },
    components: { NotificationButton },
    emits: ["userLoggedOut"],

    methods: {
        activeClass(linkRouteName) {
            if (this.$route.name == linkRouteName)
                return 'active_bar';

            switch (linkRouteName) {
                case 'profile_ticket':
                    if (this.$route.name == 'profile' || this.$route.name.includes('ticket'))
                        return 'active_bar';
                    break;
                case 'team':
                    if (this.$route.path.includes('/team'))
                        return 'active_bar';
                    break
                case 'prediction':
                    if (this.$route.path.includes('/prediction'))
                        return 'active_bar';
                    break
                case 'ucl':
                    if (this.$route.path.includes('/ucl'))
                        return 'active_bar';
                    break
                case 'serie-a':
                    if (this.$route.path.includes('/serie-a'))
                        return 'active_bar';
                    break
                case 'uel':
                    if (this.$route.path.includes('/uel'))
                        return 'active_bar';
                    break
                default:
                    return '';
            }

            return '';
        },
        activateHover(event) {
            event.stopPropagation();
            this.command = !this.command
            this.hover = !this.hover
            document.addEventListener('click', event => {


                if (!event.target.closest('.drop-content')) {

                    this.deactivateHover()
                }
            });
        },
        noH() {
            this.noHover = !this.noHover
            setTimeout(() => {
                this.noHover = !this.noHover;
            }, 500)
        },
        deactivateHover() {
            this.hover = false;
        },



    },

};
</script>

<style></style>