<template>
    <main class="news all_posts">

        <article class="news news2">
            <ArticleLogo :article="article" />
        </article>
        <LeagueLogo logo="uel" />
        <div :class="$route.path.includes('/uel') && 'sub_container_small'" class="sub_container_b">
            <NewsSubMenu name="Info" route="uelinfo" />
            <NewsSubMenu name="tables" route="uelstages" />
            <NewsSubMenu name="Matchday" route="uelmatchday" />
            <NewsSubMenu name="Goal-Scorers" route="uelgs" />
        </div>

        <ul class="league_info">
            <div class="info_table">
                <UelTables :fullScreen="fullScreen" />
            </div>
            <div class="info_extra">
                <UelGoalscorers :limit="3" :currentYear="currentYear" key="currentYear" />
                <UelWeeklyResult :currentYear="currentYear" />
            </div>
        </ul>

    </main>
</template>

<script>

import ArticleLogo from '../components/ArticleLogo.vue'

import LeagueLogo from '@/components/LeagueLogo.vue'
import NewsSubMenu from '@/components/team/NewsSubMenu.vue'
import UelTables from '@/components/uel/UelTables.vue'
import UelGoalscorers from '@/components/uel/UelGoalscorers.vue'
import UelWeeklyResult from '@/components/uel/UelWeeklyResult.vue'
export default {
    name: 'LeagueInfo',
    components: { ArticleLogo, UelTables, UelWeeklyResult, UelGoalscorers, LeagueLogo, NewsSubMenu },
    emits: ['news_direction'],
    data() {
        return {
            fullScreen: true,
            article: 'News',
            limit: undefined,
            activeMenu: 'serie A',
            activeSubMenu: 'table',
            currentYear: 'default',
            title: 'info'
        }
    },
    methods: {
        yearChanged(event) {
            this.currentYear = event
        },
        activeMenuChange(event) {
            this.activeMenu = String(event)
            
        },
        activeSubMenuChange(event) {
            this.activeSubMenu = String(event)
            
        }
    },
    mounted() {
        document.title = 'Europa League / Rossoneries';

    }

}
</script>
<style>
div.sub_container_b {
    background-color: rgba(26, 28, 50, 0.8);
    width: fit-content;
    border-radius: 8px;
    color: aliceblue;
    align-items: center;
    margin: 0 auto;
    font-size: .8em;
    justify-content: space-around;
    transition: .3s right linear;
    display: flex;
    min-height: 25px
}
</style>