<template>
    <!-- <Teleport to="body"> -->
    <div id="overlay" class="show" @click="doOverlay">
        <div class="flash">
            <p class="notyet flashed"> {{ message }}</p>
        </div>
    </div>
    <!-- </Teleport> -->
</template>

<script>
export default {
    inject: ['doOverlay'],
    props: ['message'],
}

</script>

<style scoped>
div.show {
    z-index: 9 !important;
}
</style>