<template>
    <button v-if="newsButton" @click="updateNews">update available</button>

    <li v-for="news in allNews" :key="news.id" class="new_posted all_posts">
        <div class="news_container">
            <div class="news">
                <p>{{ news.content }}<span v-if="news.src"><a :href="news.link" TARGET="_blank" class="rm">-read more at
                            {{
        news.src
    }}</a>
                    </span>
                </p>
                <img @click="zoomer(news.img)" :src="news.img" alt="">
                <svg v-if="admin" :id="news.id" @click="deleteNews" xmlns="http://www.w3.org/2000/svg" width="16"
                    height="16" fill="currentColor" class="close_image bi bi-x-circle" viewBox="0 0 16 16">
                    <path @click="closeImage"
                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
            </div>
        </div>
    </li>
</template>

<script>
export default {
    name: 'NewsTwitter',
    inject: ['backendServer', 'currentHost', 'admin', 'loading', 'zoomer'],
    data() {
        return {
            allNews: null,
            last_id: null,
            first_id: null,
            newData: null,
            newsButton: false,
            latestNews: null
        }
    },
    methods: {
        zoomImage(src, event) {
            this.zoomer(src)
            if (event) {
                event.stopPropagation();


            }
        },
        updateNews() {
            this.newsButton = !this.newsButton
            this.allNews = this.latestNews.concat(this.allNews)
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })

        },
        deleteNews(event) {
            fetch(this.backendServer() + '/delete-news/' + event.target.id, {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {

                    this.allNews = data.news;

                });
        },
        handleScroll() {
            // Check if the user has reached the bottom of the page
            var scrollPosition = window.innerHeight + window.scrollY;


            if (
                scrollPosition >= document.documentElement.offsetHeight - 30

            ) {
                window.removeEventListener('scroll', this.handleScroll);


                fetch(this.backendServer() + '/news-vue/' + this.last_id + '/' + this.first_id, {
                    method: "GET",
                    credentials: "include"
                })
                    .then((response) => response.json())
                    .then((data) => {

                        this.last_id = data.last_id
                        if (this.last_id == 'finished') {

                            window.removeEventListener('scroll', this.handleScroll);
                            return
                        } else {
                            this.newData = data.news;
                            this.allNews = this.allNews.concat(this.newData)
                            if (data.latest_news.length) {

                                this.newsButton = true
                                this.latestNews = data.latest_news


                            }
                            window.addEventListener('scroll', this.handleScroll);

                        }
                    });
            }
        }
    },
    unmounted() {
        window.removeEventListener('scroll', this.handleScroll)
    },

    async mounted() {
        this.loading()


        await fetch(this.backendServer() + '/news-vue/0/0', {
            method: "GET",
            credentials: "include"
        })
            .then((response) => response.json())
            .then((data) => {

                this.allNews = data.news;
                this.last_id = data.last_id
                this.first_id = data.first_id
                this.loading()


            });
        window.addEventListener('scroll', this.handleScroll);
    },


}
</script>

<style scoped>
button {
    font-size: .6em;
    z-index: 4;
    color: aqua;
    position: fixed;
    top: 167px;
    background: black;
    border-radius: 20px;
    left: 50%;
    transform: translate(-50%, -50%);
}

button:hover {
    font-weight: bold;
}

img {
    margin-bottom: 5px;
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    cursor: pointer
}

li {
    overflow: hidden;
}

p {
    color: black;
    margin-bottom: 5px !important;
    padding: 0 !important;
}

/* li::-webkit-scrollbar {
    width: .8em;

} */

div.news {
    border-bottom: 2px rgb(178 178 178) solid;
    padding: 9px;
    text-align: justify;
    position: relative;
    height: 375px;
    max-height: 310px;
}


div.news_container {
    /* display: flex;
    flex-wrap: wrap;
    justify-content: space-between; */
    overflow: hidden;
}

li :last-child {
    border-bottom: none;
}

.rm {
    font-size: 12px !important;
    color: #9b9b9b;
    transition: .3s linear all;
}

.rm:hover {
    color: #636363;
}
</style>