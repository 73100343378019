<template>
    <main class="register background all_posts">

        <div>

            <article class="news news2">
                <ArticleLogo :article="article" />
                <h3>Register</h3>
            </article>
            <div class="regl">
                <div id="buttonDiv"></div>

                <div class="comp_title comp_title_matchday">
                    <h5 class="league-info-header">
                        Sign Up with Email

                    </h5>
                </div>
                <form action="" method="post" class="login_form">
                    <div>
                        <TextField name="username" type="username" v-model="username" />
                        <TextField name="email" type="text" v-model="email" />
                        <TextField name="password" type="password" v-model="password" />
                        <TextField name="confirm password" type="password" v-model="passwordConfirm" />
                    </div>

                    <input type="submit" name="submit" value="sign up" class="button_submit" id="subone"
                        @click="validateRegisteringUser">

                    <p class="notyet sdfe">already a member? login <router-link
                            :to="{ name: 'login' }">here</router-link></p>
                </form>
            </div>
        </div>


    </main>
</template>

<script>
import ArticleLogo from '../components/ArticleLogo.vue'

import TextField from "../components/TextField.vue"
/* global google */

export default {
    components: { ArticleLogo, TextField },
    inject: ['backendServer', 'setFlashedMessage', 'currentHost', 'doUpdateUserInfo'],
    data() {
        return {
            article: 'Register',
            username: '',
            email: '',
            password: '',
            passwordConfirm: '',
        }
    },
    methods: {
        decodeJwtResponseFromGoogleAPI(token) {
            let base64Url = token.split('.')[1]
            let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            let jsonPayload =
                decodeURIComponent(atob(base64).split('').map(function (c) {
                    return '%' + ('00' +
                        c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));
            return JSON.parse(jsonPayload)
        },
        initGoogleSignIn() {
            google.accounts.id.initialize({
                client_id: "906235521597-39lqp5qu3klf8vceng0t5gvr3hudfu8a",
                callback: this.handleCredentialResponse,
            });
            google.accounts.id.renderButton(
                document.getElementById("buttonDiv"),
                { theme: "outline", text: "continue_with" } // customization attributes
            );
            // google.accounts.id.prompt(); // also display the One Tap dialog
        },
        handleCredentialResponse(response) {

            // const responsePayload = this.decodeJwtResponseFromGoogleAPI(response.credential);


            // const { credential } = response;



            // Step 1: Extract idToken from the credential
            //const idToken = credential.idToken;

            // Step 2: Send idToken to the backend for verification and registration
            fetch(this.backendServer() + '/google-register', {
                method: "POST",
                mode: "cors", // no-cors, *cors, same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "include", // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: "follow", // manual, *follow, error
                referrerPolicy: "no-referrer",
                body: JSON.stringify({ 'token': response.credential }),
            })
                .then(response => response.json())
                .then(data => {
                    // Step 3: Handle the response from the backend


                    // Step 4: Check if registration was successful
                    if (data) {
                        // Registration successful, you can proceed with any additional actions

                        this.doUpdateUserInfo(data);

                        this.$router.push({ name: 'profile' })
                    } else {
                        // Registration failed, handle the error
                        console.error("User registration failed:", data.error);
                    }
                })
                .catch(error => {
                    // Step 5: Handle errors
                    console.error("Error during registration:", error);
                });
        },
        // Send the credential to your backend for verification
        // Example: axios.post('http://yourbackend/google-signin', { credential })

        validateRegisteringUser(event) {
            event.preventDefault()

            if (!this.username || !this.email || !this.password || !this.passwordConfirm) {
                this.setFlashedMessage("please fill all the fields");
                return
            }
            else if (this.username.length < 8 || this.password.length > 16) {
                this.setFlashedMessage("username must be between 8 to 16 characters");
                return
            }
            else if (this.password.length < 8) {
                this.setFlashedMessage("password must at least be 8 characters");
                return
            }
            else if (this.password != this.passwordConfirm) {
                this.setFlashedMessage("Passwords does'nt match");
                return
            }
            else {
                this.doRegister(event)
            }

            // this.doLogin()

        },
        doRegister(event) {
            event.preventDefault();
            fetch(this.backendServer() + '/registering', {
                method: "POST", // *GET, POST, PUT, DELETE, etc.

                mode: "cors", // no-cors, *cors, same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "include", // include, *same-origin, omit
                headers: {
                    //"Content-Type": "application/json",
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: "follow", // manual, *follow, error
                referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify({
                    'email': this.email,
                    'username': this.username,
                    'password': this.password,
                    'password_confirm': this.passwordConfirm,
                }), // body data type must match "Content-Type" header
            }).then((response) => {
                return response.json();
            }).then((data) => {
                // If the user is logged in:
                // Router...
                if (data.username && data.email) {
                    this.setFlashedMessage("this user name and email has been registerd already")
                } else if (data.username) {
                    this.setFlashedMessage("username already taken")
                } else if (data.email) {
                    this.setFlashedMessage("email has been registerd already")
                } else if (data.success) {
                    this.setFlashedMessage("thank you dear " + this.username + "Please verify your email address to activate your account")
                }




            })

        },

    },
    mounted() {
        document.title = 'Register / Rossoneries';

        this.initGoogleSignIn();
    },



}
</script>

<style>
.sdfe {
    box-shadow: none !important;
    background: none !important;
}
</style>