<template>
    <div>
        <!-- <div v-if="comments.length > 5 && hidden_button" class="return_to_post">
            <button @click="loadAllComments" class="button_submit" id="load_all_comments">load all comments</button>

        </div> -->
        <div id="all_comments" ref="all_comments">
            <div>
                <SingleComment v-for="comment in comments.slice(0, limit)" :key="comment.id" :comment="comment" />
            </div>
        </div>

    </div>
</template>

<script>

import SingleComment from '@/components/singlePost/SingleComment.vue';

export default {
    components: { SingleComment },
    name: "CommentList",
    emits: ['reloadComments'],
    props: ['comments'],
    inject: ['user_login_info', 'currentHost'],
    data() {
        return {
            'hidden_button': true,
            'hidden_field': false,
            'id': window.location.href.split('/').pop(),
            'comment_reply': null,
            'reply_to_emit': null,
            'show': true,
            'limit': undefined,
        }
    },
    methods: {
        loadAllComments() {
            this.limit = undefined;
            this.hidden_button = !this.hidden_button
        },

    }
}
</script>

<style></style>